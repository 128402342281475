  <div class="signin" style="padding-bottom: 20px;" fxLayout="row wrap" fxLayout.xs="column">
 
    <div fxFlex="100%" class="animate__animated animate__fadeIn animate__slower">
      <h3>User Feed Back Form</h3>
      <form [formGroup]="myForm" fxLayout="column" fxLayout.xs="column">
        <div fxLayout="row wrap" fxLayout.xs="column">
          <div fxFlex="60%" style="padding-bottom: 10px;">1. പരീക്ഷകളുടെ ചോദ്യങ്ങൾ എങ്ങനെ ഉണ്ടായിരുന്നു?</div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a1" id="11"  value="1" formControlName="a1"><label for="11">Very Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a1" id="12"  value="2" formControlName="a1"><label for="12">Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a1" id="13"  value="3" formControlName="a1"><label for="13">Average</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a1" id="14"  value="4" formControlName="a1"><label for="14">Bad</label></div>
      
          <div fxFlex="60%" style="padding-top: 10px;padding-bottom: 10px;">2. വെബ്സൈറ്റ് നെ കുറിച്ച് ഉള്ള അഭിപ്രായം എന്താണ്?</div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a2" id="21" value="1" formControlName="a2"><label for="21">Very Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a2" id="22" value="2" formControlName="a2"><label for="22">Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a2" id="23" value="3" formControlName="a2"><label for="23">Average</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a2" id="24" value="4" formControlName="a2"><label for="24">Bad</label></div>

          <div fxFlex="60%" style="padding-top: 10px;padding-bottom: 10px;">3. സിലബസ് പ്രകാരം ഉള്ള പരീക്ഷ ചോദ്യങ്ങൾ ആയിരുന്നോ?</div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a3" id="31"  value="1" formControlName="a3"><label for="31">Very Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a3" id="32"  value="2" formControlName="a3"><label for="32">Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a3" id="33"  value="3" formControlName="a3"><label for="33">Average</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a3" id="34"  value="4" formControlName="a3"><label for="34">Bad</label></div>

          <div fxFlex="60%" style="padding-top: 10px;padding-bottom: 10px;">4. ഓൺലൈൻ പരീക്ഷ രീതി നിങ്ങളുടെ അറിവ് മെച്ചപ്പെടുത്താൻ സഹായിക്കുമോ?</div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a4" id="41"  value="1" formControlName="a4"><label for="41">Very Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a4" id="42"  value="2" formControlName="a4"><label for="42">Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a4" id="43"  value="3" formControlName="a4"><label for="43">Average</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a4" id="44"  value="4" formControlName="a4"><label for="44">Bad</label></div>            

          <div fxFlex="60%" style="padding-top: 10px;padding-bottom: 10px;">5. വെബ്സൈറ്റ് ഡിസൈൻ, സിലബസ് ഇവയെ കുറിച്ച് ഉള്ള അഭിപ്രായം?</div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a5" id="51"  value="1" formControlName="a5"><label for="51">Very Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a5" id="52"  value="2" formControlName="a5"><label for="52">Good</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a5" id="53"  value="3" formControlName="a5"><label for="53">Average</label></div>
          <div fxFlex="60%"><input class="opt-radio" type="radio" name="a5" id="54"  value="4" formControlName="a5"><label for="54">Bad</label></div>
          
          <div fxFlex="60%" style="padding-top: 10px;">
            <mat-form-field appearance="outline" fxFlex="95">
              <mat-label>Comment Options</mat-label>
              <mat-select formControlName="commentoptions" multiple>
                <mat-option *ngFor="let optlst of optionList" [value]="optlst.id">{{optlst.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="60%">
            <mat-form-field appearance="outline" fxFlex="95">
              <mat-label>Comments</mat-label>
              <textarea matInput placeholder="Full Name :" formControlName="usercomment" placeholder="Enter comments here"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="60">
          <button mat-flat-button color="primary" (click)="validateFeedBack()" class="btnmargin">Submit</button>
          <button routerLink="/dashboard" mat-flat-button color="primary" class="btnmargin">Cancel</button>
        </div>
      </form>
    </div>
  </div>
