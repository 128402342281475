<div>
    <div class="about animate_animated animatefadeIn animate_slower" fxLayout="row wrap" fxLayout.xs="column"
        fxLayoutAlign="center">
        <div>
            <h2>Our Pricing</h2>
           <table>
            <tr>
                <td>NMMS, NTSE, Navodaya</td>
                <td>Rs.250</td>
            </tr>
            <tr>
                <td>PSC</td>
                <td>Rs.800</td>
            </tr>
            <tr>
                <td>UPSC</td>
                <td>Rs.500</td>
            </tr>
            <tr>
                <td>Bank Recruitment</td>
                <td>Rs.200</td>
            </tr>
            <tr>
                <td>Entrance PG</td>
                <td>Rs.250</td>
            </tr>
            <tr>
                <td>Scholl exams</td>
                <td>Rs. 150</td>
            </tr>
            <tr>
                <td>Department Test</td>
                <td>Rs 599</td>
            </tr>

           </table>
        </div>
    </div>
</div>