import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-owl-slider',
  templateUrl: './owl-slider.component.html',
  styleUrls: ['./owl-slider.component.css']
})
export class OwlSliderComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit():void{
    
    $('.center').slick({
      centerPadding: '60px',
      infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 2
          }
        }
      ]
    });
}

}
