
    <div class="about" fxLayout="column">
        <div class="row m-b-20">
            <div class="col-md-12">
                <h3 >Questen Paper Subject's specification</h3>
            </div>
        </div>
        <div class="row m-b-20" *ngIf="qpsubject_show==1">
            <div class="col-md-5 float-left">
                <table class="table">
                    <thead>
                        <tr class="tr-bgcolor">
                            <td ><h6>&nbsp;&nbsp;&nbsp;Subjects</h6></td>
                        </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let exams of QpSubjects[0]"> 
                            <td >&nbsp;&nbsp;&nbsp;
                                <svg width="10px" height="10px" viewBox="-4.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                            
                                <title>arrow_right [#336]</title>
                                <desc>Created with Sketch.</desc>
                                <defs>
                            
                                </defs>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Dribbble-Light-Preview" transform="translate(-305.000000, -6679.000000)" fill="#000000">
                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                            <path
                                                d="M249.365851,6538.70769 L249.365851,6538.70769 C249.770764,6539.09744 250.426289,6539.09744 250.830166,6538.70769 L259.393407,6530.44413 C260.202198,6529.66364 260.202198,6528.39747 259.393407,6527.61699 L250.768031,6519.29246 C250.367261,6518.90671 249.720021,6518.90172 249.314072,6519.28247 L249.314072,6519.28247 C248.899839,6519.67121 248.894661,6520.31179 249.302681,6520.70653 L257.196934,6528.32352 C257.601847,6528.71426 257.601847,6529.34685 257.196934,6529.73759 L249.365851,6537.29462 C248.960938,6537.68437 248.960938,6538.31795 249.365851,6538.70769"
                                                id="arrow_right-[#336]">
                            
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>&nbsp;&nbsp;&nbsp;
                                <a  (click)="showSpecific(exams.subcat_id)">{{exams.specific_name }}</a>
                            </td>
                        </tr>
						<tr>
							<td colspan="2" >
								<button mat-flat-button class="btnbluedark"    (click)="goBack()">Go Back</button> 
							</td>
						</tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="col-md-7 float-left">
                <h6> Basic awareness</h6>
                <p style="text-align: justify;">Basic awareness,<br>Knowledge Development, <br>Exam Practise,  <br>Result Checking,<br>Progress Report, <br>Expert Traing,<br>Audio Video Presentation,<br>Application Filing. </p>
            </div> -->
        </div>
        
        <div *ngIf="videos_show==1">
            <tr *ngIf="videoStart==1">
                <td colspan="4"><video width="250" height="150"  controls="" controlslist="nodownload" src="https://admin.oppam.in/pgs/exams/videos/{{videoName}}"></video> </td>
            </tr>
            <tr *ngFor="let vids of SpecificVideos" >
                <td colspan="3" style="font-size: smaller;">{{vids.topicname}}</td>
                <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showVideo(vids.videoname)">
                    <mat-icon style="color:red">local_library</mat-icon>    
                </td>
            </tr>
            <tr>
                <td colspan="2" >
                    <button mat-flat-button class="btnbluedark"  (click)="showHide()">Go Back</button>
                </td>
            </tr>
        </div>

        <div class="row" *ngIf="specific_show==1 && videos_show==0">
            <div class="col-md-4 float-left">
                <h4>Subject Specific Practice</h4>
                <div class="table-responsive">
                    <table style="width: 100%;" class="table table-hover">
                        <thead>
                            <tr *ngIf="specific_len>0">
                                <th class="tr-bgcolor">Specific Name</th>
                                <th class="tr-bgcolor" style="text-align:center;font-size: small;">Text</th>
                                <th class="tr-bgcolor" style="text-align:center;font-size: small;">Video</th>
                                <th class="tr-bgcolor" style="text-align:center;font-size: small;">Audio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let exams of Specific">
                                <td style="font-size: smaller;">{{exams.specific_name}}</td>
                                <td style="text-align:center; cursor:pointer;font-size: small;" (click)="goStudy(exams.specific_id)">
                                    <mat-icon style="color:blue">local_library</mat-icon>
                                </td>
                                <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(exams.specific_id,1)">
                                    <mat-icon style="color:green">local_library</mat-icon>
                                </td>
                                <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(exams.specific_id,2)">
                                    <mat-icon style="color:red">local_library</mat-icon>    
                                </td>
                            </tr>
                            <div *ngIf="videos_show==1">
                                <tr *ngFor="let vids of SpecificVideos" >
                                    <td colspan="3" style="font-size: smaller;">{{vids.topicname}}</td>
                                    <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(vids.id,2)">
                                        <mat-icon style="color:red">local_library</mat-icon>    
                                    </td>
                                </tr>
                            </div>
                            <tr>
                                <td colspan="2" >
                                    <button mat-flat-button class="btnbluedark"  (click)="getBack()">Go Back</button>
                                </td>
                            </tr>
                            <!-- <tr *ngIf="eligible_len>0">
                                <td colspan="3" ><h4>Eligible Trial Exams</h4>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
           <!-- <div class="col-md-4 float-left">
               <h2> Basic awareness</h2>
               <p style="text-align: justify;">
                   <br>Basic awareness, <br>Knowlegge Development,<br>Exam Practise, <br>Result Checking,  <br>Progress Report, <br>Expert Traing, <br>Audio Video Presentation, <br>Application Filing.
               </p>
           </div> -->
      </div>
    </div>  


