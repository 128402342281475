<br><br><br><br><br><br>

<div>
    <div fxLayout="row wrap" fxLayout.xs="column"
        fxLayoutAlign="center">
        <div>
            <h2>Our Pricing</h2>
           <table>
            <tr>
                <td>NMMS, NTSE, Navodaya</td>
                <td>Rs.250</td>
            </tr>
            <tr>
                <td>PSC</td>
                <td>Rs.800</td>
            </tr>
            <tr>
                <td>UPSC</td>
                <td>Rs.500</td>
            </tr>
            <tr>
                <td>Bank Recruitment</td>
                <td>Rs.200</td>
            </tr>
            <tr>
                <td>Entrance PG</td>
                <td>Rs.250</td>
            </tr>
            <tr>
                <td>Scholl exams</td>
                <td>Rs. 150</td>
            </tr>
            <tr>
                <td>Department Test</td>
                <td>Rs 599</td>
            </tr>

           </table>
        </div>
    </div>
</div>

<div class="pt-marg-30" >
    <div class="contact" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center ">

        <div fxFlex="90" class="animate__animated animate__fadeIn animate__slower">
            <div class="row">
                <div class="col-sm-12">
                    <h4>Payment Method</h4>
                </div>
            </div>
    
            <div class="row">
                <div class="col-sm-12">
                    <ul>
                        <li><i class= "fa fa-dot-circle-o m-r-10" aria-hidden="true"></i>
                            നിങ്ങള്‍ക്ക് ആദ്യം നിങ്ങളുടെ  വാലറ്റില്‍ ആവശ്യമായ ബാലന്‍സ് ആവശ്യമാണ് </li>
                        <li><i class= "fa fa-dot-circle-o m-r-10" aria-hidden="true"></i>
                            അതിന് നിങ്ങള്‍ ചെയ്യേണ്ടത് ഗൂഗിള്‍ പേ, QR Code Scan വഴിയോ നിക്ഷേപിക്കുക </li>
                        <li><i class= "fa fa-dot-circle-o m-r-10" aria-hidden="true"></i>
                            അപ്പോള്‍ വാലറ്റില്‍ നിങ്ങള്‍ നിക്ഷേപിക്കുന്ന തുക അവിടെ കാണിക്കുന്നതാണ്.</li>
                        <li><i class= "fa fa-dot-circle-o m-r-10" aria-hidden="true"></i>	
                            ഗൂഗിള്‍ പേ <BR> <B>നമ്പര്‍ : 9847500773 </B><br> <B>പേര്  : Anil Kumar C.V.</B> </li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <table class="table-bordered">
                    <tr>
                        <td>
                            <img src="uploads/qrcodes/qr1.jpg" width="50%" height="50%"> 
                        </td>
                     </tr> 
                     <tr>  
                        <td>
                            <img src="uploads/qrcodes/qr2.jpg" width="50%" height="50%"> 
                        </td>
                    </tr>
                </table>
            </div><br>      
    
            <!--<div class="row">
                <div class="col-lg-12">
                    <h2>State Bank of India</h2>
                </div>
    
                <div class="col-lg-12 responsive">
                    <table class="table table-bordered w-100">
                        <tbody>
                            <tr>
                                <td>Account Number</td>
                                <td>33666402074</td>
                            </tr>
                            <tr>
                                <td>Name </td>
                                <td>Anil Kumar C V </td>
                            </tr>
                            <tr>
                                <td>Statebank of India</td>
                                <td>Payyanur ADO</td>
                            </tr>
                            <tr>
                                <td>IFSC CODE</td>
                                <td>SBIN0004686</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
    
            <div class="row  m-t-20 m-b-30">
                <strong>
                       Google Pay Payment നു ശേഷം താഴെ കാണുന്ന കോളത്തില്‍ നിങ്ങളുടെ ഡിറ്റെയില്‍സ് നല്‍കുക. അതിനു ശേഷം ഗൂഗിൾ പേ നമ്പറിൽ വിളിക്കുക...
                </strong>
            </div>
            <br>

            <form [formGroup]="myForm" fxLayout="column" fxLayout.xs="column">
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Amount</mat-label>
                    <input matInput placeholder="Amount" formControlName="Amount">
                    <mat-error *ngIf="myForm.controls['Amount'].invalid &&
              (myForm.controls['Amount'].dirty || myForm.controls['Amount'].touched)">
              Amount required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Bank A/C Holder Name" formControlName="AccHolderName">
                    <mat-error *ngIf="myForm.controls['AccHolderName'].invalid &&
              (myForm.controls['AccHolderName'].dirty || myForm.controls['AccHolderName'].touched)">
              Name required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Registered Mobile Number</mat-label>
                    <input matInput placeholder="Bank Reference No" formControlName="BankRefNo">
                    <mat-error *ngIf="myForm.controls['BankRefNo'].invalid &&
              (myForm.controls['BankRefNo'].dirty || myForm.controls['BankRefNo'].touched)">
              Registered Mobile Number required.
                    </mat-error>
                </mat-form-field>
                <div fxFlex="90">
                    <button mat-flat-button color="primary" (click)="walletRecharge()">Submit</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>
                </div>
                <br>
            </form>

        </div>
    </div>
</div>            
