    <div class="about" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex="60" class="animate__animated animate__fadeIn animate__slower">
            <h3>Contact Us</h3>
            <form [formGroup]="myForm" fxLayout="column" fxLayout.xs="column">
            <mat-form-field appearance="outline" fxFlex="90">
                <mat-label>Name</mat-label>
                <input matInput type="text" maxlength="100" minlength="3" placeholder="Enter your Name" formControlName="nam">
                <mat-error *ngIf="myForm.controls['nam'].invalid &&
                                (myForm.controls['nam'].dirty || myForm.controls['nam'].touched)">
                    Name required.
                </mat-error>
             </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="90">
                <mat-label>Mobile</mat-label>
                <input matInput type="text" maxlength="10" minlength="10" placeholder="Enter Mobile Number" formControlName="mob">
                <mat-icon matSuffix>phone_iphone</mat-icon>
                <mat-error *ngIf="myForm.controls['mob'].invalid &&
                              (myForm.controls['mob'].dirty || myForm.controls['mob'].touched)">
                  Mobile required.
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="90">
                <mat-label>Subject</mat-label>
                <input matInput type="text" maxlength="100" minlength="3" placeholder="Enter Subject" formControlName="sub">
                <mat-error *ngIf="myForm.controls['sub'].invalid &&
                                (myForm.controls['sub'].dirty || myForm.controls['sub'].touched)">
                    Subject required.
                </mat-error>
             </mat-form-field>
             <mat-form-field appearance="outline" fxFlex="90">
                <mat-label>Comments</mat-label>
                <textarea matInput type="text" cols="50" rows="3" maxlength="250" minlength="3" placeholder="Enter your Comments" formControlName="mes"></textarea>
                <mat-error *ngIf="myForm.controls['mes'].invalid &&
                                (myForm.controls['mes'].dirty || myForm.controls['mes'].touched)">
                    Comments required.
                </mat-error>
             </mat-form-field>
              <div fxFlex="60">
                <button mat-flat-button color="primary" (click)="validateContact()">Submit</button>
              </div>
            </form>
          </div>
          
        <div fxFlex="40" class="animate__animated animate__fadeIn animate__slower left-marg" style="padding-top: 25px;">
            <p>Location: Akshaya Center, Andamkovval</p>
              <p>Kunhimangalam, Kannur, Kerala - 670309</p>
            <p>Email: info@oppam.in, anilchembally1973klm@gmail.com</p>
            <p>Call: +91 9847500773 / 9497439329 / 9847009683</p>
        </div>
    </div>
