import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
   } from '@angular/common/http';
import { Injectable } from '@angular/core';
   import { Observable, throwError } from 'rxjs';
   import { retry, catchError, tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shard/widgets/progressbar/progress.service';

@Injectable()
   
   export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(public spinnerService:SpinnerService){

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.spinnerService.show();

      return next.handle(request)
        .pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.spinnerService.hide();
                }
            }, (error) => {
              this.spinnerService.hide();
            }),
          retry(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;  
            } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            window.alert(errorMessage);
            return throwError(errorMessage);
          })
        )
    }
   }