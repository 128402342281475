

    <div class="about" fxLayout="column"  fxLayoutAlign="center ">
        <div fxFlex="80" class="animate__animated animate__fadeIn animate__slower">
            <h3>Wallet:₹.{{UserWalletAmount[0].wallet_amount}}</h3>
            <form [formGroup]="myForm">
                <table width="100%">
                    <thead>
                        <tr ng-if="EligiblePackages.length>0" class="tr-bgcolor">
                            <td>&nbsp;#</td>
                            <td>Package Name</td>
                            <td>Amount(₹)</td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pkg of eligiblepackages">
                            <td>
                                <input type="checkbox" (change)="onCheckboxChange(pkg,pkg.qpid,pkg.subscribed,pkg.amount,$event)"/> 
                            </td>
                            <td>{{pkg.qpname}}</td>
                            <td>{{pkg.amount}}</td>
                            
                        </tr>
                        <tr><td colspan="2">Total Amount for 1 month :</td><td>₹{{TotalPrice}}</td></tr>
                        <tr><td colspan="2">GST Amount :</td><td>₹{{TotalPrice*18/100}}</td></tr>
                        <tr><td colspan="2">G-Total Amount :</td><td>₹{{TotalPrice+(TotalPrice*18/100)}}</td></tr>
                    </tbody>
                  </table>  

                 <br>
                 <div >
                    <button style="margin-right: 20px;" mat-flat-button class="btnbluedark" id="rzp-button1" (click)="payrazor()">Subscribe</button>
                    <button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>
                </div>
                <br> 
            </form>
        </div>
    </div>

    