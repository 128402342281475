<div class="pd-02 " fxLayout="row wrap" fxLayoutAlign="space-evenly start">
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/puttupodi.png" alt="" class="responsive-image yellow ">
        <figure>
            <h4>PUTTU PODI</h4>
            <p>Puttu is a great breakfast that we all enjoy despite the age group. The aroma of a perfectly
                steamed Puttu is enough to awaken our tastebuds. The perfect blend of purely grounded
                Mr.Maid puttupodi and well-grated coconuts, when cooked to perfection, gives us the best puttu.
                The combination of Puttu with Kadala curry and banana is well adored throughout our state.
            </p>
        </figure>
    </div>

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/pathiripodi.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>PATHIRI PODI</h4>
            <p>Pathiri is a delicious thin rice pancake that is ideal for breakfast/dinner. It is an original Kerala
                dish best served with spicy chicken curry or a stew. Even though the pathiri was exclusive to the
                palette of north Malabar cuisine, it has seeped deeper into all parts of Kerala with its texture and
                aroma. Mr. Maid’s finely ground pathiripodi makes it an inseparable part of our mornings.
            </p>
        </figure>
    </div>


    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/palappampodi.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>EASY PALAPPAM PODI</h4>
            <p>Palappam is a favorite breakfast for all the people of Kerala. It is effortless to prepare. Mr. Maid
                gives you the perfect blend of palappam podi, making it easier for you to cook. This dish takes
                you back to your childhood home, where your mother prepared it for you, which you enjoyed
                with delicious chutneys and curries.<br><br><br></p>
        </figure>
    </div>

    <!-- <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/atta.png" alt="" class="responsive-image">
        <figure>
            <h4>ATTA</h4>
            <p>Mr. Maid Atta powder is the best way to re-energize you for the whole day. It is made from pure
                golden brown wheat, grounded by state-of-the-art grinding technology, ensuring the absence of
                maida. The ability of the atta to absorb water gives us softer chappatis for a longer duration.
                Our chappatis are healthy and a great companion for your tastebuds.</p>
        </figure>
    </div> -->

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/chakkifreshatta.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>CHAKKI FRESH ATTA</h4>
            <p>Mr. Maid Atta powder is the best way to re-energize you for the whole day. It is made from pure
                golden brown wheat, grounded by state-of-the-art grinding technology, ensuring the absence of
                maida. The ability of the atta to absorb water gives us softer chappatis for a longer duration.
                Our chappatis are healthy and a great companion for your tastebuds.</p>
        </figure>
    </div>

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/maida.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>MAIDA</h4>
            <p>Mr. Maid Maida powder is the best way to re-energize you for the whole day. It is made from pure
                golden brown wheat, grounded by state-of-the-art grinding technology, ensuring the absence of
                maida. The ability of the atta to absorb water gives us softer chappatis for a longer duration.
                Our chappatis are healthy and a great companion for your tastebuds.</p>
        </figure>
    </div>

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/rava.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>DEEP ROASTED RAVA</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/palada_payasam_mix.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>PALADA PAYASAM MIX</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/rice_palada_payasam_mix.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>RICE PALADA PAYASAM MIX</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>

    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/vemicelli_payasam_mix.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>VERMICELLI PAYASAM MIX</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/mango_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>MANGO PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/lemon_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>LEMON PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>


    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/garlic_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>GARLIC PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/dates_lemon_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>DATES LEMON PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/dates_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>DATES PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/mixed_veg_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>MIXED VEGITABLE PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/prawns_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>PRAWNS PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div class="card" fxFlex="24" fxFlex.xs="100">
        <img src="assets/images/products/yam_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>YAM PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure>
    </div>
    <div fxFlex="24" fxFlex.xs="100">
        <!-- <img src="assets/images/products/yam_pickle.png" alt="" class="responsive-image yellow">
        <figure>
            <h4>YAM PICKLE</h4>
            <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                dishes like upma, dosa, vada.<br><br><br>
            </p>
        </figure> -->
    </div>
    <div fxFlex="24" fxFlex.xs="100">
        <!-- <img src="assets/images/products/yam_pickle.png" alt="" class="responsive-image yellow">
            <figure>
                <h4>YAM PICKLE</h4>
                <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                    quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                    directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                    dishes like upma, dosa, vada.<br><br><br>
                </p>
            </figure> -->
    </div>
    <div fxFlex="24" fxFlex.xs="100">
        <!-- <img src="assets/images/products/yam_pickle.png" alt="" class="responsive-image yellow">
            <figure>
                <h4>YAM PICKLE</h4>
                <p>Rava is well known for preparing a broad palette of dishes. It also has a tremendous dietary
                    quotient to be a nutritional supplement for both adults and kids. The Rava can be roasted to be
                    directly added into dishes like cereals, halwas, and pudding. It is also great for preparing salty
                    dishes like upma, dosa, vada.<br><br><br>
                </p>
            </figure> -->
    </div>

</div>