    <div class="about" fxLayout="column" fxLayout.xs="100%" fxLayoutAlign="center center">

        <div fxFlex="90" class="animate__animated animate__fadeIn animate__slower">
            <h3>User Registration</h3>
            <form [formGroup]="myForm" fxLayout="column">
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Full Name</mat-label>
                    <input matInput placeholder="Full Name :" formControlName="name">
                    <mat-error *ngIf="myForm.controls['name'].invalid &&
                        (myForm.controls['name'].dirty || myForm.controls['name'].touched)">
                        Name required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="90" *ngIf="src==1">
                    <mat-label>School Name</mat-label>
                    <mat-select formControlName="talentexamflag">
                        <mat-option *ngFor="let opt of Schools" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>   
                        <mat-option [value]="0">
                            Other
                        </mat-option>   
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['talentexamflag'].invalid &&
                        (myForm.controls['talentexamflag'].dirty || myForm.controls['talentexamflag'].touched)">
                        School required.
                    </mat-error>
                </mat-form-field>                

                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Education Level</mat-label>
                    <mat-select formControlName="levelid">
                        <mat-option *ngFor="let opt of EduLevels" [value]="opt.id" (onSelectionChange)="getFilterMaster('Qualification', opt.id)">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['levelid'].invalid &&
                        (myForm.controls['levelid'].dirty || myForm.controls['levelid'].touched)">
                        Education Level required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Qualification</mat-label>
                    <mat-select formControlName="qualid">
                        <mat-option *ngFor="let opt of Qualifications" [value]="opt.id" (onSelectionChange)="getFilterMaster('Qualification Subject', opt.id)">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['qualid'].invalid &&
                        (myForm.controls['qualid'].dirty || myForm.controls['qualid'].touched)">
                        Qualification required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Subject / Medium</mat-label>
                    <mat-select formControlName="qualsubid">
                        <mat-option *ngFor="let opt of subjectList" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['qualsubid'].invalid &&
                        (myForm.controls['qualsubid'].dirty || myForm.controls['qualsubid'].touched)">
                        Subject / Medium required.
                    </mat-error>
                </mat-form-field>
                <!--<mat-form-field appearance="outline"  fxFlex="90">
                    <mat-label>Subject / Medium</mat-label>
                    <input type="text" placeholder="type here" matInput formControlName="qualsubid"  [matAutocomplete]="autoSubjects">
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-autocomplete autoActiveFirstOption #autoSubjects="matAutocomplete" [displayWith]="displaySubjects.bind(this)">
                      <mat-option *ngFor="let opt of filteredSubjects | async" [value]="opt.id">
                        {{opt.name}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="myForm.controls['qualsubid'].invalid &&
                        (myForm.controls['qualsubid'].dirty || myForm.controls['qualsubid'].touched)">
                        Subject / Medium required.
                    </mat-error>
                  </mat-form-field> -->

                <mat-form-field appearance="outline" fxFlex="90" *ngIf="profqualiflag">
                    <mat-label>Additional Qualification if any</mat-label>
                    <mat-select formControlName="profid">
                        <mat-option *ngFor="let opt of ProfQualifications" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                </mat-form-field>                  

                  <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Year of Birth</mat-label>
                    <mat-select formControlName="dob">
                        <mat-option [value]="1980">1980</mat-option> 
                        <mat-option [value]="1981">1981</mat-option>   
                        <mat-option [value]="1982">1982</mat-option>   
                        <mat-option [value]="1983">1983</mat-option>   
                        <mat-option [value]="1984">1984</mat-option>   
                        <mat-option [value]="1985">1985</mat-option>   
                        <mat-option [value]="1986">1986</mat-option>   
                        <mat-option [value]="1987">1987</mat-option>   
                        <mat-option [value]="1988">1988</mat-option>   
                        <mat-option [value]="1989">1989</mat-option>   
                        <mat-option [value]="1990">1990</mat-option>   
                        <mat-option [value]="1991">1991</mat-option>   
                        <mat-option [value]="1992">1992</mat-option>   
                        <mat-option [value]="1993">1993</mat-option>   
                        <mat-option [value]="1994">1994</mat-option>   
                        <mat-option [value]="1995">1995</mat-option>   
                        <mat-option [value]="1996">1996</mat-option>     
                        <mat-option [value]="1997">1997</mat-option>   
                        <mat-option [value]="1998">1998</mat-option>   
                        <mat-option [value]="1999">1999</mat-option>   
                        <mat-option [value]="2000">2000</mat-option>   
                        <mat-option [value]="2001">2001</mat-option> 
                        <mat-option [value]="2002">2002</mat-option>  
                        <mat-option [value]="2003">2003</mat-option>  
                        <mat-option [value]="2004">2004</mat-option>  
                        <mat-option [value]="2005">2005</mat-option>  
                        <mat-option [value]="2006">2006</mat-option>  
                        <mat-option [value]="2007">2007</mat-option>  
                        <mat-option [value]="2008">2008</mat-option>  
                        <mat-option [value]="2009">2009</mat-option>  
                        <mat-option [value]="2010">2010</mat-option>  
                        <mat-option [value]="2011">2011</mat-option>  
                        <mat-option [value]="2012">2012</mat-option>  
                        <mat-option [value]="2013">2013</mat-option>  
                        <mat-option [value]="2014">2014</mat-option>  
                        <mat-option [value]="2015">2015</mat-option>  
                        <mat-option [value]="2016">2016</mat-option>  
                        <mat-option [value]="2017">2017</mat-option>  
                        <mat-option [value]="2018">2018</mat-option>  
                        <mat-option [value]="2019">2019</mat-option>  
                        <mat-option [value]="2020">2020</mat-option>  
                        <mat-option [value]="2021">2021</mat-option>   
                        <mat-option [value]="2022">2022</mat-option>
                        <mat-option [value]="2023">2023</mat-option>                       
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['dob'].invalid &&
                        (myForm.controls['dob'].dirty || myForm.controls['dob'].touched)">
                        Year of Birth required.
                    </mat-error>
                  </mat-form-field>
                  
                  <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Login ID</mat-label>
                    <input matInput type="text"  maxlength="10" minlength="3" placeholder="Enter Mobile Number" formControlName="unm">
                    <mat-icon matSuffix>phone_iphone</mat-icon>
                    <mat-error *ngIf="myForm.controls['unm'].invalid && myForm.controls['unm'].errors?.pattern &&
                        (myForm.controls['unm'].dirty || myForm.controls['unm'].touched)">
                        UserName (Valid Mobile) required.
                    </mat-error>
                   <!-- <mat-error *ngIf="myForm.controls['unm'].errors?.pattern">
                        Mobile number is not 10 Digit.
                    </mat-error> -->
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>PassWord</mat-label>
                    <input matInput [type]="showPassword ? 'text' : 'password'"  formControlName="pwd" minlength="6" maxlength="10">
                    <!-- <mat-icon matSuffix>visibility</mat-icon> -->
                    <mat-icon matSuffix (click)="togglePasswordVisibility()">
                        {{showPassword?'visibility_off':'visibility'}}
                    </mat-icon>
                    <mat-error *ngIf="myForm.controls['pwd'].invalid &&
                        (myForm.controls['pwd'].dirty || myForm.controls['pwd'].touched)">
                        PassWord (Min length 6 with Special Chrs) required.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Confirm PassWord</mat-label>
                    <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="pwd2" minlength="6" maxlength="10">
                    <mat-icon matSuffix (click)="togglePasswordVisibility()">
                        {{showPassword?'visibility_off':'visibility'}}
                    </mat-icon>
                    <mat-error *ngIf="myForm.controls['pwd2'].invalid &&
                        (myForm.controls['pwd2'].dirty || myForm.controls['pwd2'].touched)">
                        ReType PassWord required.
                    </mat-error>
                  </mat-form-field>
                  
                <div fxFlex="90">
                    <button mat-flat-button color="primary" (click)="saveData()">Save Data</button>
                </div>
                
               
            </form>
        </div>
    </div>