import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShardModule } from '../../shard/shard.module';
import { QpsubjectspecificComponent } from './qpsubjectspecific/qpsubjectspecific.component';
import { StudyComponent } from './study/study.component';
import { SpecificpracticeComponent } from './specificpractice/specificpractice.component';
import { PracticeComponent } from './practice/practice.component';
import { ExamComponent } from './exam/exam.component';
import { ViewresultComponent } from './viewresult/viewresult.component';
import { BookletexamComponent } from './bookletexam/bookletexam.component';

@NgModule({
  declarations: [QpsubjectspecificComponent, StudyComponent, SpecificpracticeComponent, PracticeComponent, ExamComponent, ViewresultComponent, BookletexamComponent],
  imports: [
    CommonModule, ShardModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ExamsModule { }
