import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { DialogService } from '../../../shard/service/dialog.service';
@Component({
  selector: 'app-specificpractice',
  templateUrl: './specificpractice.component.html',
  styleUrls: ['./specificpractice.component.css']
}) 
export class SpecificpracticeComponent implements OnInit {
  loading:boolean=true;
  user_id:any;
  private_key:any;
  Chapter:any;
  errorMessage:any;
  sub_cat_id:any;
  specific_id:any;
  qp_id:any;
  Questions:any;
  QuestionsLength:any;
  isLoaded:any;
  true_answer:any;
  false_answer:any;
  message:any;
  AssessmentData:any;
  sanitizer:any;
  constructor(private dialogService: DialogService,private route: ActivatedRoute,private router: Router, private location: Location,  private _subscriptionService: SubscriptionService) { }
  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.sub_cat_id = this.route.snapshot.paramMap.get('sub_cat_id');
    this.specific_id = this.route.snapshot.paramMap.get('specific_id');
    this.qp_id = this.route.snapshot.paramMap.get('qpid');
    this.showSpecific();
    this.loadQuestions();
    this.true_answer=0;
    this.false_answer=0;
    this.Chapter = [{subject:"",name:""}];
  }

  reloadQuestions(): void{
    window.location.reload();
  }

  goBack(): void {
    this.location.back();
  }
  
  showSpecific(): void {
		this.Chapter = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.specificnames + '?specificid='+this.specific_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
    data => {
      this.Chapter = data;
      if(this.Chapter.Message=='Unauthorised Access.!!') {
        this.router.navigate(['/loginform']); return;
      }
    },
    error => this.errorMessage = <any>error);
  }

  errorNotification(){
    const dataToComponent = {
      title: "Access Denied!",
      message: this.message,
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }
 
  loadQuestions():void {
		this.Questions = [];
    
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.specificpractice + '?qpid='+this.qp_id+'&user_id='+this.user_id + '&private_key=' + this.private_key +'&centerid=0&specificid='+this.specific_id).subscribe(
      data => {
        let HttpResponse=""; 
        this.Questions = data; 
        if(this.Questions[0].Question){ 
          HttpResponse=this.Questions[0].Question;
          if(HttpResponse.substring(0,27)=='Maximum Study Count Reached'){
            this.message=this.Questions[0].Question;
            this.errorNotification();
            this.router.navigate(['/subscribenow']);
          }
        }  
        if(this.Questions[0].Question){ console.log(this.Questions[0].Question);
          if(this.Questions[0].Question=='Your Subscription Expired'){
            this.message="Your Subscription Expired. Please recharge your wallet and subscribe for this examination.!!";
            this.errorNotification();
            this.router.navigate(['/subscribenow']);
          }
       }  
        this.QuestionsLength=this.Questions.length;
        //Start Removing first p tag and its closing from qname and aqns
        this.Questions.forEach((e: any) => {
          if (e.name){
            if (e.qname?.substring(e.qname.length - 4, e.qname.length) == '</p>') {
              e.qname = e.qname.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch1){
            if (e.ch1.substring(e.ch1.length - 4, e.ch1.length) == '</p>') {
              e.ch1 = e.ch1.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch2){
            if (e.ch2.substring(e.ch2.length - 4, e.ch2.length) == '</p>') {
              e.ch2 = e.ch2.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch3){
            if (e.ch3.substring(e.ch3.length - 4, e.ch3.length) == '</p>') {
              e.ch3 = e.ch3.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch4){
            if (e.ch4.substring(e.ch4.length - 4, e.ch4.length) == '</p>') {
              e.ch4 = e.ch4.replace("<p>", "").slice(0,-4); 
            }
          }
        }); 
        //End Removing first p tag and its closing from qname and aqns	
        this.loading=false;			
        this.isLoaded=true;
      },
      error => this.errorMessage = <any>error);
	}; 

  getStatus(obj:any): boolean { 
		if(obj.stat==0) 
      return false
    else
      return true;
    };   

  getScore(question:any,index:any,icon:any,$event:any): void {
    let answer=0;
    question.stat = 1;
    if ($event.target.value == question.qans){ 
      answer=1;
      this.true_answer++; 
      $("#IC" + $event.target.value + index).css("background-color", "green");
      $("#IC" + $event.target.value + index).css("color", "white");
    }
    else
    {   
      this.false_answer++;
      $("#IC" + $event.target.value + index).css("background-color", "red");
      $("#IC" + question.qans + index).css("background-color", "green");
      $("#IC" + $event.target.value + index).css("color", "white");
      $("#IC" + question.qans + index).css("color", "white");
    } 
    this.AssessmentData = {
      'user_id':this.user_id,
      'private_key':this.private_key,
      'qpid':this.qp_id,
      'qid':question.qid,
      'subcatid':question.subcatid,
      'specificid':question.specificid,
      'qans':answer,
      'attempttype':4,
      'attemptid':1
  };
    this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.assessment, this.AssessmentData).subscribe(
      data => {
      },
      error => this.errorMessage = <any>error)
  }

}
