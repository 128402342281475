import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.css']
})
export class FaqPageComponent implements OnInit {
  
  
 
  constructor() {
   
   }

  ngOnInit(): void {
  }

  
}
