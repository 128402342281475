import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlocksRoutingModule } from './blocks-routing.module';
import { AppComponent } from './root/app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '../core/intercepter/http-error.interceptor';
import { SpinnerService } from '../shard/widgets/progressbar/progress.service';
import { ShardModule } from '../shard/shard.module';

@NgModule({
  declarations: [ AppComponent, HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    BlocksRoutingModule,
    ShardModule,
  ],
  providers: [
    SpinnerService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }], 
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class BlocksModule { }
