    <div class="about " fxLayout="column">
		<div *ngIf="loading" style="display: flex;justify-content: center; align-items: center;">
			<mat-spinner></mat-spinner>
		</div>
        <div class="row text-center">
            <h3>{{Paper[0].examname}} (Mock Test) </h3>
        </div>
        
        <div class="row" style="display: flex;justify-content: space-between;">
            <div><label>{{Paper[0].name}}</label></div>
            <div><label>Total Mark : {{Paper[0].totalquestions}}</label></div>
            <div><label>Time : {{countTimer}} out of {{Paper[0].qpminutes}} Minutes</label></div>
		</div>

        <div class="row" style="border-top: solid 1px; padding-top: 20px;">
			<div id="dvQuestions" *ngIf="QuestionsLength>1 && isLoaded==true">
				<div class="examdiv" style="margin-bottom: 20px; width: 100%;" *ngFor="let quest of Questions; let indexOfelement=index;">
					<div class="qst-line"  [innerHTML]="(indexOfelement + 1) + '. &nbsp;' + quest.qname" ></div>
						<div class="choice-line">
							<input class="opt-radio" type="radio" id="rdch1{{indexOfelement}}" [disabled]="getStatus(quest)"
								(click)="getScore(quest,indexOfelement,$event)"
								value="1">
							<label class="opt-label" for="rdch1{{indexOfelement}}" [innerHTML]="quest.ch1"></label>
						</div>
					<div class="choice-line">
						<input class="opt-radio" type="radio" id="rdch2{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,$event)"
							value="2">
						<label class="opt-label" for="rdch2{{indexOfelement}}" [innerHTML]="quest.ch2"></label>
					</div>
					<div class="choice-line" >
						<input class="opt-radio" type="radio" id="rdch3{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,$event)"
							value="3">
						<label class="opt-label" for="rdch3{{indexOfelement}}" [innerHTML]="quest.ch3"></label>
					</div>
					<div class="choice-line">
						<input class="opt-radio" type="radio" id="rdch4{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,$event)"
							value="4">
						<label class="opt-label" for="rdch4{{indexOfelement}}" [innerHTML]="quest.ch4"></label>
					</div>
				</div>
			</div>																																																							
			<div>
				<button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
				<!-- <button	mat-flat-button class="btnbluedark float-right" (click)="reloadQuestions()">Next</button>  -->
				<button mat-flat-button class="btnbluedark" (click)="viewResult()">View Result</button>
			</div>
		</div>

    </div>
        
