import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../shard/service/subscription.service'; 
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../shard/service/dialog.service'
import { Platform} from '@angular/cdk/platform';
@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.css']
})
export class LoginformComponent implements OnInit {
  public showPassword: boolean = false;
  errorMessage:any;
  getUserData:any;
  jsondata:any;
  httpResponse:any;
  myForm: FormGroup = new FormGroup({});
  constructor(public platform: Platform, private dialogService: DialogService,private route: ActivatedRoute,private router: Router,private fb: FormBuilder, private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    //console.log(this.platform);
    this.createForm();
  }

  createForm(){
    this.myForm = this.fb.group({
      unm:[null, [Validators.required]],
      pwd:[null, [Validators.required]],
      });
  };

  loginNotification(){
    const dataToComponent = {
      title: "Browser Error.!!",
      message: "Please use Google Chrome to access our subscription pages.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  loginErrorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Login ID or PassWord invalid...!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  validateLogin(): void {
     if(this.platform.FIREFOX  == true) {
       this.loginNotification();
       this.router.navigate(['/home']);
       return;
     }
     
    if (this.myForm.invalid) {
      this.errorNotification();
        return;
    }
    this.jsondata = JSON.stringify(this.myForm.value); 
    console.log(this.jsondata);
    this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.login, this.jsondata).subscribe(
    data => {
      this.httpResponse = data; console.log(data);
      if(this.httpResponse.isvalid==="Yes") 
      {
        //sessionStorage.setItem('User',JSON.parse(this.httpResponse));
        sessionStorage.setItem('name',this.httpResponse.fullname);
        sessionStorage.setItem('loginname',this.httpResponse.loginname);
        sessionStorage.setItem('usertype',this.httpResponse.usertype);
        sessionStorage.setItem('edulevelid',this.httpResponse.edulevelid);
        sessionStorage.setItem('standardid',this.httpResponse.standardid);
        sessionStorage.setItem('password',this.httpResponse.password);
        sessionStorage.setItem('user_id',this.httpResponse.userid);
        sessionStorage.setItem('private_key',this.httpResponse.pkey);
        this.router.navigate(['/dashboard']); 
      }
      else
      {
        this.loginErrorNotification();
      } 
    },
    error => this.errorMessage = <any>error); 
  };

  validateUser(): void{
    this.router.navigate(['/regform']); 
  }

}
