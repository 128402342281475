<div style="margin:20px; margin-top:80px;">
    <h2>Oppam Platform - Terms and Conditions</h2>

    <p>
      Welcome to Oppam! We hope that you have a great experience using our
      Platform.
    </p>
    <p>
      <br>
    </p>
    <p>
      These Terms and Conditions (“Terms and Conditions”) set out the terms and
      conditions for use of
      <a href="https://oppam.in/">https://oppam.in/</a> (the
      <b>“Site”</b>), the mobile application(s) (the <b>“Application”</b>) and any
      features, subdomains, content (except as specified hereunder),
      functionality, products, services (including the Services), media,
      applications, or solutions offered on or through the Site and/or the
      Application and/or through any modes, medium, platform or format, including
      through Secure Digital (‘SD’) cards, tablets or other storage/transmitting
      device (hereinafter collectively referred to as the
      <b>“Platform”/ “Oppam Platform”</b>).
    </p>
    <p>
      <br>
    </p>
    <p>
      These Terms and Conditions apply to all users of the Platform, including
      without limitation, all persons who create and / or make available Content
      Provider Content ( as defined under the
      <b>‘Intellectual Property’</b> section below) on the Platform (referred to
      as <b>“Content Providers”</b>) and users who access the Platform to learn a
      subject or a skill (referred to as <b>“Learners”</b>) or users who access
      the Platform otherwise (collectively referred to as <b>“you”, “your”</b>, or
      <b>“User(s)”</b>). These Terms and Conditions along with the Privacy Policy,
      Refund Policy, Cookies Policy, Accessibility Statement, the User Guidelines
      and any other terms and conditions, rules, policies or guidelines included
      in these Terms and Conditions or Privacy Policy by reference and/or as
      updated on the Platform or otherwise communicated to you from time to time
      (including terms and conditions of other third party platforms that may be
      used or accessed for gaining access to the Platform or using the Services on
      the Platform (collectively referred to as the <b>“Platform Term(s)”</b>),
      defines the relationship between you and Oppam, and they shall govern
      your use of the Platform and the Services (defined below) offered therein.
      Your access to the Platform is subject to your acceptance of the Platform
      Terms and such acceptance of the Platform Terms forms a legally binding
      agreement between you and Oppam <b>(“Agreement”)</b>. Hence, please take
      your time to read the Platform Terms in their entirety.
    </p>
    <p>
      <br>
    </p>
    <p>
      From time-to-time, updated versions of the Platform Terms may be made
      available as aforesaid for your reference. By visiting and accessing the
      Platform, providing your Personal Information (
      <i>as defined in the Privacy Policy</i>), using the Services offered or by
      otherwise signalling your agreement when the option is presented to you, you
      hereby expressly accept and agree to the Platform Terms. If you do not agree
      to any of the terms or do not wish to be bound by them, then please do not
      use the Platform in any manner.
    </p>
    <p>
      <br>
    </p>
    <p>
      When we speak of <b>“Oppam”, “we”, “us”,</b> and <b>“our”</b>, we
      collectively mean <b>Oppam Private Limited</b> – a
      company incorporated in India and all of its affiliates which have license
      to host the Platform and offer Services. Kindly refer to the
      <b>‘ About Oppam and the Platform ’</b> section of these Terms and
      Conditions to know which Oppam entity your Agreement will be with.
    </p>
    <p>
      <br>
    </p>
    <p>
      All capitalized terms are defined/have the meaning assigned to it under
      these Terms and Conditions. In other words, if you find any word herein that
      has its first letter capitalized, then it means that we have explained the
      meaning such word has in the context of these Terms and Conditions and/or
      the Platform Terms; if not here, then it will be provided in the relevant
      Platform Terms – when we refer to any such term in these Terms and
      Conditions, we will try and specify where in the Platform Terms you can find
      the meaning/definition.
    </p>
    <br>
    <br>
    <a name="about">
      <h3>A. About Oppam and the Platform</h3>
    </a>
    <p>
      In India, the Platform is owned, managed, operated and offered by OPPAM
      Private Limited, a company incorporated under the (Indian)
      Companies Act, 2013, having its registered office at Akshaya E Centre, 3rd Floor,A-Block, Andamkovval, Kunhimangalam, Kannur- 670309, Kerala, India.
      <b>(“Oppam India”)</b>. In any jurisdiction other than India,
      wherever Services are rendered through the Platform, the same is offered by
      Oppam India and / or its affiliates (as relevant pursuant to appropriate
      intra group contractual arrangements). For knowing with which entity of
      Oppam your Agreement will be, please refer the list in
      <b>Annexure A</b> below.
    </p>
    <br>
    <br>
    <a name="platform">
      <h3>B. Platform Services:</h3>
    </a>
    <p>
      Oppam Platform is an online service platform enabling Content Providers
      to create and offer content in various formats including without limitation
      audiovisuals, audio, pre-recorded audiovisuals, live audiovisuals and/or in
      written form in a diverse range of categories through various modes and
      means <b>(“Content Provider Content”)</b> and publish and make available
      such Content Provider Content to the Learners. Oppam acts as an
      intermediary between the Content Providers and Learners [in accordance with
      the Information Technology Act, 2000, or the Digital Millennium Copyright
      Act (as amended from time to time)] or other equivalent / similar
      legislations and makes available the Content Provider Content to the
      Learners, including through subscription offerings (
      <i>refer to the Subscription Terms below to know more</i>). Oppam may
      also offer, either directly or indirectly (through other third party
      platforms), other incidental and ancillary services which may include
      without limitation question banks, mock tests, doubt-solving solutions, Live
      Mentorship (
      <i>as explained <a href="/live-mentorship-terms"> here</a> </i>), live
      videos (as explained
      <i>as explained <a href="/classroom-terms"> here</a> </i>), contests,
      scholarships, webinars and events (physical and/or online) and facilitating
      discussion forums.
    </p>
    <p>
      <br>
    </p>
    <p>
      Further, Oppam may offer certain products/services to you in
      collaboration with one or more of the Oppam Group Platforms (
      <i>as defined under the Privacy Policy</i>). In such cases, to the extent
      that such product/services are made available to you through the Platform,
      the Platform Terms shall continue to apply to you.
    </p>
    <p>
      <br>
    </p>
    <p>
      For the purpose of the Platform Terms, usage of the terms
      <b>“Platform Services”/ “Services”</b> shall mean and include all the
      services referred to in this section and such other products or services as
      may be offered by Oppam.
    </p>
    <p>
      <br>
    </p>
    <p>
      You agree and acknowledge that Oppam has no control over and assumes no
      responsibility for, the User-generated Content (
      <i>as defined under the ‘Intellectual Property’ section below</i>) and by
      using the Platform, you expressly relieve Oppam from any and all
      liability arising from the User-generated Content.
    </p>
    <p>
      <br>
    </p>
    <p>
      You agree and acknowledge that Oppam shall have the right at any time to
      change or discontinue any Service, product, aspect, or feature of the
      Platform, including without limitation to, the User-generated Content (to
      the extent it is permitted to, or required, by virtue of being an
      ‘intermediary’), its availability and Supported/Compatible device required
      to access or use the Services. Oppam reserves the right to terminate
      your Subscription and / or restrict your access to the Platform, if you
      choose not to provide / partially provide the requested information.
    </p>
    <br>
    <br>
    <a name="rights_to_accounts">
      <h3>C. Right to Access and Account Creation</h3>
    </a>
    <p>
      As a Learner, to access the Platform and use certain Services, you will be
      required to register and create an account on the Platform by providing the
      requested details.
    </p>
    <br>
    <p>
      To register on the Oppam Platform you must meet the ‘Age Requirements’
      specified hereinbelow. By using the Oppam Platform, you, through your
      actions, affirm that the Age Requirements are met.
    </p>
    <br>
    <p>
      You can register on the Platform for free however, certain Services offered
      on the Platform may be chargeable. Please review the Service offerings on
      our Site or Application(s). You are not obligated to purchase any product or
      use any Service offered on the Platform.
    </p>

    <p>
      A Learner who does not fulfill the Age Requirements mentioned above (as may
      be revised as per applicable laws from time to time) and is desirous of
      registering on the Platform i.e., if a Learner is a <b>“Minor”/ “Child”</b>,
      then the Learner may use the Platform with the consent of, and under the
      supervision of, their parent or legal guardian <b>(“Parent”)</b>.
      Accordingly, in such a case, the Parent must agree to the Platform Terms at
      the time of their registration on the Platform. Please note that
      minors/children are not by themselves eligible to register on the Platform.
      When a Minor/Child uses the Platform, we assume that the Parent of such
      Minor/Child has enabled the Minor/Child’s usage of the Platform by agreeing
      to the Platform Terms and that such usage is under the supervision of their
      Parent.
    </p>

    <p>
      Oppam reserves the right to terminate your Subscription and / or
      restrict your access to the Platform, if it is discovered that you do not
      meet the Age Requirements and/or the consent to register and use the
      Platform is not obtained as above. You acknowledge that Oppam does not
      have the responsibility to ensure that You conform to the aforesaid Age
      Requirements. It shall be Your sole responsibility to ensure that the
      required qualifications are met.
    </p>

    <p>
      If you are a Content Provider, your access and use of the Platform may
      additionally be subject to separate agreement(s) with Oppam and shall be
      collectively governed by the terms of such agreement, the Terms and
      Conditions, other Platform Terms, and such other terms as may be
      communicated or agreed with the Content Provider from time to time.
    </p>

    <p>
      Further, the permission given by Oppam to access and use the Platform
      under these Terms and Conditions is subject to the following conditions:
    </p>
    <ol type="1">
      <li>
        <p>
          You agree not to distribute in any medium any part of the Platform,
          Content Provider Content or the Oppam Content (as defined below)
          without Oppam’s prior written authorization.
        </p>
      </li>
      <li>
        <p>You agree not to alter or modify any part of the Platform.</p>
      </li>
      <li>
        <p>
          You agree not to access User-generated Content of any other User through
          any technology or means other than the User-generated Content you have
          legitimate access to.
        </p>
      </li>
      <li>
        <p>
          You agree not to use the Platform for any of the following commercial
          uses unless you obtain Oppam’s prior written approval:
        </p>
        <ol type="a">
          <li>the sale of access to the Platform;</li>
          <li>
            the sale of advertising, sponsorships, or promotions placed on or
            within the Platform or Content Provider Content; or
          </li>
          <li>
            the sale of advertising, sponsorships, or promotions of any
            third-party page or website.
          </li>
        </ol>
      </li>
      <li>
        <p>
          You agree to receive installs and updates from time to time from
          Oppam. These updates are designed to improve, enhance, and further
          develop the Platform and may take the form of bug fixes, enhanced
          functions, new software modules and completely new versions. You agree
          to receive such updates (and permit Oppam to deliver these to you)
          as part of your use of the Platform.
        </p>
      </li>
      <li>
        <p>
          You agree not to use or launch any automated system, including without
          limitation, “robots,” “spiders,” or “offline readers,” that accesses the
          Platform in a manner that sends more request messages to Oppam’s
          servers in a given period of time than a human can reasonably produce in
          the same period by using a conventional on-line web browser.
          Notwithstanding the foregoing, Oppam grants the operators of public
          search engines permission to use spiders to copy materials from the Site
          for the sole purpose of and solely to the extent necessary for creating
          publicly available searchable indices of the materials, but not caches
          or archives of such materials. Oppam reserves the right to revoke
          these exceptions either generally or in specific cases at any time with
          or without providing any notice in this regard. You agree not to collect
          or harvest any personally identifiable information, including account
          names, from the Platform, nor to use the communication systems provided
          by the Platform, unless otherwise mentioned herein.
        </p>
      </li>
      <li>
        <p>
          You may post reviews, comments and other content; send other
          communications; and submit suggestions, ideas, comments, questions or
          other information as long as the content is not illegal, obscene,
          threatening, defamatory, invasive of privacy, infringement of
          intellectual property rights, or otherwise injurious to third parties or
          objectionable and does not consist of or contain software viruses,
          political campaigning, commercial solicitation, chain letters, mass
          mailings or any other form of spam. In other words, ensure that your
          conduct on the Platform is in accordance with the Platform Terms,
          including the User Guidelines.
        </p>
      </li>
      <li>
        <p>
          In your use of the Platform, you will, at all times, comply with all
          applicable laws and regulations.
        </p>
      </li>
      <li>
        <p>
          Oppam reserves the right to discontinue any aspect of the Platform
          at any time with or without notice at its sole discretion.
        </p>
      </li>
      <br>
    </ol>
    <p>
      You are responsible for maintaining the confidentiality of your account and
      password to access the Platform. You acknowledge that your account is
      personal to you and agree not to provide any other person with access to the
      Platform and to restrict access to your device to prevent any unauthorized
      access to your account. You agree to accept responsibility for all
      activities that occur under your account. You should use particular caution
      when accessing your account from a public or shared computer so that others
      are not able to view or record your username, password, or other Personal
      Information. You should take all necessary steps to ensure that the password
      is kept confidential and secure at all times, and if you have any reason to
      believe that your password has become known to anyone else, or if the
      password is being, or is likely to be, used in an unauthorized manner, you
      agree to immediately change your password or inform us of any unauthorized
      access to or use of your username or password, so that we are able to help
      you stop or prevent such unauthorized access. Please ensure that the details
      you provide us are correct and complete.
    </p>
    <br>
    <p>
      You represent that the information provided by you at the time of the
      registration are correct, true and accurate, and you agree to update the
      same as and when there is any change in the said information. Please read
      our Privacy Policy to understand how we handle your information.
    </p>
    <br>
    <p>
      Please note that we reserve the right to reject or put on-hold your
      registration on the Platform as may be required to comply with any legal and
      regulatory provisions, and also reserve the right to refuse access to the
      Platform, terminate accounts, remove content at any time without providing
      any notice to you, and/or reserve the right to disable any account, feature,
      or identifiers, whether chosen by you or provided by us, at any time if, in
      our opinion, the said identifiers or you have violated any provision of the
      Platform Terms.
    </p>
    <br>
    <p>
      Further, to access the Platform, create, publish and/or view the
      User-generated Content on the Platform, you will need to use a
      <b> “Supported/Compatible Device”</b> which means a personal computer,
      mobile phone, portable media player, or other electronic device that meets
      the system and compatibility requirements and on which you are authorized to
      operate the Platform. The Supported/Compatible Devices to access the
      Platform and/or avail the Services may change from time to time and, in some
      cases, whether a device is (or remains) a Supported/Compatible Device may
      depend on software or systems provided or maintained by the device
      manufacturer or other third parties. Accordingly, devices that are
      Supported/Compatible Devices at one time may cease to be
      Supported/Compatible Devices in the future. Thus, kindly make sure that the
      device that you use is compatible with our system/software to use the
      Platform and avail the Services offered therein.
    </p>
    <br>
    <br>
    <a name="terms">
      <h3>D. Subscription terms</h3>
    </a>
    <p>
      For Learners, Oppam does not charge any fee for registration and account
      creation. However, certain Services offered by Oppam may be chargeable.
      Accordingly, access to certain Services and features is offered by Oppam
      through a multi- tiered paid subscription plan(s) or purchases; the details
      of the Services and applicable features along with their corresponding
      prices can be found on our <a href="https://oppam.in">Site</a> and/or
      Application <b>(“Subscription”/ “Subscription Service”)</b>. You can
      purchase Subscriptions by following the instructions you encounter as you
      navigate through the Platform.
    </p>
    <br>
    <p>The terms that are applicable to Your purchase of Subscription(s) -</p>
    <ol type="1">
      <li>
        <p>
          Purchase of a Subscription will allow you access only to the content
          available under the category of content for which you have purchased the
          Subscription.
        </p>
      </li>
      <li>
        <p>
          The Services offered, and the validity/term of your Subscription
          <b>(“Subscription Period”)</b>may vary depending on the plan you may
          purchase. Hence, before you proceed to purchase any Subscription, please
          read and understand the details of the Subscription(s) you intend to
          purchase on the Platform. If you are unclear about any part of the
          Subscription offering or need further clarification, then please feel
          free to write to us prior to your purchase at email address provided in
          the ‘Contact for User Support/Queries’ section below.
        </p>
      </li>
      <li>
        <p>
          We may personalize Services and feature them as part of Subscriptions,
          including showing you recommendations on content in the subscribed
          category, and other related categories that might be of interest to you.
          We also endeavor to continuously improve the Subscription offerings to
          improve your Platform experience.
        </p>
      </li>
      <li>
        <p>
          Subscription Period may be extended upon renewal of your already
          purchased Subscription. The terms of renewal, if any, can be found on
          our <a href="https://oppam.in">Site</a> and/or Application.
          However, please note that the prices may stand revised from the time of
          your first purchase of the Subscription.
        </p>
      </li>
      <li>
        <p>
          The Subscription is of a personal nature and is solely for the benefit
          of the person subscribing and is not allowed to be resold by you or
          transferred to or shared with any other person for consideration or
          otherwise. In the event we get to know that any User has resold /
          transferred / shared Subscription with another person, then Oppam
          retains the right to cancel/terminate the Subscription forthwith.
        </p>
      </li>
      <li>
        <p>
          Due to the limitations, if any, imposed on us by our Content Providers
          (who own the Content Provider Content), the Content Provider Content we
          make available to you under any Subscription offering are subject to
          restrictions on viewing access and on the length of time we make them
          available to you. These restrictions may change over time as we add new
          features, devices and content to our Subscription Service.
          Illustratively, following are some of the restrictions that are
          applicable to your access to the content (made available through
          Subscription Service or otherwise):
        </p>
        <ol type="a">
          <li>
            <p>
              <b>Live – Streaming of Content Provider Content/ Live Videos:</b>
              You will get access to attend live videos (in other words, live-
              streamed content), if the same is offered as part of your purchased
              Subscription. The access shall be available to you through the
              Platform during the Subscription Period and shall be subject to the
              Platform Terms including the
              <a href="/classroom-terms">Live Videos Terms and Conditions.</a>
              Please be sure to use a Supported/Compatible device (refer to the
              <i>‘Right to Access and Account Creation’</i> section above) while
              accessing the live videos.
            </p>
          </li>
          <li>
            <p>
              <b>Viewing Period:</b> Depending on the Subscription, we may make
              available the recorded versions of any relevant live video to you
              for continued viewing on the Platform (which may include in-app
              downloading as mentioned below) during your Subscription Period.
              Please note that availability of the recorded versions of any
              relevant live video content for your subsequent access on the
              Platform is subject to our agreements with the Content Providers,
              and we do not in any manner guarantee the availability of the same
              for any reason whatsoever.
            </p>
          </li>
          <li>
            <p>
              <b>In-App Downloading:</b> Depending upon your Subscription, you may
              only have an option to do in-app downloads and the downloaded
              content can be viewed by you during the Subscription Period. After
              downloading the Content Provider Content, you shall have access to
              the same for the Subscription Period only and all access to the
              downloaded content shall be removed on expiry or termination of your
              Subscription, as the case maybe.
            </p>
          </li>
          <li>
            <p>
              <b>Quality of Streaming:</b> The resolution and quality of the
              content you receive will depend on a number of factors, including
              the type of Compatible Device on which you are accessing the Content
              Provider Content and your bandwidth, which may increase or decrease
              over the course of your viewing. While we strive to provide you a
              high-quality viewing experience, we make no guarantee as to the
              resolution or quality of the content you will see when streaming.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          Depending on the Subscription Service you have purchased, you may be
          given access to certain additional Services and features. The additional
          terms applicable to each of these additional Services may be made
          available to you on the Platform in the form of terms and conditions or
          FAQs (Frequently Asked Questions) for that specific Service or may be
          otherwise communicated to you. Hence, please be sure to go through the
          Platform Terms to understand the additional services, if any made
          available to you, and how you can avail the same.
        </p>
      </li>
    </ol>
    <br>
    <br>
    <a name="pricing">
      <h3>E. Pricing, Payments and Refunds</h3>
    </a>
    <ol type="1">
      <li>
        <p>
          <b>Pricing and Payments:</b> You can purchase a Subscription Service of
          your choice for any category(ies) of content by following instructions
          on the Platform and making the payment applicable for the Subscription
          you intend to purchase.
        </p>
        <br>
        <p>
          Please read the below terms applicable for the purchase of your Platform
          Subscription. The below terms are to be read with any other terms
          communicated to you at the time of purchase of your Subscription:
        </p>
        <ol type="a">
          <li>
            <p>
              You agree to pay all Subscription fees and charges that are
              attributable to your account on the Platform and that you are solely
              responsible for payment of these fees and charges. The Subscriptions
              are payable in full and in advance and are valid until the
              completion of the applicable Subscription Period or until otherwise
              cancelled or terminated in accordance with the terms of this
              Agreement.
            </p>
          </li>
          <li>
            <p>
              If you have specifically authorized us, then the payments for the
              applicable Subscriptions are automatically charged at the beginning
              of each billing period, unless you withdraw your authorization or
              submit a cancellation request to us directly through your account
              prior to the start of the billing period or in writing via email to
              the address specified in the ‘Contact for User Support/Queries’
              section below. The payment for your Subscription will be charged
              upon the anniversary of its billing period if the payments for
              Subscription are in more than a single tranche. Subject to your
              specific authorization and applicable laws, you agree that Oppam
              may charge any recurring service to the credit card or debit card or
              account that you provide/link at the time of your first purchase of
              the Subscription or as updated by you through your account on the
              Platform, provided such updation takes place prior to upcoming
              billing period.
            </p>
          </li>
          <li>
            <p>
              If you have not completed payments for your Subscriptions, we may
              restrict / suspend your access to the Platform until your account
              becomes current and paid in full.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to pursue the fee owed to us using collection
              methods which may include charging other payment methods on file
              with us and/or retaining collection agencies or legal counsel.
            </p>
          </li>
          <li>
            <p>
              The entity within the Oppam group to which your payments will be
              made will be on the basis of <b>Annexure 1.</b>
            </p>
          </li>
          <li>
            <p>
              Your payments to Oppam shall be subject to applicable taxes
              including without limitation Goods and Service Taxes (GST) and Value
              Added Taxes (VAT) or other similar taxes as may be applicable in
              your country of residence/from where you have created your account
              on the Platform/ purchased the underlying Subscriptions.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to change/revise the pricing of the
              Subscriptions. For existing Subscriptions for which the applicable
              fees have been already received by us, we will implement the price
              changes during the next billing period or renewal of the
              Subscription.
            </p>
          </li>
          <li>
            <p>
              We further reserve the right to offer custom plans and pricing
              (including discounts and / or special offers) in addition to what is
              offered on the Platform, which include offering custom billing and
              payment terms, that are different from our standard terms.
            </p>
          </li>
          <li>
            <p>
              We use third-party payment gateways and/or aggregators to process
              payments applicable to the Services offered by us. Third-party
              payment gateway(s) made available to you may vary depending on the
              Subscription you choose. Similarly, we have also enabled integration
              of third-party payment providers to facilitate better payment
              options to you, which may vary depending on your territory or the
              Subscription you choose. Third-party payment gateways/aggregators
              and third- party payment providers shall collectively be referred to
              as <b>“Third-Party Service Providers”</b>.
            </p>
            <br>
            <p>
              Third-Party Service Providers may also charge you fees to use or
              access their services and may require your Personal Information to
              complete any transaction for the Platform. Further, to facilitate
              completion of your payments to us through the Platform or avail the
              payment options provided to you, you may be redirected to an
              external website operated by the Third-Party Service Provider. We
              cannot and do not (i) guarantee the adequacy of the privacy and
              security practices employed by or the content and media provided by
              the Third-Party Service Provider or its respective websites or (ii)
              control collection or use of your Personal Information by such
              Third-Party Service Provider. Hence, prior to using any services
              offered by a Third-Party Service Provider, we suggest that you read
              their terms and conditions, privacy policy and other policies, that
              may apply, to understand their terms of usage and to understand how
              your Personal information is being processed. Oppam is not
              affiliated to any Third-Party Service Provider and neither Oppam
              nor any of the Third-Party Service Provider are agents or employees
              of the other.
            </p>
            <br>
            <p>
              Further, pursuant to the payment option you may choose, you may be
              required to enter into a separate agreement with the relevant
              Third-Party Service Provider. This agreement with the Third-Party
              Service Provider is an independent contract/agreement between you
              and such Third-Party Service Provider and Oppam shall in no
              manner be a party to the same. Oppam is only facilitating
              various payment options to you and is not offering the payment by
              itself in any manner.
            </p>
          </li>
          <li>
            You agree that you are solely responsible for all charges that occur
            through such Third-Party Service Providers and acknowledge and agree
            to indemnify, defend, and hold harmless Oppam, its licensors,
            their affiliates, and their respective officers, directors, employees,
            and agents from any loss arising out of or related to the use of the
            Platform or any purchases made through the Platform. This obligation
            will survive your use of the Platform and termination of your
            Agreement with us. For purposes of the Platform Terms, “Loss” means
            all losses, liabilities, damages, awards, settlements, claims, suits,
            proceedings, costs, and expenses (including reasonable legal fees and
            disbursements and costs of investigation, litigation, settlement,
            judgment, interest, and penalties). Oppam shall not be liable to
            you for any claims arising out of any act or omission on the part of
            the Third-Party Service Provider(s) including, but not limited to, any
            lost, stolen, or incorrectly processed payments. Oppam expressly
            disclaims any responsibility and liability for all services provided
            by the Third-Party Service Provider(s).
          </li>
          <li>
            Please note that all Subscription payments are collected by Oppam
            only through the Platform and not through any third parties (except
            Third-Party Service Provider(s)). We do not usually authorize any
            third party (except Third-Party Service Provider(s)) to collect monies
            on our behalf; however, if we have authorized any third party then
            such third party will have received a written authorization from
            Oppam either by way of any agreement or an authorization letter.
            Kindly verify with such third party before you make any payments to
            them, alternatively, you can always check with us by writing to us at
            email address provided under the ‘Contact for User Support/Queries’
            section below.
          </li>
          <li>
            Further, Oppam is solely authorized to offer discounts / offers,
            if any, on the Subscription prices. These discounts / offers are
            communicated on the Platform or via direct communication to you from
            Oppam via email, SMS, phone, or such other means of communication,
            and can be availed only through the Platform, unless otherwise
            specifically communicated by Oppam. Other than Oppam, no
            person, including without limitation, Content Providers or any
            third-party platform, are allowed to offer any discounts on the
            Subscription prices offered on the Platform. Oppam shall not be
            liable for any claims arising from such unauthorized discounts /
            offers offered by any person (including any third- party platform or
            Content Provider), other than Oppam.
          </li>
        </ol>
      </li>
      <br>
      <li>
        <p>
          <b>Cancellation and Refund Policy:</b>
        </p>
        <p>
          You may cancel your Subscription through your account on the Platform.
          However, please note that the cancellation will become effective at the
          end of the then-current billing period; in other words, we will not
          renew your Subscription, but the existing Subscription will continue
          until the end of its billing period and there shall be no refund of the
          fee already paid for the same, unless otherwise specified in the
          <a href="/refund-policy"> Refund Policy</a>. So, please read these terms
          and conditions and the Refund Policy carefully before purchasing any
          Subscription.
        </p>
        <br>
        <p>
          When you cancel your Subscription, Oppam may disable access to
          features made available to you upon your purchase of Subscription, while
          your account may continue to exist on the Platform.
        </p>
      </li>
    </ol>
    <br>
    <br>
    <a name="use_of_platform">
      <h3>F. Use of the Platform</h3>
    </a>
    <p>
      Subject to the Platform Terms, Oppam hereby grants you a non- exclusive,
      non-transferable, non-sublicensable, limited license to access and use the
      Oppam Platform for your own personal, non-commercial and private use on
      an ‘as is’ basis in accordance with these Terms and Conditions and other
      Platform Terms.
    </p>
    <br>
    <p>
      Subject to payment of the Subscription fee, and your compliance (as a
      Learner) with all Platform Terms, Oppam and the Content Providers grant
      you (as a Learner) a non- exclusive, non-transferable, non-sublicensable,
      limited license, during the applicable Subscription Period, to access and
      view the content you have subscribed to and attend live videos in
      association with your Subscription, for personal, non-commercial, private
      use only, in accordance with the Platform Terms. We may automatically remove
      your access to the content from your Compatible Device after the end of the
      Subscription Period or in accordance with any restriction mentioned in the
      Platform Terms.
    </p>
    <br>
    <p>
      If you are a Content Provider, Oppam grants you a limited,
      non-exclusive, non- transferable, non-sublicensable license to access and
      use the Platform for Your own personal and commercial use in accordance with
      these Platform Terms and any other agreement(s) that may be executed between
      you (as a Content Provider) and Oppam (as applicable). Except as
      expressly permitted by Oppam under these Platform Terms or otherwise in
      writing to you, You will not reproduce, duplicate, copy, sell, redistribute,
      create derivative works or otherwise exploit the Platform or any portion of
      the Platform (including but not limited to any copyrighted material,
      trademarks, or other proprietary information).
    </p>
    <br>
    <p>
      Except as expressly permitted under any of the Platform Terms or otherwise
      in writing, you will not reproduce, duplicate, copy, sell, redistribute,
      create derivative works or otherwise exploit the Platform or any portion of
      the Platform (including but not limited to any copyrighted material,
      trademarks, or other proprietary information).
    </p>
    <br>
    <br>
    <a name="content_conduct">
      <h3>G. Content and Conduct</h3>
    </a>
    <ol type="1">
      <li>
        <p>
          As a User, you may submit User-generated Content (
          <i>as defined under the ‘Intellectual Property’ section below</i>) on
          the Platform. However, you must understand that Oppam does not
          guarantee any confidentiality with respect to any User-generated Content
          you submit.
        </p>
      </li>
      <li>
        <p>
          You shall be solely responsible for your own User-generated Content and
          the consequences of submitting and publishing such content on the
          Platform. You affirm, represent, and warrant that you own or have the
          necessary licenses, rights, consents, and permissions to publish the
          User-generated Content that you submit. You further agree that
          User-generated Content you submit on the Oppam Platform will not
          contain third party copyrighted material, or material that is subject to
          other third-party proprietary rights, unless you have permission from
          the rightful owner of the material, or you are otherwise legally
          entitled to post the material and to grant Oppam the necessary
          license and rights required under the Platform Terms. You shall solely
          be responsible for all claims in respect of your User-generated Content
          published on the Platform.
        </p>
      </li>
      <li>
        <p>
          For clarity, you retain your ownership rights in your User-generated
          Content. However, you grant a limited license to Oppam to make
          available the User-generated Content on the Platform. Please read the
          <i>‘Intellectual Property’</i> section below to know the exact nature of
          license and rights you grant to Oppam.
        </p>
      </li>
      <li>
        <p>
          Furthermore, you confirm that you shall not host, display, upload,
          modify, publish, transmit, store, update or share User-generated Content
          or any information on the Platform that:
        </p>
        <ol type="a">
          <li>
            <p>
              belongs to another person and to which you do not have any right;
            </p>
          </li>
          <li>
            <p>
              is defamatory, obscene, pornographic, invasive of another’s privacy
              including bodily privacy, insulting or harassing on the basis of
              gender, libelous, racially or ethnically objectionable, relating or
              encouraging money laundering or gambling, or otherwise inconsistent
              with or contrary to the laws in force;
            </p>
          </li>
          <li>
            <p>is harmful to a child;</p>
          </li>
          <li>
            <p>
              infringes any patent, trademark, copyright or other proprietary
              rights of another;
            </p>
          </li>
          <li>
            <p>
              violates any law, statue, ordinance or regulation or Platform Terms;
            </p>
          </li>
          <li>
            <p>
              deceives or misleads any User about the origin of the message or
              knowingly and intentionally communicates any information which is
              patently false or misleading in nature but may reasonably be
              perceived as a fact;
            </p>
          </li>
          <li>
            <p>impersonates another person;</p>
          </li>
          <li>
            <p>
              threatens the unity, integrity, defense, security or sovereignty of
              India, friendly relations with foreign States, or public order, or
              causes incitement to the commission of any cognizable offence or
              prevents investigation of any offence or is insulting other nation;
            </p>
          </li>
          <li>
            <p>
              contains software virus or any other computer code, file or program
              designed to interrupt, destroy or limit the functionality of any
              computer resource;
            </p>
          </li>
          <li>
            <p>
              is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity or
              agency for financial gain or to cause any injury to any person;
            </p>
          </li>
          <li>
            <p>False, inaccurate or misleading;</p>
          </li>
          <li>
            <p>obscene or contain pornography;</p>
          </li>
          <li>
            <p>
              Contain any viruses, trojan horses, worms, cancelbots or other
              computer programming routines that may damage, detrimentally
              interfere with, surreptitiously intercept, or expropriate any
              system, data or Personal Information.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          Your conduct on the Platform shall strictly be in accordance with the
          User Guidelines and other Platform Terms.
        </p>
      </li>
      <li>
        <p>
          You understand that as a User you may interact with Minors/Children
          (refer to <i>‘Right to Access and Account Creation’</i> section above)
          on the Platform; accordingly, you shall make yourself fully aware of our
          <a href="/minor-policy">Minor &amp; Learner Interaction Guidelines</a>
          and conduct yourself in accordance thereof.
        </p>
      </li>
      <li>
        <p>
          You understand and confirm that you shall not during your use of the
          Oppam Platform at any time post or publish any content, comments or
          act in any way which will amount to harassment of any other User. If at
          any given point it comes to Oppam’s notice that you have engaged in
          any kind of harassment of other Users, then in such a case you agree
          that Oppam shall have the sole right to suspend/terminate your
          account with immediate effect and without any notice of such suspension
          or termination and we also reserve the right in our sole discretion to
          initiate any legal proceedings against you in such cases.
        </p>
      </li>
      <li>
        <p>
          Oppam does not endorse any User-generated Content submitted on the
          Oppam Platform by any User, or any opinion, recommendation, or
          advice expressed therein, and Oppam expressly disclaims any and all
          liability in connection with User-generated Content. Oppam does not
          permit copyright infringing activities and infringement of intellectual
          property rights on the Oppam Platform, and Oppam will remove all
          User-generated Content if properly notified that such User-generated
          Content infringes on another’s intellectual property rights in line with
          its obligations as an ‘intermediary’. Oppam reserves the right to
          remove User- generated Content without prior notice if it has reason to
          believe that the User- generated Content is violative of these Terms and
          Conditions. Please refer to the
          <a href="/takedown-policy"> Takedown Policy</a> and
          <i>‘Copyright, Trademarks and other Intellectual Property Rights’</i>section below to understand the process to
          notify Oppam about any
          infringing content and the process of takedown of infringing content
          followed by Oppam.
        </p>
      </li>
      <li>
        <p>
          You shall not engage in any activity that increases/optimizes the User
          views on any particular Content Provider; these activities, without
          limitation, include creating multiple fake accounts as Learners, either
          by yourself or through a third-party.
        </p>
      </li>
      <li>
        <p>
          You shall not engage in any activity that will negatively impact
          Oppam and/or other Users of the Platform, including without
          limitation, activities that involve screen- recording, screen-casting or
          downloading on any other device of any Content Provider Content (live or
          recorded) in any manner that is not directly facilitated as feature
          within the Platform and/or sharing or otherwise publishing such
          screen-recorded content on third-party platforms, either for a cost or
          otherwise. If Oppam becomes aware of your engagement, either by
          yourself or through a third-party, in any such activity, then Oppam
          may immediately terminate your or such User’s access / Subscription
          along with termination and removal of access to all downloaded content
          with a right to initiate appropriate legal action, at the sole
          discretion of Oppam.
        </p>
      </li>
    </ol>
    <br>
    <br>
    <a name="communications">
      <h3>H. Communications</h3>
    </a>
    <p>
      When you visit the Platform, you are communicating with us electronically.
      You may be required to provide a valid phone number while creating any
      account with us or while enrolling or purchasing any Subscription Service.
      We may communicate with you by e- mail, SMS, phone call, WhatsApp or by posting
      notices on the Platform or by any other mode of communication. By providing
      your information you authorize Oppam, its affiliates, and their
      respective employees, agents, and contractors to initiate electronic
      communications by email, telephone calls, WhatsApp or such other mode of
      communication with respect to your use of the Platform and regarding the
      products and services offered by Oppam and its affiliates. These
      communications may be made by or on behalf of Oppam, even if your phone
      number is registered on any state or federal do not call list. Telephone
      calls may be recorded. You acknowledge that your telephone operator and/or
      internet service provider may have levied certain charges on you vis-à-vis
      your communications with Oppam as it constitutes availing their
      services, and you agree to be responsible for all such charges, and
      Oppam will not be responsible for any such charges. Do not submit your
      information if you do not consent to being contacted by telephone, text,
      email, WhatsApp or such other mode of communication. Further, you will always have an
      option to unsubscribe / opt out from any promotional communications sent by
      Oppam and / or its affiliates either by following the relevant
      instructions that may be communicated via text, email or such other mode of
      communication in which you received a promotional communication or by
      disabling the options provided within your account on the Platform.
    </p>
    <br>
    <p>Please note that –</p>
    <br>
    <ol type="1">
      <li>
        <p>
          If you opt out of promotional communications, we may still send you
          transactional communications, such as service announcements,
          administrative and legal notices, and information about your account,
          without offering you the opportunity to opt out of these communications.
          If you no longer wish to use our Platform or receive any communications
          from us (except for those that are legally required), then you may
          delete your account by either writing to us at the email address
          provided under <i>‘Contact for User Support/Queries’</i>section below by
          using the option enabled within the Platform; and
        </p>
      </li>
      <li>
        <p>
          opting out of promotional communications only affects future
          communications from us. If we have already provided your information to
          a third party (as stated in our Privacy Policy) before you changed your
          preferences or updated your information, you may have to change your
          preferences directly with that third party.
        </p>
      </li>
      <li>
        <p>
          We do not sell your Personal Information to third parties
        </p>
      </li>
    </ol>
    <br>
    <p>
      Further, in respect of interactions between Users - Oppam is only an
      intermediary and does not monitor any of the interactions that take place
      between Users on the Platform, but Oppam has clearly laid out the terms
      and guidelines a User must follow in their conduct on the Platform as part
      of the Platform Terms; and if Oppam receives from any User a complaint
      of misconduct against you, in any form, or if Oppam otherwise deems
      necessary, Oppam reserves the right to suspend or terminate your access
      to any the Platform or any part thereof at any time, with or without giving
      any notice or reason.
    </p>
    <br>
    <p>
      You acknowledge that any User-generated Content (including without
      limitation any Content Provider Content, chats, postings, or materials
      posted by the Users) posted by you on part of the Platform, is neither
      endorsed nor controlled by us. You further understand and agree that you
      shall be solely responsible for any User-generated Content published by you
      on the Platform, including its legality, reliability, accuracy, and
      appropriateness, and shall be solely liable for the consequences of its
      publication. You represent and warrant that you own and control all rights
      in and to any User-generated Content uploaded by you on the Platform, or
      that you have the necessary licenses or permissions or are legally entitled
      to use and reproduce such User-generated Content on the Platform.
    </p>
    <br>
    <br>
    <a name="processing_personal_information">
      <h3>I. Processing of Personal Information</h3>
    </a>
    <p>
      All our collection, processing, sharing and storing of any Personal
      Information collected from you shall be in accordance with our Privacy
      Policy. Kindly read the same to understand the security measured undertaken
      by Oppam to safeguard your Personal Information. Should have any queries
      in respect of the same, please feel free to right to us at
      <a href="mailto:info@oppam.in">info@oppam.in</a>.
    </p>
    <br>
    <br>
    <a name="intellectual_property_license">
      <h3>J. Intellectual Property and License to User-generated Content</h3>
    </a>
    <ol>
      <li>
        <p>
          <b>User-generated Content:</b> You shall remain the sole owner of any
          content uploaded or published or submitted or posted by you on the
          Platform, including without limitation, the content published by you as
          a Content Provider and/or any text, image, media, written statements or
          other content posted or published by a User anywhere on the Platform
          including without limitations in the comments section
          <b>(“User-generated Content”)</b>and Oppam does not claim any
          ownership over any User-generated Content uploaded/published by any User
          on the Platform.
        </p>
        <br>
        <p>
          By submitting the User-generated Content on the Platform, you hereby
          grant Oppam a limited, worldwide, non-exclusive, royalty-free,
          sub-licensable and transferable license to use, reproduce, distribute,
          share, display, publish, retain, make available online and/or
          electronically transmit such User-generated Content as well as technical
          information collected via the Platform to the extent necessary (i) to
          provide the Platform and our Services; (ii) to perform our obligations
          under the Platform Terms and other written agreement with the User;
          (iii) to provide, monitor, correct, and improve the Platform and
          Services related thereto; (iv) for the Oppam Platform and
          Oppam’s (and its successors’, subsidiaries and affiliates’)
          business, including without limitation for promoting and redistributing
          part or all of the Oppam Platform in any media formats and through
          any media channels; (v) to de-identify User data such that there is no
          reasonable basis to believe that the information can be used, alone, or
          in combination with other reasonably available information, to identify
          any individual or to identify User as the source of such data; (vi) to
          aggregate User data with other data; and (vii) to comply with applicable
          laws. However, please note that we will never disclose aggregated or
          de-identified information in a manner that could identify you as an
          individual, and all your information shall be processed in accordance
          with out Privacy Policy.
        </p>
        <br>
        <p>
          You also hereby grant each User of the Platform a limited, non-exclusive
          license to access your User-generated Content through the Platform.
        </p>
      </li>
      <br>
      <li>
        <p>
          <b>Oppam Content:</b> Oppam and / or its affiliates own all
          information and materials (in whatever form or media) provided or
          communicated to you by or on behalf of Oppam including but not
          limited to, the Platform, illustrations, letters, images, ideas,
          concepts, the layout, design, flow, look and feel of the Platform,
          logos, marks, graphics, audio files, video files, any software which is
          owned by or licensed to Oppam and / or its affiliates, the
          underlying source and object code, instructions embedded in any form of
          digital documents and other data, information, or material made
          available to you by Oppam <b>(“Oppam Content”)</b>. Oppam
          Content specifically excludes any content uploaded by the Users,
          including without limitation, any User-generated Content on the
          Platform. Oppam Content, including its trademarks, will not be used,
          modified, or altered by you in any way. You acknowledge and agree that
          you do not acquire any ownership or rights to the Oppam Content or
          the Platform by use of the Platform. You acknowledge and agree that the
          Oppam Content is protected by the copyright, trademark, patent,
          trade secret and other intellectual property or proprietary rights laws
          and any unauthorize use, reproduction, modification, distribution,
          transmission, republication, display or performance of the Oppam
          Content and any component thereof is strictly prohibited.
        </p>
        <br>
        <p>
          You confirm and undertake not to utilize any data mining tools, robots,
          or similar data gathering and extraction tools to extract for
          re-utilization of any substantial parts of this Platform, without
          Oppam’s express prior written consent.
        </p>
      </li>
    </ol>
    <br>
    <br>
    <a name="feedback">
      <h3>K. Feedback</h3>
    </a>
    <p>
      If any User(s) submits suggestions, ideas, comments, or questions containing
      product feedback about the Platform or any of the Services, either through
      the Platform or otherwise <b>(“Feedback”)</b>, then such User(s) grants
      Oppam and its affiliates a worldwide, non-exclusive, royalty-free,
      perpetual, and irrevocable right to use (and full right to sublicense),
      reproduce, modify, adapt, publish, translate, create derivative works from,
      distribute, transmit, and display such Feedback in any form. Users shall
      have no intellectual property right in the Platform as a result of
      Oppam’s incorporation of their Feedback in the Platform.
    </p>
    <br>
    <br>
    <a name="copyrights">
      <h3>L. Copyright, Trademarks, and other Intellectual Property Rights</h3>
    </a>
    <p>
      At Oppam, we respect the intellectual property of others just as much
      ours and, hence, if you believe that your intellectual property rights have
      been used in a way that gives rise to concerns of infringement of your
      intellectual property (including your copyrights and trademarks), then
      kindly write to us at
      <a href="mailto:info@oppam.in"> info@oppam.in</a> with complete
      details as required under <a href="/takedown-policy"> Takedown Policy</a>.
      If Oppam has knowledge of or has any reason to believe that any
      User-generated Content on the Platform violates the intellectual property
      rights of Oppam or other Users, then we reserve the right to remove
      access to such content in accordance with takedown practices specified in
      <a href="/takedown-policy"> Takedown Policy</a>. <br>
    </p>
    <br>
    <p>
      If Oppam has knowledge of or has any reason to believe that any
      User-generated Content on the Platform violates the intellectual property
      rights of Oppam or other Users, then we reserve the right to remove
      access to such content in accordance with takedown practices specified in
      <a href="/takedown-policy"> Takedown Policy</a>.
    </p>
    <br>
    <br>
    <a name="claims_against_user_content">
      <h3>M. Claims Against User-generated Content</h3>
    </a>
    <p>
      Oppam does not monitor or have any control over or does not warrant, and
      makes no claim or representation regarding the accuracy, completeness, or
      usefulness of any User-generated Content provided on the Platform by its
      Users and accepts no responsibility for reviewing changes or updates to, or
      the quality, content, policies, nature or reliability of, such
      User-generated Content. Oppam disclaims all liability and responsibility
      arising from any reliance placed on such materials by you. All statements
      and/or opinions expressed in these materials, and all articles and responses
      to questions and other content, other than the Oppam Content, are solely
      the responsibility of the User providing those materials.
    </p>
    <br>
    <br>
    <a name="Oppam_rights">
      <h3>N. Oppam’s Rights</h3>
    </a>
    <p>
      In respect of the entire Platform, Oppam reserves the following rights:
    </p>
    <p>
      <br>
    </p>
    <ol type="1">
      <li>
        <p>
          Oppam reserves the right to put on-hold or reject or suspend or
          terminate your registration on the Platform for the purpose of complying
          with the legal and regulatory requirements.
        </p>
      </li>
      <li>
        <p>
          Oppam reserves the right to remove you and/or the User-generated
          Content without notice if you violate any provisions of the Platform
          Terms.
        </p>
      </li>
      <li>
        <p>
          Oppam may modify, terminate, or refuse to provide Services at any
          time for any reason, without notice.
        </p>
      </li>
      <li>
        <p>
          Notwithstanding anything contrary stated in the Platform Terms, in its
          sole discretion, Oppam may remove anyone from the Platform at any
          time for any reason.
        </p>
      </li>
      <li>
        <p>
          Oppam reserves the right to access your account and/or the
          User-generated Content in order to respond to requests for technical
          support, to maintain the safety and security of the Platform, legal
          purposes and for other legitimate business purposes, as necessary, in
          Oppam’s discretion.
        </p>
      </li>
      <li>
        <p>
          Oppam has no obligation to monitor any content that appears on the
          Platform or review any conduct occurring through the Platform, including
          any interactions between Users, however, if Oppam receives notice or
          becomes aware of, any violation of the Platform Terms, then, Oppam
          reserves the right to refuse your access to the Platform, terminate
          accounts or remove such violating content at any time without notice to
          you.
        </p>
      </li>
    </ol>
    <br>
    <br>
    <a name="platform_availability">
      <h3>O. Platform Availability</h3>
    </a>
    <p>
      Your access to the Platform may occasionally be suspended or restricted to
      allow for repairs, maintenance, or due to the introduction of new facilities
      or services at any time without prior notice. We will attempt to limit the
      frequency and duration of any such suspension or restriction. You agree that
      Oppam will not be liable for any losses that may be incurred by you if
      for any reason all or part of the Platform is unavailable at any time or for
      any period for use.
    </p>
    <br>
    <br>
    <a name="deletion_of_account">
      <h3>P. Deletion of Account</h3>
    </a>
    <p>
      As a Learner, you may delete your account at any time by either writing to
      us at the email address provided under ‘Contact for User Support/Queries’
      section below or by using the delete option provided within your account on
      the Platform (if available). If your account is deleted (regardless of the
      reason), you will no longer has access to your account on the Platform and
      your User-generated Content may no longer be available; any deletion once
      processed is irrecoverable. Oppam is not responsible for the loss of
      your information and/or User-generated Content upon deletion and Oppam
      shall not be liable to any party in any way for the inability to access
      User-generated Content arising from any deletion. Please note that all
      accounts will remain active unless you explicitly ask us to delete it, and
      applicable Subscription payments shall continue to be deducted until
      cancelled by you or until your account is deleted on your request (as stated
      in the <b>‘Pricing and Payments’</b> section above) or otherwise terminated
      in accordance with the Platform Terms
    </p>
    <br>
    <p>
      Please note that we may not be able to delete all communications or photos,
      files, or other documents publicly made available by you on the Platform,
      however, we shall anonymize your Personal Information (as defined in the
      Privacy Policy) in such a way that you can no longer be identified as an
      individual in association with such information made available by you on the
      Platform. We will never disclose aggregated or de-identified information in
      a manner that could identify you as an individual.
    </p>
    <br>
    <p>
      If you are a Content Provider, your access and use of the Platform may be
      subject to a separate agreement with Oppam and shall be collectively
      governed by the terms of such agreement, the Terms and Conditions and other
      Platform Terms.
    </p>
    <br>
    <br>
    <a name="disclaimer">
      <h3>Q. Disclaimer:</h3>
    </a>
    <p>
      YOU AGREE THAT THE PLATFORM, CONTENT, AND ALL MATERIALS ARE PROVIDED ON AN
      “AS IS” AND “AS AVAILABLE” BASIS AND YOUR USE OF THE PLATFORM SHALL BE AT
      YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, Oppam, ITS
      OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM AND EXCLUDE ALL
      WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND YOUR USE
      THEREOF. TO THE FULLEST EXTENT PERMITTED BY LAW, Oppam EXCLUDES ALL
      WARRANTIES, CONDITIONS, TERMS OR REPRESENTATIONS ABOUT THE ACCURACY,
      SECURITY, RELIABILITY, QUALITY, AVAILABILITY OR COMPLETENESS OF THE
      PLATFORM, USER-GENERATED CONTENT ON THE PLATFORM, OR THE CONTENT OF ANY
      SITES SO LINKED AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
      ERRORS, MISTAKES, OR INACCURACIES OF USER-GENERATED CONTENT OR ANY CONTENT
      ON THE PLATFORM, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
      WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (III) ANY
      UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
      PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY
      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (IV) ANY
      BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
      THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS
      IN ANY USER-GENERATED CONTENT OR ANY OTHER CONTENT OR FOR ANY LOSS OR DAMAGE
      OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY USER- GENERATED CONTENT
      OR ANY OTHER CONTENT THAT IS POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
      AVAILABLE VIA THE PLATFORM AS Oppam IS STRICTLY AN INTERMEDIARY AS UNDER
      THE INFORMATION TECHNOLOGY ACT, 2000. Oppam DOES NOT WARRANT, ENDORSE,
      GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY USER- GENERATED CONTENT OR THE
      PLATFORM ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR ANY
      HYPERLINKED SERVICES OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
      Oppam WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
      ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF USER-GENERATED
      CONTENT OR SERVICES. AS WITH THE PURCHASE OF A SERVICE THROUGH ANY MEDIUM OR
      IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
      WHERE APPROPRIATE.
    </p>
    <br>
    <br>
    <a name="limitation_of_liability">
      <h3>R. Limitation of Liability</h3>
    </a>
    <p>
      TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL Oppam, ITS
      AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE
      LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
      LOSSES OR EXPENSES OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY
      (I) ERRORS, MISTAKES, OR INACCURACIES OF USER-GENERATED CONTENT OR ANY OTHER
      CONTENT AVAILABLE AT Oppam, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
      ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR
      Oppam PLATFORMS, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
      SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
      STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
      FROM THE Oppam PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
      LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR Oppam PLATFORMS BY ANY
      THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY USER-GENERATED
      CONTENT OR ANY OTHER CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
      AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
      OTHERWISE MADE AVAILABLE VIA THE Oppam PLATFORM, WHETHER BASED ON
      WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
      Oppam IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <br>
    <p>
      WE UNDERSTAND THAT, IN SOME JURISDICTIONS, WARRANTIES, DISCLAIMERS AND
      CONDITIONS MAY APPLY THAT CANNOT BE LEGALLY EXCLUDED, IF THAT IS TRUE IN
      YOUR JURISDICTION, THEN TO THE EXTENT PERMITTED BY LAW, Oppam AND ITS
      AFFILIATES LIMIT THEIR LIABILITY FOR ANY CLAIMS UNDER THOSE WARRANTIES OR
      CONDITIONS TO SUPPLYING YOU THE Oppam PLATFORM AGAIN.
    </p>
    <br>
    <p>
      YOU SPECIFICALLY ACKNOWLEDGE THAT Oppam SHALL NOT BE LIABLE FOR USER-
      GENERATED CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
      THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
      ENTIRELY WITH YOU.
    </p>
    <br>
    <p>
      THE Oppam PLATFORM IS CONTROLLED AND OFFERED BY Oppam AND / OR ITS
      AFFILIATES DEPENDING UPON YOUR JURISDICTION. Oppam MAKES NO
      REPRESENTATIONS THAT THE Oppam PLATFORM IS APPROPRIATE OR AVAILABLE FOR
      USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE Oppam PLATFORM FROM
      OTHER JURISDICTIONS DO SO AT THEIR OWN VOLITION AND ARE RESPONSIBLE FOR
      COMPLIANCE WITH LOCAL LAW.
    </p>
    <br>
    <br>
    <a name="indemnity_and_release">
      <h3>S. Indemnity and Release</h3>
    </a>
    <p>
      To the extent permitted by applicable law, you agree to defend, indemnify
      and hold harmless Oppam, its affiliates, their respective officers,
      directors, employees and agents, from and against any and all claims,
      damages, obligations, losses, liabilities, costs or debt, and expenses
      (including but not limited to attorney’s fees) arising from: (i) your use of
      and access to the Oppam Platform; (ii) your violation of any term of the
      Platform Terms; (iii) your violation of any third party right, including
      without limitation any copyright, property, or privacy right; (iv) any claim
      that your User-generated Content caused damage to a third party; or (v)
      violation of any applicable laws. This defense and indemnification
      obligation will survive these Terms and Conditions and your use of the
      Oppam Platform.
    </p>
    <br>
    <p>
      You hereby expressly release Oppam, its affiliates and any of their
      respective officers, directors, employees and agents from any cost, damage,
      liability or other consequence of any of the actions/inactions of any
      third-party vendors or service providers and specifically waive any claims
      or demands that you may have in this behalf against any of Oppam, its
      affiliates and any of their respective officers, directors, employees and
      agents under any statute, contract or otherwise.
    </p>
    <br>

    <a name="governing_laws">
      <h3>V. Applicable Law and Jurisdiction</h3>
    </a>
    <p>
      The applicable law and jurisdiction is dependent upon which entity forming
      part of the Oppam group is offering you the Platform and the Services,
      which in turn is dependent upon the Subscription chosen by you.
      Illustratively, Services provisioned in respect of Subscriptions pertaining
      to India, Oppam India shall be the entity rendering the Services and,
      accordingly, the Agreement shall be governed by and construed in accordance
      with the laws of India; and you agree, as we do, to submit to the exclusive
      jurisdiction of the courts at Kannur, Kerala, India. Similarly,
      Services in respect of Subscriptions pertaining to US shall be rendered by
      Oppam Inc. and, accordingly, in such cases, the Agreement shall be
      governed by and construed in accordance with the laws of State of Delaware,
      U.S.A. and you agree, as we do, to submit to the exclusive jurisdiction of
      the courts at State of Delaware, U.S.A.
    </p>
    <br>
    <br>
    <a name="general_provisions">
      <h3>W. General Provisions:</h3>
    </a>
    <ol type="1">
      <li>
        <p><b>Legal Notices:</b> In the event of any
          other disputes or claims arising from the use of the Oppam Platform, please get in touch with us at<a href="mailto:info@oppam.in"> info@oppam.in</a></p>
      </li>
      <li>
        <p><b> Modification, Amendment or
            Termination:</b> Oppam may, in its sole discretion, modify or revise the Agreement and policies at any
          time, and you agree to be bound by such modifications or revisions. Your continued use of the Platform post any
          modification of the Agreement shall be taken as your consent and acceptance to such modifications. Nothing in
          the Agreement shall be deemed to confer any third-party rights or benefits. You are advised to check our
          Platform frequently to see recent changes and to keep yourself updated with the most recent updates.</p>
      </li>
      <li>
        <p><b>Force Majeure:</b> Oppam shall not be
          liable for failure to perform, or the delay in performance of, any of its obligations if, and to the extent
          that, such failure or delay is caused by events substantially beyond its control, including but not limited to
          acts of God, acts of the public enemy or governmental body in its sovereign or contractual capacity, war,
          terrorism, floods, fire, strikes, epidemics, civil unrest or riots, power outage, and/or unusually severe
          weather.</p>
      </li>
      <li>
        <p><b>Miscellaneous: </b>If any part of the
          Agreement is found to be unlawful, void or unenforceable, that part of the Agreement will be deemed severable
          and will not affect the validity and enforceability of any remaining provisions. Any notice required to be given
          in connection with the Platform shall be in writing and sent to the registered office of Oppam. We do not
          guarantee continuous, uninterrupted or secure access to the Platform, and operation of the Platform may be
          interfered by numerous factors outside our control. Headings are for reference purpose only an on no way define,
          limit, construe or describe the scope or extent of such section. Our failure to act with respect to any breach
          by you or others does not waive our right to act with respect to subsequent or similar breaches.</p>
      </li>
    </ol>
    <br>
    <br>
    <a name="user_support">
      <h3>X. Contact for User Support/Queries
      </h3>
    </a>
    <p>For queries relating to Services offered by
      Oppam, please write to us at<a href="mailto:info@oppam.in">
        info@oppam.in</a>.</p>
    <br>
    <br>
    <a name="consumer_grievance">
      <h3>Y. Consumer Grievance</h3>
    </a>
    <h6>Nodal Officer Details – </h6>
  
    <p>Name: Anikumar CV</p>
    <p>Contact:<a href="mailto:info@oppam.in">info@oppam.in</a></p>
    <br>
    <h6>Grievance Officer Details – </h6>
  
    
    <br>
    <br><a name="contact_us">
      <h3>Z. Contact Us</h3>
    </a>
    <p>If you have concerns or queries regarding the
      Platform Terms, you may write to us by email at<a href="mailto:info@oppam.in">info@oppam.in</a> or by post to:</p>
    <br>
    <p><b>Oppam Pvt Ltd</b></p>
    <p>Akshaya E Centre, 3rd Floor,</p>
    <p>A-Block, Andamkovval, Kunhimangalam,</p>
    <p>Kannur - 670309, Kerala, India.</p>
    <br>
    <br><a name="refund_policy">
      <h3>Annexure A</h3>
    </a>
    <ol type="1">
      <li>All Services offered on the Platform other than those offered under the ‘International Goals’ section on the
        Platform are provisioned by <b>Oppam Pvt. Ltd. (Oppam India), Akshaya E Centre, 3rd
          Floor, A-Block, Andamkovval, Kunhimangalam, Kannur- 670 309.</b></li>
    </ol>
  </div>