<br><br><br><br><br>
<div class="pt-marg-30">
    <div fxLayout="row" fxLayout.xs="row" style="justify-content: space-between">
        <div fxFlex="15" fxFlex.xs="50" class="text-center"><a (click)="dispProgressReport()">
                <svg style="cursor: hand;" fill="#000000" width="35px" height="35px" viewBox="0 0 1000 1000" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    enable-background="new 0 0 1000 1000" xml:space="preserve">
                    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                    <g>
        
                        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
        
                            <path
                                d="M1685.8,4989.2c-253.7-58.9-499.3-265.9-621.1-519.6l-75.1-158.3l-6.1-4141c-4.1-2772.8,2-4173.4,16.2-4238.4c56.8-267.9,257.8-513.6,523.7-639.4l156.3-75.1l3258-6.1c2174-4.1,3290.4,2,3355.4,16.2c326.8,69,627.2,365.4,702.4,692.2c14.2,58.9,22.3,1049.5,22.3,2809.4c0,2616.5-2,2724.1-38.6,2801.2c-48.7,101.5-3373.6,3428.5-3458.9,3460.9C5449.2,5017.6,1797.5,5015.6,1685.8,4989.2z M4856.5,3087.2c0-1433.1,2-1451.4,115.7-1648.3c89.3-152.2,253.7-298.4,426.3-381.6l158.3-75.1l1427-6.1l1425-6.1v-2494.7c0-1891.9-6.1-2506.9-24.4-2547.5c-52.8-117.7,148.2-111.6-3385.8-111.6s-3333.1-6.1-3385.8,111.6c-38.6,81.2-34.5,8302.2,4.1,8371.2c58.9,105.6,12.2,101.5,1682.8,103.5h1556.9V3087.2z M6866.1,1585.1c-1351.9-4.1-1337.7-6.1-1380.3,109.6c-12.2,30.5-20.3,552.1-20.3,1293V4230l1319.4-1319.4l1319.4-1319.4L6866.1,1585.1z" />
        
                            <path d="M2989-327.1v-304.5h2009.6h2009.6v304.5v304.5H4998.6H2989V-327.1z" />
        
                            <path d="M2989-1646.5V-1951h2009.6h2009.6v304.5v304.5H4998.6H2989V-1646.5z" />
        
                            <path d="M2989-2925.3v-304.5h954.1h954v304.5v304.5h-954H2989V-2925.3z" />
        
                        </g>
        
                    </g>
                </svg>
                <br> Progress Report</a>
        </div>
          <!-- <div fxFlex="40" fxFlex.xs="0"></div> -->
        
        <div fxFlex="15" fxFlex.xs="50" class="text-center">
            <a (click)="dispWalletRecharge()">
                <svg style="cursor: hand;" width="40%" height="40%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                        <path d="M14.2617 15.4385H9.26172" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                            stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.7617 12.998V17.998" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <path
                        d="M12.6609 2.51814L12.6309 2.58814L9.73094 9.31814H6.88094C6.20094 9.31814 5.55094 9.45814 4.96094 9.70814L6.71094 5.52814L6.75094 5.42814L6.82094 5.26814C6.84094 5.20814 6.86094 5.14814 6.89094 5.09814C8.20094 2.06814 9.68094 1.37814 12.6609 2.51814Z"
                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M18.0505 9.51807C17.6005 9.37807 17.1205 9.31807 16.6405 9.31807H9.73047L12.6305 2.58807L12.6605 2.51807C12.8105 2.56807 12.9505 2.63807 13.1005 2.69807L15.3105 3.62807C16.5405 4.13807 17.4005 4.66807 17.9205 5.30807C18.0205 5.42807 18.1005 5.53807 18.1705 5.66807C18.2605 5.80807 18.3305 5.94807 18.3705 6.09807C18.4105 6.18807 18.4405 6.27807 18.4605 6.35807C18.7305 7.19807 18.5705 8.22807 18.0505 9.51807Z"
                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z"
                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path opacity="0.4"
                        d="M6.71 5.52832L4.96 9.70832C3.22 10.4583 2 12.1883 2 14.1983V11.2683C2 8.42832 4.02 6.05832 6.71 5.52832Z"
                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path opacity="0.4"
                        d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z"
                        stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <br> Wallet Recharge</a>
        </div>

        <div fxFlex="15" fxFlex.xs="100" class="text-center">
            <span><a (click)="dispWallet()">
                
                        <svg width="40%" height="40%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4">
                                <path
                                    d="M10.7496 16.8599V18.8899C10.7496 20.6099 9.14963 21.9999 7.17963 21.9999C5.20963 21.9999 3.59961 20.6099 3.59961 18.8899V16.8599C3.59961 18.5799 5.19963 19.7999 7.17963 19.7999C9.14963 19.7999 10.7496 18.5699 10.7496 16.8599Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M10.7498 14.1097C10.7498 14.6097 10.6098 15.0697 10.3698 15.4697C9.77981 16.4397 8.5698 17.0497 7.1698 17.0497C5.7698 17.0497 4.55979 16.4297 3.96979 15.4697C3.72979 15.0697 3.58984 14.6097 3.58984 14.1097C3.58984 13.2497 3.98982 12.4797 4.62982 11.9197C5.27982 11.3497 6.16979 11.0098 7.15979 11.0098C8.14979 11.0098 9.03982 11.3597 9.68982 11.9197C10.3498 12.4697 10.7498 13.2497 10.7498 14.1097Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M10.7496 14.11V16.86C10.7496 18.58 9.14963 19.8 7.17963 19.8C5.20963 19.8 3.59961 18.57 3.59961 16.86V14.11C3.59961 12.39 5.19963 11 7.17963 11C8.16963 11 9.05966 11.35 9.70966 11.91C10.3497 12.47 10.7496 13.25 10.7496 14.11Z"
                                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <path
                                d="M22.0002 10.9702V13.0302C22.0002 13.5802 21.5602 14.0302 21.0002 14.0502H19.0402C17.9602 14.0502 16.9702 13.2602 16.8802 12.1802C16.8202 11.5502 17.0602 10.9602 17.4802 10.5502C17.8502 10.1702 18.3602 9.9502 18.9202 9.9502H21.0002C21.5602 9.9702 22.0002 10.4202 22.0002 10.9702Z"
                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M2 10.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H13.5"
                                stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                
                   <br> My Wallet ₹.{{UserWalletAmount[0].wallet_amount?(UserWalletAmount[0].wallet_amount|number:'.2'):0}}
                </a></span>
        </div>
            <div fxFlex="15" fxFlex.xs="40" class="text-center">
                <span (click)="dispProfile()">
                    <svg style="cursor: hand;" width="40%" height="40%" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                            stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                            stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg><br>Profile View/Edit</span>
            </div>
    </div>

<!-- Booklet Spot Exams notifications start-->
    <div fxLayout="row" fxlayout.xs="row" *ngIf="SpotExamsLength>0" fxLayoutAlign="center center">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>   
                            <th class="tr-bgcolor">Today's Aptitude Exam</th>
                            <th class="tr-bgcolor" style="width: 60px; text-align: center;">Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let exam of SpotExams">
                            <td>{{exam.BookletName}}</td> 
                            <td><button mat-flat-button class="btnbluedark" (click)="spotExam(exam.BookletNo)" title="Start Exam now">Start Now</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
 <!-- Booklet Spot Exams notifications end-->  

 <!-- Offline Exam Eligibility Message start -->

    <!-- <div fxLayout="row" fxlayout.xs="row" class="text-center" fxFlex="50" *ngIf="usertype==1" style="padding-bottom: 10px; padding-top: 10px;"> -->
    <!--  <div fxLayout="row" fxlayout.xs="row" fxLayoutAlign="center center" *ngIf="usertype==1 && ResultAllLength>0"> -->
              

    <!-- </div> -->
<!--
 <div fxLayout="row" fxlayout.xs="row" fxLayoutAlign="center stretch" style="padding-top: 10px;">
    <h5>{{exam_message}}</h5>
    <div style="border: 1px solid #dee2e6;">
        <table class="table table-borderless">
            <tr>
                <th class="tr-bgcolor text-center" colspan="4">Aptitude Exam Result</th>
            </tr>
            <tr>
                <td>Attempt : {{attempt}}</td>
                <td>Total : {{total}}</td>
                <td>Score : {{score}}</td>
                <td>Percentage : {{grade}}%</td>
            </tr>
        </table>
        
         <table class="table table-bordered">
            <tr class="tr-bgcolor">
                <td>Date </td>
                <td>Exam </td>
                <td>Mark</td>
                <td>Score</td>
            </tr>
            <tr *ngFor="let subject of ResultAll">
                <td >{{subject.examdate | date: 'dd/MM/yyyy'}}</td>
                <td >{{subject.examname}}</td>
                <td >{{subject.totalmark}}</td>
                <td >{{subject.totalscore}}</td>
            </tr>
        </table>
        </div>
 </div> -->
 <!-- Offline Exam Eligibility Message end -->

    <div fxLayout="row" fxLayout.xs="row">
    <div fxFlex="40" fxFlex.xs="40" class="toppadding">Subscribed({{subscribed_len}})</div>
    <div fxFlex="40" fxFlex.xs="40" class="toppadding"><button mat-flat-button class="btnbluedark"
            (click)="dispSubScribeNow()">Subscribe Now</button></div>
    <div fxFlex="40" fxFlex.xs="40" class="toppadding"><button mat-flat-button class="btnbluedark"
        (click)="dispFeedBack()">FeedBack</button></div>
    </div>

    <hr>
    <div style="text-align:right;"><button mat-flat-button class="btnbluedark"
        (click)="dispOtherPackages()">View Other Exams</button></div>
    <!--<div *ngIf="subscribed_len==0">
        No Active Package Found.!!<br>Please click on 'Subscribe Now' to get the qualified packages.<br>You can see your
        eligible packages below. You can do a trial for these examinations<br>Make sure you have sufficient amount in
        your wallet to subscribe for new packages.!!
    </div> -->
    <div class="table-responsive">
        <table style="width: 100%;" class="table table-hover">
            <thead>
                <tr *ngIf="subscribed_len>0">
                    <th class="tr-bgcolor">Exam Name</th>
                    <th class="tr-bgcolor" style="text-align:center;font-size: small;">Study</th>
                    <th class="tr-bgcolor" style="text-align:center;font-size: small;">Practice</th>
                    <th class="tr-bgcolor" style="text-align:center;font-size: small;">Exam</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pkg of subscribedpackages">
                    <td style="font-size: smaller;">{{pkg.qpname}}</td>
                    <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(pkg.qpid,pkg.examid,1)">
                        <mat-icon style="color:blue">local_library</mat-icon><br>Study
                    </td>
                    <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(pkg.qpid,0,2)">
                        <mat-icon style="color:green">task_alt</mat-icon><br>Practice
                    </td>
                    <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(pkg.qpid,0,3)">
                        <mat-icon style="color:red">pending_actions</mat-icon><br>Exam
                       
                    </td>
                </tr>
                <!-- <tr *ngIf="eligible_len>0">
                    <td colspan="3" ><h4>Eligible Trial Exams</h4>
                    </td>
                </tr> -->
                <tr *ngIf="eligible_len>0">
                    <th class="tr-bgcolor">Exam Name</th>
                    <th class="tr-bgcolor" style="text-align:center;font-size: small;">Study</th>
                    <th class="tr-bgcolor" style="text-align:center;font-size: small;">Practice</th>
                    <th class="tr-bgcolor" style="text-align:center;font-size: small;">Exam</th>
                </tr>
                <ng-container *ngFor="let pkg of eligiblepackages">
                    <tr *ngIf="eligible_len>0">
                        <td style="font-size: smaller;">{{pkg.qpname}}</td>

                        <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(pkg.qpid,pkg.examid,1)">
                            <mat-icon style="color:blue">local_library</mat-icon><br>Study
                        </td>
                        <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(pkg.qpid,0,2)">
                            <mat-icon style="color:green">task_alt</mat-icon><br>Practice
                        </td>
                        <td style="text-align:center; cursor:pointer;font-size: small;" (click)="showPage(pkg.qpid,0,3)">
                            <mat-icon style="color:red">pending_actions</mat-icon><br>Exam
                        </td>
                        </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="table-responsive" *ngIf="ResultAllLength>0">
        <div *ngIf="attempt==5">{{exam_message}}</div>
        <table style="width: 100%;" class="table table-hover">
        <tr>
            <th class="tr-bgcolor" colspan="5" style="text-align: center;">Aptitude Test Result</th>
        </tr>
        <tr>
            <td>Attempt : {{attempt}}</td>
            <td>Total : {{total}}</td>
            <td>Attended : {{attended}}</td>
            <td>Score : {{score}}</td>
            <td>(%) : {{grade=='NaN'?0:grade}}%</td>
        </tr>
        </table>
        
     <table class="table table-bordered">
        <tr class="tr-bgcolor">
            <td>Date </td>
            <td>Exam</td>
            <td>Total</td>
            <td>Attended</td>
            <td>Score</td>
        </tr>
        <tr *ngFor="let subject of ResultAll">
            <td >{{subject.examdate | date: 'dd/MM/yy'}}</td>
            <td >{{subject.examname}}</td>
            <td >{{subject.totalmark}}</td>
            <td >{{subject.questions_attended}}</td>
            <td >{{subject.totalscore}}</td>
        </tr>
        </table> 
     </div>

</div>