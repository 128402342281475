<br><br><br><br>
<div class="pt-marg-30">
  <div class="signin" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">

    <div fxFlex="50" class="animate__animated animate__fadeIn animate__slower">
      <h3>User Login</h3>
      <form [formGroup]="myForm" fxLayout="column" fxLayout.xs="column">
        <mat-form-field appearance="outline" fxFlex="90">
          <mat-label>Login ID</mat-label>
          <input matInput type="text" maxlength="10" minlength="3" placeholder="Enter Mobile Number" formControlName="unm">
          <mat-icon matSuffix>phone_iphone</mat-icon>
          <mat-error *ngIf="myForm.controls['unm'].invalid &&
                        (myForm.controls['unm'].dirty || myForm.controls['unm'].touched)">
            Login ID (Mobile) required.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="90">
          <mat-label>PassWord</mat-label>
          <input matInput [type]="showPassword ? 'text' : 'password'"  formControlName="pwd" minlength="3" maxlength="15">
          <mat-icon matSuffix (click)="togglePasswordVisibility()">
            {{showPassword?'visibility_off':'visibility'}}
        </mat-icon>
          <mat-error *ngIf="myForm.controls['pwd'].invalid &&
                        (myForm.controls['pwd'].dirty || myForm.controls['pwd'].touched)">
            PassWord required.
          </mat-error>
        </mat-form-field>
        <div fxFlex="90">forgot password ? call 9847500773</div>
        <div fxFlex="90">
          <button mat-flat-button color="primary" (click)="validateLogin()" class="btnmargin">Login</button><button mat-flat-button color="primary" (click)="validateUser()" class="btnmargin">Register</button>
        </div>

      </form>
    </div>
  </div>
</div>