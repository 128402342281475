

    <div class="about" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex="90"  class="animate__animated animate__fadeIn animate__slower"> 
            <h3>Progress Report</h3>
            <form [formGroup]="myForm" fxLayout="column"> 
                <mat-form-field appearance="outline" fxFlex="90"> 
                    <mat-label>Start Date</mat-label> 
                    <input matInput type="date" placeholder="Start Date" formControlName="startdate" >
                    <mat-error *ngIf="myForm.controls['startdate'].invalid && 
                         (myForm.controls['startdate'].dirty || myForm.controls['startdate'].touched)">
                         Start Date required.
                     </mat-error>
                 </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>End Date</mat-label> 
                     <input matInput type="date" placeholder="Start Date" formControlName="enddate"> 
                     <mat-error *ngIf="myForm.controls['enddate'].invalid && 
                         (myForm.controls['enddate'].dirty || myForm.controls['enddate'].touched)"> 
                         End Date required.
                     </mat-error>
                 </mat-form-field> 
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Report Type</mat-label>
                    <mat-select formControlName="reporttype">
                        <mat-option [value]="'Exam Wise'">Exam Wise</mat-option> 
                        <mat-option [value]="'Subject Wise'">Subject Wise</mat-option>   
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['reporttype'].invalid &&
                        (myForm.controls['reporttype'].dirty || myForm.controls['reporttype'].touched)">
                        Report Type required.
                    </mat-error>
                </mat-form-field>
                <div *ngIf="myForm.controls['reporttype'].value =='Exam Wise' && eligible_len>0" class="form-group form-inline">
              
                    <mat-form-field appearance="outline" fxFlex="90">
                        <mat-label>Examination</mat-label>
                        <mat-select formControlName="qpid">
                            <mat-option *ngFor="let opt of eligibleqpids" [value]="opt.qpid">
                                {{opt.qpname}}
                            </mat-option>   
                        </mat-select>
                        <mat-error *ngIf="myForm.controls['qpid'].invalid &&
                            (myForm.controls['qpid'].dirty || myForm.controls['qpid'].touched)">
                            Exam required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="myForm.controls['reporttype'].value!=='Exam Wise' && eligible_len>0" class="form-group form-inline">
                    <mat-form-field appearance="outline" fxFlex="90">
                        <mat-label>Subject</mat-label>
                        <mat-select formControlName="subjectid">
                            <mat-option *ngFor="let opt of Subjects" [value]="opt.id">
                                {{opt.name}}
                            </mat-option>   
                        </mat-select>
                        <mat-error *ngIf="myForm.controls['subjectid'].invalid &&
                            (myForm.controls['subjectid'].dirty || myForm.controls['subjectid'].touched)">
                            Subject required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="outline" fxFlex="90">
                    <mat-label>Practice / Exam</mat-label>
                    <mat-select formControlName="examtype">
                        <mat-option [value]="2">Practice</mat-option> 
                        <mat-option [value]="3">Examination</mat-option>   
                    </mat-select>
                    <mat-error *ngIf="myForm.controls['examtype'].invalid &&
                        (myForm.controls['examtype'].dirty || myForm.controls['examtype'].touched)">
                        Practice / Exam required.
                    </mat-error>
                </mat-form-field>
            </form>
           </div>
          
           <div style="width: 100%;" class="table-responsive" *ngIf="Report!='no' && ResultLength>0" style="padding-bottom: 10px; padding-top: 10px; padding-left: 10px; padding-right: 10px;">           
                 <div *ngFor="let exam of Result.table1">
                    <table style="width: 100%;" class="table table-hover">
                        <tr>
                            <th class="tr-bgcolor" colspan="4" style="text-align: center;">{{exam.name}}</th>
                        </tr>
                        <tr>
                            <td>Attempt {{exam.attempted}}</td>
                            <td>Questions {{exam.totalq}}</td>
                            <td>Answered {{exam.answered}}</td>
                            <td>{{(exam.answered/exam.totalq*100) | number : '1.0-0'}}%</td>
                        </tr>
                    </table>
                    
                     <table class="table table-bordered">
                        <tr class="tr-bgcolor">
                            <td>Subject</td>
                            <td>Questions</td>
                            <td>Attended</td>
                            <td>True</td>
                            <td>(%)</td>
                        </tr>
                        <tr *ngFor="let subject of Result.table2">
                            <td >{{subject.subcatname}}</td>
                            <td >{{subject.totalq}}</td>
                            <td >{{+(subject.falseanswered) + +(subject.answered)}}</td>
                            <td >{{subject.answered}}</td>
                            <td><p>{{(subject.performed) | number : '1.0-0'}}</p></td>
                        </tr>
                    </table>
                </div>    
            </div>
           
                <div fxFlex="90">
                    <button mat-flat-button color="primary" (click)="getReport()">Get Report</button>
                </div>
                
                
           
       
       <!--  <div fxFlex="90" class="chart-container">
            <canvas  id="MyChart" >{{ chart }}</canvas>
        </div>  -->

              
              
        <!-- <div fxFlex="50" class="chart-container" *ngIf="usertype==1 && ResultAllLength>0" style="padding-bottom: 10px; padding-top: 10px;"> -->
         <div style="width: 100%;" class="table-responsive" *ngIf="ResultAllLength>0" style="padding-bottom: 10px; padding-top: 10px; padding-left: 10px; padding-right: 10px;">
            <table style="width: 100%;" class="table table-hover">
            <tr>
                <th class="tr-bgcolor" colspan="5" style="text-align: center;">Aptitude Test Result</th>
            </tr>
            <tr>
                <td>Attempt : {{attempt}}</td>
                <td>Total : {{total}}</td>
                <td>Attended : {{attended}}</td>
                <td>Score : {{score}}</td>
                <td>(%) : {{grade=='NaN'?0:grade}}%</td>
            </tr>
        </table>
        
         <table class="table table-bordered">
            <tr class="tr-bgcolor">
                <td>Date </td>
                <td>Exam </td>
                <td>Mark</td>
                <td>Attended</td>
                <td>Score</td>
            </tr>
            <tr *ngFor="let subject of ResultAll">
                <td >{{subject.examdate | date: 'dd/MM/yy'}}</td>
                <td >{{subject.examname}}</td>
                <td >{{subject.totalmark}}</td>
                <td >{{subject.questions_attended}}</td>
                <td >{{subject.totalscore}}</td>
            </tr>
        </table>
        </div>
        <!-- </div> -->

        
        <div fxFlex="90" style="padding-top: 10px;">
            <button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>
        </div>
        
    </div>
    
                 
