import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approve-dialog',
  templateUrl: './approve-dialog.component.html',
  styleUrls: ['./approve-dialog.component.css']
})
export class ApproveDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
