<br><br><br><br><br>
<div>
    <div class="about animate_animated animatefadeIn animate_slower" fxLayout="row wrap" fxLayout.xs="column"
        fxLayoutAlign="center">
        <div fxFlex="80">
            <h2>Other Links</h2>
            
            <UL>
                <li><a href="https://thulasi.psc.kerala.gov.in/thulasi/">Kerla PSC registration</a></li>
                <li><a href="https://ssc.gov.in/login">SSC registration</a></li>
             </UL>  
            
            
          

           <br><br>
        </div>
      
</div>
</div>