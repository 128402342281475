import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { DialogService } from '../../../shard/service/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user_id:any;
  private_key:any;
  errorMessage:any;
  QualificationSubjects:any;
  subjectList:any;
  expenseAccountsList:any;
  EduLevels:any;
  tablename:any;
  State:any;
  District:any;
  Panchayath:any;
  Qualifications:any;
  ProfQualifications:any;
  jsondata:any;
  Profile:any;
  httpResponseMessage:any;
  filteredSubjects!: Observable<any[]>
  myForm: FormGroup = new FormGroup({});

  constructor(private route: ActivatedRoute,private router: Router, private location: Location,private dialogService: DialogService, private fb: FormBuilder, private _subscriptionService: SubscriptionService
    ) { }

    ngOnInit(): void {
      if(sessionStorage.getItem('user_id')==null) {
        this.router.navigate(['/home']); return;
      }
      this.user_id =  sessionStorage.getItem('user_id');
      this.private_key = sessionStorage.getItem('private_key');
      this.createForm();
      this.getProfile();
      this.getMaster('Education Level');
      this.getMaster('Prof Qualification');
      this.getMaster('State');
      //this.folkJoinData();

      this.filteredSubjects = this.myForm.controls['qualification_subject_id'].valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
         if (this.subjectList){ return name ? this._filterSubject(name as string) : this.subjectList.slice();}
        }),
      )
      console.log(this.filteredSubjects);
    }
 
    createForm(){
      this.myForm = this.fb.group({
        "user_id": this.user_id,
        "private_key":this.private_key,
        "customer_id": null,
        "user_name": null,
        "pass_word": null,
        "name": null,
        "dob": null,
        "gender": null,
        "mobile": null,
        "email": null,
        "education_level": 0,
        "qualification_id": 0,
        "qualification_subject_id": 0,
        "standard_id": 0,
        "prof_qualification_id": 0,
        "state_id": 0,
        "dist_id": 0,
        "panchayath_id": 0,
        "caste_cat": null,
        "student": 0,
        "job": 0,
        "subscription_type": 0,
        "end_date": null,
        "prof_qualification_name": "Nil"
        });
    };
    
    private _filterSubject(name: string): any[] {
      const filterValue = name.toLowerCase(); 
      return this.subjectList.filter((subject: any) => subject.name.toLowerCase().includes(filterValue));
    }
  
    getMaster(tbl:any): void { 
      this._subscriptionService.getData(AppSettings.API_ENDPOINT +  AppSettings.mastercombo + '?tblname='+ tbl+ '&user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
      data => {
        if (tbl=='Education Level') {
          this.EduLevels = data;
        }  
        if (tbl=='Prof Qualification') {
            this.ProfQualifications = data; 
        }  
        if (tbl=='State') {
          this.State = data; 
        }
      },
      error => this.errorMessage = <any>error);
    }
  
    getFilterMaster(tbl:any, id:any): void { 
      this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.filtercombo + '?tblname='+tbl+'&id=' + id + '&user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
      data => {
        if (tbl=='Qualification') {
          this.Qualifications = data; 
        }
        if (tbl=='Qualification Subject') {
          this.subjectList = data;   console.log(this.subjectList);
        }
        if (tbl=='District') {
          this.District = data;   console.log(this.District);
        }
        if (tbl=='Panchayath') {
          this.Panchayath = data;   console.log(this.Panchayath);
        }
     },
      error => this.errorMessage = <any>error);
    }
  
    displaySubjects(id: any): string {
      if (!id) {return '';}
      if (!this.subjectList) {return '';}
        let name='';
        this.subjectList?.forEach((e: any) => {
          if (e.id===id){name= e.name;}
        });
        return name;
    }

    goBack(): void{
      this.location.back();
    }
    
    getProfile(): void{
      this._subscriptionService.getData(AppSettings.API_ENDPOINT +  AppSettings.profile + '?user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
        data => {
          this.Profile = data; console.log(this.Profile);

          if(this.Profile.Message=='Unauthorised Access.!!'){
            this.router.navigate(['/loginform']); return;
          }

          this.myForm.patchValue(this.Profile[0]); console.log(this.myForm.value);
          this.getFilterMaster('District',this.Profile[0].state_id);
          //this.getFilterMaster('Panchayath',this.Profile[0].dist_id);
          },
        error => this.errorMessage = <any>error);
    }

    folkJoinData(){
      forkJoin({
        StateData: this._subscriptionService.getData(AppSettings.API_ENDPOINT +  AppSettings.mastercombo + '?tblname=State'+'&user_id='+this.user_id + '&private_key='+this.private_key),
        DistrictData: this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.filtercombo + '?tblname=District'+'&id='+this.Profile[0].state_id + '&user_id='+this.user_id + '&private_key='+this.private_key),
        PanchayathData: this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.filtercombo + '?tblname=Panchayath'+'&id='+this.Profile[0].dist_id + '&user_id='+this.user_id + '&private_key='+this.private_key),
      })
      .subscribe(({StateData, DistrictData,PanchayathData}) => {
        this.State=StateData;
        this.District=DistrictData;
        this.Panchayath=PanchayathData; 
        this.getProfile();
      });
    }

    successNotification(){
      const dataToComponent = {
        title: "Done.!!",
        message: "Successfully Saved.!!",
        alertType: "Success",
      }
      this.dialogService.openAlertDialog(dataToComponent);
    }
  
    errorNotification(){
      const dataToComponent = {
        title: "Error.!!",
        message: "Fill all required fields.!!",
        alertType: "Warning",
      }
      this.dialogService.openAlertDialog(dataToComponent);
    }
  
    updateData(): void {
       if (this.myForm.invalid) {
          this.errorNotification();
          return;
        }
        else {
        console.log(this.myForm); 
        this.jsondata = JSON.stringify(this.myForm.value); console.log(this.jsondata);
        this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.profileupdate, this.jsondata).subscribe(
          data => {
            this.httpResponseMessage = data
            console.log(this.httpResponseMessage);
            //this.myForm.reset();
            this.successNotification();
          },
          error => this.errorMessage = <any>error)
        } 
      } 

}
