import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DialogService } from '../../../shard/service/dialog.service'
@Component({
  selector: 'app-bookletexam',
  templateUrl: './bookletexam.component.html',
  styleUrls: ['./bookletexam.component.css']
})
export class BookletexamComponent implements OnInit {
  bookletno:any;
  user_id:any;
  private_key:any;
  Paper:any;
  qp_id:any;
  attemptid:any;
  Questions:any;
  QuestionsLength:any;
  isLoaded:any;
  errorMessage:any;
  true_answer:any;
  false_answer:any;
  AssessmentData:any;
  jsondata:any;
  httpResponse:any;
  message:any;
  promise:any;
  mymessage:any;
  attended:any;
  public timerInterval: any;
  countTimer:any;
  postData : Array<{user_id:0,booklet_no:0,mark_obtained:0,attended:0}> = [];
  constructor(private dialogService: DialogService, private route: ActivatedRoute,private router: Router, private location: Location,private _subscriptionService: SubscriptionService) {
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.mymessage='';
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key'); 
    this.bookletno = this.route.snapshot.paramMap.get('bookletno'); 
    this.countTimer=0;
    this.attended=0;
    this.getBookletDetails();
    this.loadQuestions(); 
    this.true_answer=0;
    this.false_answer=0;
    this.Paper = [{bookletname:"",countTimer:10000}];
  }

  //timer starts **********************************
  start() {
    this.timer(this.Paper[0].countTimer);
  }
  stop() {
    clearInterval(this.timerInterval);
  }

  timer(minute:number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.countTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log('finished');
        clearInterval(this.timerInterval);
        this.submitResult(); return;
      }
    }, 1000);
  }
  //timer ends   ********************************************

    goBack(): void {
      this.location.back();
    }
  
    reloadQuestions(): void{
      window.location.reload();
    }
  
    submitResult(): void{
      this.stop();
      this.attended=this.true_answer+this.false_answer;
        this.postData.push({user_id:this.user_id,booklet_no:this.bookletno,mark_obtained:this.true_answer,attended:this.attended});
        this.jsondata = JSON.stringify(this.postData);
        this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.bookletresultupdate + '?booklet_no='+this.bookletno + '&user_id=' + this.user_id + '&mark_obtained='+ this.true_answer + '&private_key=' + this.private_key + '&attended=' + this.attended).subscribe(
          data => {
              this.httpResponse = data; console.log(data);
              this.successNotification();
              this.router.navigate(['/dashboard']); 
              return;
          },
          error => this.errorMessage = <any>error); 
    }
  
    getBookletDetails(): void {
      this.Paper = []; 
      this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.bookletdetails + '?bookletno='+this.bookletno + '&user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
      data => {
        this.Paper = data; 
        
        if(this.Paper.Message=='Unauthorised Access.!!') {
          this.router.navigate(['/loginform']); 
          this.Paper[0].countTimer = 0;
          return;
        }
        else {
          console.log(this.Paper);
          this.Paper[0].countTimer = this.Paper[0].qpminutes;
          this.start();
        }    
      },
      error => this.errorMessage = <any>error);
    }
  
    successNotification(){
      const dataToComponent = {
        title: "Submitted.!!",
        message: "Result will be published on your Dashboard...",
        alertType: "Warning",
      }
      this.dialogService.openAlertDialog(dataToComponent);
    }
  
    loadQuestions():void {
      this.Questions = [];
      this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.bookletquestions + '?candidate_id='+this.user_id+'&center_id=0&booklet_no='+this.bookletno + '&user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
        data => {
          this.Questions = data;
          console.log(this.Questions);
          this.Questions.forEach((e: any) => {
            if (e.name){
              if (e.qname?.substring(e.qname.length - 4, e.qname.length) == '</p>') {
                e.qname = e.qname.replace("<p>", "").slice(0,-4); 
              }
            }
            if (e.ch1){
              if (e.ch1.substring(e.ch1.length - 4, e.ch1.length) == '</p>') {
                e.ch1 = e.ch1.replace("<p>", "").slice(0,-4); 
              }
            }
            if (e.ch2){
              if (e.ch2.substring(e.ch2.length - 4, e.ch2.length) == '</p>') {
                e.ch2 = e.ch2.replace("<p>", "").slice(0,-4); 
              }
            }
            if (e.ch3){
              if (e.ch3.substring(e.ch3.length - 4, e.ch3.length) == '</p>') {
                e.ch3 = e.ch3.replace("<p>", "").slice(0,-4); 
              }
            }
            if (e.ch4){
              if (e.ch4.substring(e.ch4.length - 4, e.ch4.length) == '</p>') {
                e.ch4 = e.ch4.replace("<p>", "").slice(0,-4); 
              }
            }
          }); 
  
          this.QuestionsLength=this.Questions.length;
          this.isLoaded=true;
        },
        error => this.errorMessage = <any>error);
    }
  
    getStatus(obj:any): boolean { 
      if(obj.stat==0) 
        return false
      else
        return true;
      };    
  
    getScore(question:any,index:any,$event:any): void { 
      question.stat = 1; console.log(question);
      let answer=0;
      question.stat = 1;
      if ($event.target.value==question.ans){ 
          answer=1;
          this.true_answer++; 
      }
      else
      {
        this.false_answer++;
      }
    } 

}
