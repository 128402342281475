import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThrowModuleAlreadyExist } from './utils/module-import-guard';
import { AuthModule } from './auth/auth.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule
  ],

})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule){
      ThrowModuleAlreadyExist(parentModule, 'CoreModule') 
    }
 }

//  core module is a singleton and heart of the app