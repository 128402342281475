import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})


export class SubscriptionService {
  httpOptions: any;
  headers: any;
  

  private _refreshNeeded$ = new Subject<void>();
  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    };
  }

  getData(url:string) {
    return this.http.get(url, this.httpOptions)
  }

  postData(url:any, param: any) {
    return this.http.post(url, param, this.httpOptions)
      .pipe(
        tap(() => {
            this._refreshNeeded$.next();
        })
      );
  }

  // updateSubscription(url, param) {
  //   return this.http.patch(url, param, this.httpOptions)
  //     .pipe(
  //       tap(() => {
  //         this._refreshNeeded$.next();
  //       })
  //     );
  // }

  // deleteSubscription(url) {
  //   return this.http.delete(url, this.httpOptions)
  //     .pipe(
  //       tap(() => {
  //         this._refreshNeeded$.next();
  //       })
  //     );
  // }

}
