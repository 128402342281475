import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '../../../shard/service/dialog.service'
import { Location } from '@angular/common';
import { WindowRefService } from '../../../core/services/window-ref.service';

function _window(): any {
  // return the global native browser window object
  return window;
}
@Component({
  selector: 'app-subscribenow',
  templateUrl: './subscribenow.component.html',
  styleUrls: ['./subscribenow.component.css'],
  providers: [WindowRefService]
})
export class SubscribenowComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  packages: any;
  eligiblepackages: any;
  subscribedpackages: any;
  subscribed_len: any;
  eligible_len: any;
  UserWalletAmount: any;
  TotalPrice: any;
  GTotalPrice:any;
  SelectedPackages: any;
  errorMessage: any;
  dataSource: any;
  submitFormArray: any;
  httpResponseMessage: any;
  jsondata: any;
  user_id: any;
  private_key: any;
  arr: Array<{ qp_id: number, qp_name: string, qp_amount: number, row_total: number, chk_box: boolean }> = [];

  constructor(private winRef: WindowRefService, private location: Location, private dialogService: DialogService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('user_id') == null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.myForm = this.fb.group({
      qpCheckBox: this.fb.array([]),
      qpChkBox: false,
      months: 1,
      user_id: this.user_id,
      private_key: this.private_key
    });
    this.UserWalletAmount = [{ wallet_amount: 0 }];
    this.TotalPrice = 0;
    this.getUserWalletAmount();
    this.getAllSubscription();
  }

  options:any;
  payrazor() {
    if (this.myForm.invalid || this.arr.length == 0) {
      this.errorNotification();
      return;
    }
    this.GTotalPrice = this.TotalPrice + (this.TotalPrice * 18 / 100);
    this.options = {
      "key": "rzp_live_JId0QsZ0cQuW7t", // Replace with your Razorpay key ID
      "amount": this.GTotalPrice * 100, // Amount is in currency subunits. Default currency is INR. Hence, 4000 refers to 40.00 INR
      "currency": "INR",
      "name": "OPPAM",
      "description": "Test Transaction",
      "image": "https://example.com/your_logo",
      "prefill": {
        "name": "Anil Kumar CV",
        "email": "anilchembally1973klm@gmail.com",
        "contact": "9847500773"
      },
      "notes": {
        "address": "Akshaya E Office, Kannur, Kerala"
      },
      "theme": {
        "color": "#3399cc"
      },
      // "handler": function (response: any){
      //   console.log(response.razorpay_payment_id);
      //   console.log(response.razorpay_order_id);
      //   console.log(response.razorpay_signature);
      // },
      // "modal": {
      //   "ondismiss": function(){
      //     console.log('Checkout form closed');
      //   }
      // }
    };

    this.options.handler = ((response:any) => {
      console.log('response ', response);
      console.log(response.razorpay_payment_id);
      console.log(response.razorpay_order_id);
      console.log(response.razorpay_signature);
      
      if (response.razorpay_payment_id){ 
        this.SubscribePackages(response.razorpay_payment_id);
      } else {
        const dataToComponent = {
          title: "Error.!!",
          message: "Transaction failed. Please verify your input and try again.!!",
          alertType: "Warning",
        }
        this.dialogService.openAlertDialog(dataToComponent);
      }
    });

    this.options.ondismiss = (() => {
          console.log('Transaction cancelled.');
    });

    const rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
    rzp1.open();
  }

  getUserWalletAmount(): void {
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userwallet + '?service_user_id=' + this.user_id + '&user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
      data => {
        this.UserWalletAmount = data;
      },
      error => this.errorMessage = <any>error);
  }

  getAllSubscription(): void {
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userpackages + '?user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
      data => {
        this.packages = data;
        this.eligiblepackages = this.packages.filter((acc: any) => acc.subscribed == 0 && +acc.eligible == 1 && +acc.display == 1);
        this.subscribedpackages = this.packages.filter((acc: any) => acc.subscribed == 1 && +acc.eligible == 1 && +acc.display == 1);
        this.dataSource = new MatTableDataSource(this.eligiblepackages);
        this.subscribed_len = this.subscribedpackages.length;
        this.eligible_len = this.eligiblepackages.length;
      },
      error => this.errorMessage = <any>error);
  };

  onCheckboxChange(pkg: any, qpid: number, subscribed: number, amt: number, $event: any): void {
    if ($event.target.checked) {
      pkg.subscribed = 0;
      subscribed += amt;
      pkg.subscribed = subscribed;
      this.TotalPrice += subscribed;
      this.arr.push({ qp_id: qpid, qp_name: pkg.qpname, qp_amount: amt, row_total: subscribed, chk_box: this.myForm.controls.qpChkBox.value });
      //if(this.myForm.controls.months.value) {subscribed+=amt*this.myForm.controls.months.value;} 
    } else {
      for (let index = 0; index < this.arr.length; index++) {
        if (this.arr[index].qp_id == qpid) {
          this.arr.splice(index, 1);
        }
      };
      this.TotalPrice -= pkg.subscribed;
      pkg.subscribed = 0;
    }
  };

  goBack(): void {
    this.router.navigate(['/dashboard']); return;
  }

  successNotification() {
    const dataToComponent = {
      title: "Done.!!",
      message: "Successfully Saved.!!",
      alertType: "Success",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  errorNotification() {
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  walletErrorNotification() {
    const dataToComponent = {
      title: "Wallet Error.!!",
      message: "Your Wallet amount is less than Total amount...Please recharge Wallet!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  SubscribePackages(paymentId: any): void {
    this.jsondata = { qpids: this.arr, 
      totalprice: this.GTotalPrice, 
      centersubscription: 0, 
      serviceuser_id: this.user_id, 
      commissionpercent: 0, 
      user_id: this.user_id, 
      private_key: this.private_key, 
      payment_id: paymentId,
      duration: 1 }
    
    // if (this.UserWalletAmount[0].wallet_amount < this.TotalPrice) {
    //   this.walletErrorNotification();
    //   this.router.navigate(['/walletrecharge']); return;
    //   return;
    // }
    // else {
      this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.addsubscription, this.jsondata).subscribe(
        data => {
          this.httpResponseMessage = data
          this.myForm.reset();
          this.successNotification();
          this.router.navigate(['/dashboard']); return;
        },
        error => this.errorMessage = <any>error)
    // }
  };

};
