
    <div class="about " fxLayout="column">
       
        <div class="row text-center">
                 <h2>Result</h2>
         </div> 

            <!-- <div class="chart-container"> -->
                <!-- <canvas  id="MyChart" >{{ chart }}</canvas> -->
            <!-- </div> -->

            <div class="table-responsive">
                <table class="table table-bordered">
                <tr class="tr-bgcolor"><td><b>Subject</b></td><td ><b>Quest</b></td><td ><b>Attend</b></td><td ><b>Mark</b></td></tr>
                <tr *ngFor="let x of Result" class="warning">
                        <td width="33%" >{{x.subject_name}}</td>
                        <td width="33%" >{{x.no_of_questions}}</td>
                        <td width="33%" >{{x.attended }}</td>
                        <td width="33%" >{{x.answered}}</td>
                        
                    </tr>
                </table>
            </div> 
            <br>
            <div style="text-align:right;"><button mat-flat-button class="btnbluedark"
                (click)="dispDashboard()">Back to Dashboard</button></div>
            <div class="row text-center">
                <button mat-flat-button class="btnbluedark" (click)="goBack()">Next Exam</button>
            </div>           
       
    </div>           
