<div class="center">
    <img src="assets/images/products/puttupodi.png" alt="">
    <img src="assets/images/products/palappampodi.png" alt="">
    <img src="assets/images/products/pathiripodi.png" alt="">
    <img src="assets/images/products/rava.png" alt="">
    <img src="assets/images/products/chakkifreshatta.png" alt="">
    <img src="assets/images/products/maida.png" alt="">
    <img src="assets/images/products/rava.png" alt="">

    <img src="assets/images/products/palada_payasam_mix.png" alt="">
    <img src="assets/images/products/rice_palada_payasam_mix.png" alt="">
    <img src="assets/images/products/vemicelli_payasam_mix.png" alt="">
    <img src="assets/images/products/mango_pickle.png" alt="">
    <img src="assets/images/products/lemon_pickle.png" alt="">
    <img src="assets/images/products/garlic_pickle.png" alt="">
    <img src="assets/images/products/dates_lemon_pickle.png" alt="">
    <img src="assets/images/products/mixed_veg_pickle.png" alt="">
    <img src="assets/images/products/prawns_pickle.png" alt="">
    <img src="assets/images/products/yam_pickle.png" alt="">
</div>