import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { DialogService } from '../../../shard/service/dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-walletrecharge',
  templateUrl: './walletrecharge.component.html',
  styleUrls: ['./walletrecharge.component.css']
})
export class WalletrechargeComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  errorMessage:any;
  httpResponseMessage:any;
  jsondata:any;
  HTMLCode:any;
  user_id:any;
  private_key:any;
  HTML_PaymentDate:any;
  PaymentDate:any;
  name:any;
  amount:any;
  bankrefno:any;
  accholdername:any;
  loginname:any;
  constructor(private route: ActivatedRoute,private router: Router, private location: Location,private dialogService: DialogService, private fb: FormBuilder, private _subscriptionService: SubscriptionService
    ) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.name = sessionStorage.getItem('name');
    this.user_id = sessionStorage.getItem('user_id');
    this.loginname = sessionStorage.getItem('loginname');
    this.private_key = sessionStorage.getItem('private_key');
    this.PaymentDate =  new Date(Date());
    this.createForm();
    this.jsondata = { 
          "user_id": this.user_id,
          "private_key":this.private_key,
          "service_id": 125,
          "name": "",
          "mobile": "",
          "service_name": "Wallet Recharge",
          "amount": 0,
          "service_charge": 0,
          "bank_charge": 0,
          "incentive": 0,
          "entered_on": this.PaymentDate,
          "html_code": "",
          "franchisee_id": "0",
          "center_type": "0",
          "user_flag": "0"
    };
    
  }
  goBack(): void {
    this.location.back();
  }

  createForm(){
    this.myForm = this.fb.group({
      Amount:[null, [Validators.required]],
      AccHolderName:[null, [Validators.required]],
      BankRefNo:[null, [Validators.required]],
      });
  };

  successNotification(){
    const dataToComponent = {
      title: "Done.!!",
      message: "Successfully Submitted.!!",
      alertType: "Success",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  walletRecharge(): void{
    if (this.myForm.invalid) {
      this.errorNotification();
      return;
    }
    else { 
      this.amount = this.myForm.get("Amount")?.value; 
      this.accholdername = this.myForm.get("AccHolderName")?.value; 
      this.bankrefno = this.myForm.get("BankRefNo")?.value; 

      this.PaymentDate = formatDate(this.PaymentDate, 'yyyy-MM-dd',"en-US");

      this.HTML_PaymentDate = formatDate(this.PaymentDate, 'dd-MMM-yyyy',"en-US");

      this.HTMLCode='pppp'; //<table border="1" width="100%"><tr><td align="center"><b>User ID</b></td><td align="center"><b>Acc Holder Name</b></td><td align="center"><b>Amount</b></td><td align="center"><b>Reference No</b></td><td align="center"><b>Date</b></td></tr><tr><td align="center">' + this.user_id + '</td><td align="center">' + this.myForm.controls.AccHolderName + '</td><td align="center">' + this.myForm.controls.Amount + '</td><td align="center">' + this.myForm.controls.BankRefNo + '</td><td align="center">' + this.HTML_PaymentDate + '</td></tr></table>';

      this.jsondata = {     
          "user_id": this.user_id,
          "private_key": this.private_key,
          "service_id": 125,
          "name": this.name,
          "mobile": this.loginname,
          "service_name": "Wallet Recharge",
          "amount": this.amount,
          "service_charge": 0,
          "bank_charge": 0,
          "incentive": 0,
          "entered_on": this.PaymentDate,
          "html_code": "<table><tr><td><b>User ID</b></td><td><b>Acc Holder Name</b></td><td><b>Amount</b></td><td ><b>Reference No</b></td><td><b>Date</b></td></tr><tr><td>" + this.user_id + "</td><td>" + this.accholdername + "</td><td>" + this.amount + "</td><td>" + this.bankrefno + "</td><td>" + this.HTML_PaymentDate + "</td></tr></table>",
          "franchisee_id": "0",
          "center_type": "0",
          "user_flag": "0"
      }
    this.jsondata = JSON.stringify(this.jsondata); 
    this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.walletrecharge, this.jsondata).subscribe(
      data => {
        this.httpResponseMessage = data
        console.log(this.httpResponseMessage);
        this.myForm.reset();
        this.successNotification();
        this.router.navigate(['/dashboard']); return;
      },
      error => this.errorMessage = <any>error)
    } 
  }
  
}
