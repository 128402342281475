import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from '../dialogs/alert-dialog/alert-dialog.component'
import { ApproveDialogComponent } from '../dialogs/approve-dialog/approve-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData, AlertDialogData, ApproveDialogData } from '../dialogs/dialog-data';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
    ) { }

    
   
    openAlertDialog(metaData: AlertDialogData){
      const dialogReference = this.dialog.open(AlertDialogComponent,
        { //disableClose: true,
          data: Object.assign({}, metaData),
          width: '400px',
          panelClass: ['animate__animated','animate__slideInLeft']
        });
    }

    openDeleteDialog(metaData: ConfirmDialogData, dataSource: any): boolean{
      const dialogReference = this.dialog.open(ConfirmDialogComponent,
        { //disableClose: true,
          data: Object.assign({}, metaData),
          width: '400px' });
      let result: boolean=false;
      dialogReference.afterClosed().subscribe((res) => {
        if(res===true){
          if (metaData.recordId! > 0 ) {
            dataSource.data = dataSource.data.filter((element: any) => element['' + metaData.colName + ''] !== metaData.recordId);
            dataSource._updateChangeSubscription();
            this.snackBar.open('Data Removed Successfully', null!, {
              panelClass: ["snackbar-success"]
            });
            result=true;
          }
        } else if (res!=="Cancel") {
          this.snackBar.open(res, null!, {
            panelClass: ["snackbar-warning"]
          });
        }
      });
      return result;
    }

    openApproveDialog(dataSource: any, metaData: ApproveDialogData){
      const dialogReference = this.dialog.open(ApproveDialogComponent,
        {
          //disableClose: true,
          data: Object.assign({}, metaData),
          width: '500px' ,
          height: '350px'
        });
      dialogReference.afterClosed().subscribe((res) => {
        if(res.Message="Successfully Approved.!!"){
          if (metaData.recordid > 0 ) {
            dataSource.data.forEach((e: any) => {
              if (e['' + metaData.colname + '']===metaData.recordid){
                e.approvalstatus=res.approvalstatus;
              }
            });
            dataSource._updateChangeSubscription();
            let msg: string = res.approvalstatus==1 ? "Successfully Approved.!!" : "Successfully Rejected.!!";
            this.snackBar.open(msg, null!, {
              panelClass: ["snackbar-success"]
            });
          }
        } else if (res!=="Cancel") {
          this.snackBar.open(res, null!, {
            panelClass: ["snackbar-warning"]
          });
        }
      });
    } 

}
