<div class="about " fxLayout="column">
    <div *ngIf="loading" style="display: flex;justify-content: center; align-items: center;">
        <mat-spinner></mat-spinner>
    </div>
        <div class="row">
            <div class="col-lg-12 pull-center">
                <h5 style="text-align: center;">{{Paper[0].examname}}</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-6 float-left">
                <h5>Study Material</h5>
            </div>
            <div class="col-lg-6 col-sm-6 float-left">
                <h3>{{Paper[0].name}}</h3>
            </div>
        </div>
        <div class="row" style="border-top: solid 1px; padding-top: 20px;">
            <div id="dvQuestions" class="col-lg-12 col-md-12 col-sm-12 faq p-0" *ngIf="isLoaded==true">
                
                <div class="qcard" *ngFor="let quest of Questions; let indexOfelement=index;">
                    <div class="qst-line"  [innerHTML]="quest.qname" ></div>
                    <div class="ans-line ans-color"  [innerHTML]="quest.qans" ></div>
                    
                    <!-- <ul lass="float-left w-100" *ngFor="let quest of Questions">
                        <li><span [innerHTML]="quest.qname"></span></li>
                        <br><span [innerHTML]="quest.qans"></span>
                    </ul> -->
                </div>
            </div>
        </div>
        <div>
            <button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button	mat-flat-button class="btnbluedark float-right" (click)="reloadQuestions()">Next</button>
        </div>
</div>        