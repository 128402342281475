import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscribenowComponent } from './subscribenow/subscribenow.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './profile/profile.component';
import { ShardModule } from 'src/app/shard/shard.module';
import { WalletrechargeComponent } from './walletrecharge/walletrecharge.component';
import { WalletComponent } from './wallet/wallet.component';
import { ProgressreportComponent } from './progressreport/progressreport.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { OtherpackagesComponent } from './otherpackages/otherpackages.component';
@NgModule({
  declarations: [DashboardComponent, SubscribenowComponent, ProfileComponent, WalletrechargeComponent, WalletComponent, ProgressreportComponent, FeedbackComponent, OtherpackagesComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,ShardModule
  ], schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }
