import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageComponent } from './modules/about-page/about-page.component';
import { ContactPageComponent } from './modules/contact-page/contact-page.component';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { QpsubjectspecificComponent } from './modules/exams/qpsubjectspecific/qpsubjectspecific.component';
import { StudyComponent } from './modules/exams/study/study.component';
import { SpecificpracticeComponent } from './modules/exams/specificpractice/specificpractice.component';
import { PracticeComponent } from './modules/exams/practice/practice.component';
import { ExamComponent } from './modules/exams/exam/exam.component';
import { BookletexamComponent } from './modules/exams/bookletexam/bookletexam.component';
import { RegformComponent } from './modules/user/regform/regform.component';
import { LoginformComponent } from './modules/loginform/loginform.component';
import { SubscribenowComponent } from './modules/dashboard/subscribenow/subscribenow.component';
import { ViewresultComponent } from './modules/exams/viewresult/viewresult.component';
import { ProfileComponent } from './modules/dashboard/profile/profile.component';
import { WalletrechargeComponent } from './modules/dashboard/walletrecharge/walletrecharge.component';
import { WalletComponent } from './modules/dashboard/wallet/wallet.component';
import { ProgressreportComponent } from './modules/dashboard/progressreport/progressreport.component';
import { FeedbackComponent } from './modules/dashboard/feedback/feedback.component';
import { OtherpackagesComponent } from './modules/dashboard/otherpackages/otherpackages.component';
import { FaqPageComponent } from './modules/faq-page/faq-page.component';
import { OtherlinksComponent } from './modules/otherlinks/otherlinks.component';
import { PricingComponent } from './modules/pricing/pricing.component';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { TermsComponent } from './modules/terms/terms.component';
const routes: Routes = [
  {path :'', redirectTo:'home', pathMatch:'full'},
  {
    path:'',
    loadChildren:() => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path:'about',
    component:AboutPageComponent,
    data: {title: "About"}
  },
  {
    path:'otherlinks',
    component:OtherlinksComponent,
    data: {title: "OtherLinks"}
  },
  {
    path:'features',
    component:FaqPageComponent,
    data: {title: "Features"}
  },
  {
    path:'pricing',
    component:PricingComponent,
    data: {title: "pricing"}
  },
  {
    path:'privacypolicy',
    component:PrivacypolicyComponent,
    data: {title: "Privacy and Refund Policy"}
  },
  {
    path:'terms',
    component:TermsComponent,
    data: {title: "Terms and Conditions"}
  },  
  {
    path:'contact',
    component:ContactPageComponent,
    data: {title: "Contact Page"}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: "DashBoard" }
  },
  {
    path: 'qpsubjectspecific/:qpid/:examid',
    component: QpsubjectspecificComponent,
    data: { title: "Subject / Specific" }
  },
  {
    path: 'qpsubjectspecific/:qpid/:examid/:target_id',
    component: QpsubjectspecificComponent,
    data: { title: "Subject / Specific" }
  },
  {
    path: 'study/:qpid/:sub_cat_id/:specific_id',
    component: StudyComponent,
    data: { title: "Specific Study" }
  },
  {
    path: 'specificpractice/:qpid/:sub_cat_id/:specific_id',
    component: SpecificpracticeComponent,
    data: { title: "Specific Practice" }
  },
  {
    path: 'practice/:qpid',
    component: PracticeComponent,
    data: { title: "Practice Exam" }
  },
  {
    path: 'exam/:qpid',
    component: ExamComponent,
    data: { title: "Live Exam" }
  },
  {
    path: 'regform',
    component: RegformComponent,
    data: { title: "Registration Form" }
  },
  {
    path: 'regform/:src',
    component: RegformComponent,
    data: { title: "Registration Form" }
  },
  {
    path: 'loginform',
    component: LoginformComponent,
    data: { title: "Login Form" }
  },
  {
    path: 'subscribenow',
    component: SubscribenowComponent,
    data: { title: "SubScribe Now Form" }
  },
  {
    path: 'viewresult/:attemptid/:qpid',
    component: ViewresultComponent,
    data: { title: "View Result" }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: { title: "View Profile" }
  },
  {
    path: 'walletrecharge',
    component: WalletrechargeComponent,
    data: { title: "Wallet Recharge" }
  },
  {
    path: 'wallet',
    component: WalletComponent,
    data: { title: "Wallet Details" }
  },
  {
    path: 'progressreport',
    component: ProgressreportComponent,
    data: { title: "Progress Report" }
  },
  {
    path: 'bookletexam/:bookletno',
    component: BookletexamComponent,
    data: { title: "Spot Exam" }
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    data: { title: "Feed Back" }
  },
  {
    path: 'otherpackages',
    component: OtherpackagesComponent,
    data: { title: "Other Packages" }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash:true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
