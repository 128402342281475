<div class="navbar_jd" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="20" fxFlex.xs="100"> <a routerLink="/home"><img src="assets/icons/logo.png"></a></div>
    <div fxFlex="80">
         <ul class="nav-item">
           <li><a routerLink="/home">Home</a></li>
          <li><a routerLink="/dashboard">Dashboard</a></li>
           <li><a routerLink="/features">Features</a></li>
            <li><a routerLink="/otherlinks">Other Links</a></li>
           <li><a routerLink="/about">About</a></li>
           <li><a routerLink="/pricing">Our Pricing</a></li>
           <li><a routerLink="/contact">Contact US</a></li>
            <li><a routerLink="/loginform">Sign In</a></li>
            <li  (click)="signOut()"><a href="">Sign Out</a></li>
         </ul>
    </div>
    

    <div fxFlex="40" fxFlex.xs="40" (click)="toggle()">
      <div class="mobile-item" [@toggleBox]="isOpen ? 'open' : 'closed'">
        <div class="logo" > 
          <img src="assets/icons/logo.png" style="padding: 21px 31px;">
        </div>
        <ul >
          <li><a routerLink="/home">Home</a></li>
          <li><a routerLink="/dashboard">Dashboard</a></li>
          <li><a routerLink="/features">Features</a></li>
          <li><a routerLink="/otherlinks">Other Links</a></li>
          <li><a routerLink="/about">About</a></li>
          <li><a routerLink="/pricing">Our Pricing</a></li>
          <li><a routerLink="/contact">Contact US</a></li>
          <li><a routerLink="/loginform">Sign In</a></li>
          <li (click)="signOut()"><a href="">Sign Out</a></li>
        </ul>
      </div>

     
 </div>

 <div class="mobile-menu" (click)="toggle()">
  <mat-icon style="color: white;">{{isOpen? "close" : "menu" }}</mat-icon>
  <!-- <img [src]="isOpen ? 'assets/icons/cancel.svg' : 'assets/icons/menu.svg'" alt=""> -->
</div>
</div>


<!-- 
    <table>
      <tr>
        <th>id</th>
        <th>name</th>
        <th>unit</th>
      </tr>
      <tr *ngFor="let item of data; let i = index">
        <td>
            <input type="checkbox" [id]="item+i" [name]="item"[(ngModel)]="item.Checked" (change)="getCheckboxValues($event,item)">
        </td>
        <td>{{item.id}}</td>
        <td>{{item.name}}</td>
        <td>{{item.unit}}</td>
      </tr>
    </table>


    <ul>
      <li *ngFor="let item of newArray">
          {{item.unit}}
      </li>
    </ul>

    <h1 *ngIf="this.newArray.length > 0">{{this.totalunit | async}}</h1>
     -->