import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  packages: any;
  eligiblepackages: any;
  subscribedpackages: any;
  subscribed_len: any;
  eligible_len: any;
  UserWalletAmount: any;
  dataSource: any;
  errorMessage: any;
  user_id: any;
  private_key:any;
  edulevelid:any;
  standardid:any;
  
  SpotExams:any;
  SpotExamsLength:any;
  exam_message:any;
  ResultAll:any;
  ResultAllLength:any;
  total:any;
  score:any;
  attempt:any;
  grade:any;
  usertype:any;
  attended:any;
  //UserData:any;
  
  constructor(private route: ActivatedRoute,private router: Router, private location: Location,private _subscriptionService: SubscriptionService) {

  }

  ngOnInit(): void {
    
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    
    this.user_id =  sessionStorage.getItem('user_id');
    this.usertype = sessionStorage.getItem('usertype');
    this.private_key = sessionStorage.getItem('private_key');
    this.edulevelid =  sessionStorage.getItem('edulevelid');
    this.standardid = sessionStorage.getItem('standardid');
    this.UserWalletAmount=[{wallet_amount:0}];
    this.ResultAllLength=0;
    //console.log(this.user_id);
    this.getUserWalletAmount();
    this.getAllSubscription();
    this.getBooklets();
    this.getResult();
    //console.log(this.user_id,this.edulevelid,this.standardid);
    this.exam_message="";
  }

  getResult(): void{
    this.ResultAll = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.aptituderesult + '?user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
    data => {
    this.ResultAll = data; console.log(this.ResultAll);
    this.total = 0;
    this.score = 0;
    this.attempt = 0;
    this.attended = 0;
    this.grade = 0 ;
    if(this.ResultAll) this.ResultAllLength=this.ResultAll.length;
      for(let index=0; index<this.ResultAll.length; index++){
        this.total = this.total + this.ResultAll[index].totalmark;
        this.score = this.score + this.ResultAll[index].totalscore;
        this.attended = this.attended + this.ResultAll[index].questions_attended;
        this.attempt = this.attempt + 1;
      }; 
     // console.log(this.total,this.score);
      this.grade = this.score / this.total * 100 ;
      this.grade = this.grade.toFixed(0);
      if(this.grade>=70){
        this.exam_message="You are eligibile(mark >= 70%) to attend our Offline Exam and the center name will be given later...!!";
      }
      else {
        this.exam_message="You are not eligibile(mark < 70%) to attend our Offline Exam, try to practice again...!!";
      }
    },
    error => this.errorMessage = <any>error);
  }

  ///use this code
  dispWalletRecharge(): void{
    this.router.navigate(['/walletrecharge']);
  }

  dispFeedBack(): void{
    this.router.navigate(['/feedback']);
  }

  dispOtherPackages(): void{
    this.router.navigate(['/otherpackages']);
  }

  dispProgressReport(): void{
    this.router.navigate(['/progressreport']);
  }

  dispWallet(): void{
    this.router.navigate(['/wallet']);
  }

  getUserWalletAmount(): void { 
    this.UserWalletAmount[0].wallet_amount=0;
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userwallet + '?service_user_id=' + this.user_id + '&user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
    data => { 
      if(data) this.UserWalletAmount = data;
      if(this.UserWalletAmount.Message=='Unauthorised Access.!!') {
        this.router.navigate(['/loginform']); return;
      }
      //console.log(this.UserWalletAmount);
    },
    error => this.errorMessage = <any>error);
  }
  
  dispSubScribeNow(): void{
    this.router.navigate(['/subscribenow']); 
  }

  dispProfile(): void{
    this.router.navigate(['/profile']); 
  }

  getAllSubscription(): void {
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userpackages + '?user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
      data => {
        this.packages = data; //console.log(data);
        this.eligiblepackages = this.packages.filter(function (item: any) {
          if (parseInt(item.subscribed) === 0 && parseInt(item.eligible) === 1 && parseInt(item.display) === 1) { return true; } else { return false; }
        });
        this.subscribedpackages = this.packages.filter(function (item: any) {
          if (parseInt(item.subscribed) === 1) { return true; } else { return false; }
        });
        this.subscribed_len = this.subscribedpackages.length;
        this.eligible_len = this.eligiblepackages.length;
      },
      error => this.errorMessage = <any>error);
  }
  //#/qpsubjectspecific/{{pkg.qpid}}/{{pkg.examid}}   #/practice/{{pkg.qpid}}   #/exam/{{pkg.qpid}}
  showPage(qpid: any, examid:any, targetid:any): void {
    if(targetid==1)
      this.router.navigate(['/qpsubjectspecific/'+qpid+'/'+examid]); 
    else if(targetid==2)  
      this.router.navigate(['/practice/'+qpid]); 
    else if(targetid==3) 
      this.router.navigate(['/exam/'+qpid]);   
  }


	getBooklets(): void{ 
    this.SpotExamsLength=0;
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.booklets + '?edulevelid='+ this.edulevelid + '&standardid='+this.standardid + '&user_id=' +  this.user_id + '&private_key=' + this.private_key).subscribe(
      data => {
        this.SpotExams = data;
        if(data) this.SpotExamsLength = this.SpotExams.length;
        console.log(this.SpotExams);
      },
      error => this.errorMessage = <any>error); 
  }   

  spotExam(bookletno:any): void{
    this.router.navigate(['/bookletexam/'+bookletno]);  
  }

}
