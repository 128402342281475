<div class="about" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">

        <div fxFlex="100%" class="animate__animated animate__fadeIn animate__slower">
            <h3>Profile Details</h3>

            <form [formGroup]="myForm" fxLayout="column"> <div fxLayout="row wrap" fxLayout.xs="column">
                <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Full Name</mat-label>
                    <input matInput placeholder="Full Name :" formControlName="name">

                </mat-form-field></div>
                <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Education Level</mat-label>
                    <mat-select formControlName="education_level">
                        <mat-option *ngFor="let opt of EduLevels" [value]="opt.id" (onSelectionChange)="getFilterMaster('Qualification', opt.id)">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                </mat-form-field></div>
                <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Qualification</mat-label>
                    <mat-select formControlName="qualification_id">
                        <mat-option *ngFor="let opt of Qualifications" [value]="opt.id" (onSelectionChange)="getFilterMaster('Qualification Subject', opt.id)">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                </mat-form-field></div>
                <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Subject / Medium</mat-label>
                    <mat-select formControlName="qualification_subject_id">
                        <mat-option *ngFor="let opt of subjectList" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                </mat-form-field></div>
                <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Additional Qualification</mat-label>
                    <mat-select formControlName="prof_qualification_id">
                        <mat-option *ngFor="let opt of ProfQualifications" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Year of Birth</mat-label>
                    <mat-select formControlName="dob">
                        <mat-option [value]="1980">1980</mat-option> 
                        <mat-option [value]="1981">1981</mat-option>   
                        <mat-option [value]="1982">1982</mat-option>   
                        <mat-option [value]="1983">1983</mat-option>   
                        <mat-option [value]="1984">1984</mat-option>   
                        <mat-option [value]="1985">1985</mat-option>   
                        <mat-option [value]="1986">1986</mat-option>   
                        <mat-option [value]="1987">1987</mat-option>   
                        <mat-option [value]="1988">1988</mat-option>   
                        <mat-option [value]="1989">1989</mat-option>   
                        <mat-option [value]="1990">1990</mat-option>   
                        <mat-option [value]="1991">1991</mat-option>   
                        <mat-option [value]="1992">1992</mat-option>   
                        <mat-option [value]="1993">1993</mat-option>   
                        <mat-option [value]="1994">1994</mat-option>   
                        <mat-option [value]="1995">1995</mat-option>   
                        <mat-option [value]="1996">1996</mat-option>     
                        <mat-option [value]="1997">1997</mat-option>   
                        <mat-option [value]="1998">1998</mat-option>   
                        <mat-option [value]="1999">1999</mat-option>   
                        <mat-option [value]="2000">2000</mat-option>   
                        <mat-option [value]="2001">2001</mat-option> 
                        <mat-option [value]="2002">2002</mat-option>  
                        <mat-option [value]="2003">2003</mat-option>  
                        <mat-option [value]="2004">2004</mat-option>  
                        <mat-option [value]="2005">2005</mat-option>  
                        <mat-option [value]="2006">2006</mat-option>  
                        <mat-option [value]="2007">2007</mat-option>  
                        <mat-option [value]="2008">2008</mat-option>  
                        <mat-option [value]="2009">2009</mat-option>  
                        <mat-option [value]="2010">2010</mat-option>  
                        <mat-option [value]="2011">2011</mat-option>  
                        <mat-option [value]="2012">2012</mat-option>  
                        <mat-option [value]="2013">2013</mat-option>  
                        <mat-option [value]="2014">2014</mat-option>  
                        <mat-option [value]="2015">2015</mat-option>  
                        <mat-option [value]="2016">2016</mat-option>  
                        <mat-option [value]="2017">2017</mat-option>  
                        <mat-option [value]="2018">2018</mat-option>  
                        <mat-option [value]="2019">2019</mat-option>  
                        <mat-option [value]="2020">2020</mat-option>  
                        <mat-option [value]="2021">2021</mat-option>   
                        <mat-option [value]="2022">2022</mat-option>
                        <mat-option [value]="2023">2023</mat-option>                       
                    </mat-select>

                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option [value]="'Male'">Male</mat-option> 
                        <mat-option [value]="'Female'">Female</mat-option>
                        <mat-option [value]="'TransGender'">TransGender</mat-option>  
                    </mat-select>
            
                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Caste Category</mat-label>
                    <mat-select formControlName="caste_cat">
                        <mat-option [value]="'OBC'">OBC</mat-option> 
                        <mat-option [value]="'OEC'">OEC</mat-option>
                        <mat-option [value]="'FC'">FC</mat-option> 
                        <mat-option [value]="'SC/ST'">SC/ST</mat-option> 
                    </mat-select>
            
                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state_id">
                        <mat-option *ngFor="let opt of State" [value]="opt.id" (onSelectionChange)="getFilterMaster('District', opt.id)">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>District</mat-label>
                    <mat-select formControlName="dist_id">
                        <mat-option *ngFor="let opt of District" [value]="opt.id" (onSelectionChange)="getFilterMaster('Panchayath', opt.id)">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Panchayath</mat-label>
                    <mat-select formControlName="panchayath_id">
                        <mat-option *ngFor="let opt of Panchayath" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>   
                    </mat-select>
                    
                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Mobile</mat-label>
                    <input matInput placeholder="Mobile" formControlName="mobile">
                    
                  </mat-form-field></div>
                  <div fxFlex="50"><mat-form-field appearance="outline" fxFlex="95">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email">
                    
                 </mat-form-field></div>
                  <div fxFlex="50">
                      <button mat-flat-button color="primary" (click)="updateData()">Update Data</button> 
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>
                  </div>
                  
            </div></form>
        </div>
       
    </div>

