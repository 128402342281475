
export class AppSettings {
  public static API_ENDPOINT = 'https://oppam.in/api/';
  //public static API_ENDPOINT = 'https://meccara.in/oppamapi/';
  
  public static login = 'user/login'; 
  public static register = "user/register";
  public static contact = 'user/contact'; 
  public static mastercombo = "user/mastercombo";
  public static filtercombo = "user/filtercombo";
  public static userwallet = "dashboard/userwallet";
  public static booklets = "dashboard/booklets";
  public static userpackages = "dashboard/userpackages";
  public static addsubscription = "dashboard/addsubscription";
  public static qpsubjects = "exams/qpsubjects";
  public static qpspecifics = "exams/qpspecifics";
  public static specificvideos = "exams/specificvideos";
  public static specificnames = "exams/specificnames";
  public static specificpractice = "exams/specificpractice";
  public static qpdetails = "exams/qpdetails";
  public static study = "exams/study";
  public static practice = "exams/practice";
  public static exam = "exams/exam";
  public static assessment = 'exams/assessment';
  public static attemptid = 'exams/attemptid';
  public static lastqpstatus = 'exams/lastqpstatus';
  public static profile = 'dashboard/profile';
  public static profileupdate = 'dashboard/profileupdate';
  public static walletrecharge = 'dashboard/walletrecharge';
  public static wallet = 'dashboard/wallet';
  public static progressreport = 'dashboard/progressreport';
  public static filteredsubjects = 'dashboard/filteredsubjects';
  public static bookletdetails = "exams/bookletdetails";
  public static bookletquestions = "exams/bookletquestions";
  public static bookletresultupdate = "exams/bookletresultupdate";
  public static aptituderesult = "dashboard/aptituderesult";
  public static feedback = "dashboard/feedback";
  public static userpackagesother = "dashboard/userpackagesother";
  public static userpackagesexams = "dashboard/userpackagesexams";
 
  //public static subscription = 'getPackages';
  //public static qpspecifics = 'getSpecific';
  //public static qpsubjects = 'getQpSubjects';
  //public static qpdetails = 'getQuestionPaperDetail';
  //public static study = 'studyQuestions';
  //public static specificnames = 'getSpecificName';
  //public static specificpractice = 'getSchoolPracticeExam';
  //public static practice = 'practiceExam';
  //public static exam = 'writtenExam';
  //public static userwallet = 'getUserWalletAmount';
  //public static mastervaluesforcombo = 'getMasterValuesForCombo';
  //public static filtermastervalues = 'getFilteredMasterValuesForCombo';
  //public static userregistration = 'setUserRegistration'; //post
   
}
  