import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

interface items{
  id?:any;
  unit?:any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('toggleBox', [
      // ...
      state('open', style({
        right: '0px',
      })),
      state('closed', style({
        right: '-281px'
      })),
      transition('open <=> closed', [
        animate('.3s')
      ])
    ])
  ],
  encapsulation:ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  
  constructor(private router: Router){

    }
  // public totalunit: BehaviorSubject<any> = new BehaviorSubject<any>(Number)

  // newArray:items[] =[];
  // data:any = [
  //   {id:1, unit:10, name:'jijin'},
  //   {id:2, unit:10, name:'jijin'},
  //   {id:3, unit:10, name:'jijin'},
  //   {id:4, unit:10, name:'jijin'},
  //   {id:5, unit:10, name:'jijin'},
  //   {id:6, unit:10, name:'jijin'},
  //   {id:7, unit:10, name:'jijin'},
  //   {id:8, unit:10, name:'jijin'}

  // ];

  isOpen:boolean = false;
  //isLogIn:boolean = true;
  //isLogOut:boolean = true;
  

  toggle() {
    this.isOpen = !this.isOpen;
  }
  
  


  ngOnInit(): void {
   
  }

  signOut(): void{
    sessionStorage.clear(); 
    //this.isLogOut=true;
    //this.isLogIn=false;
  }

  //Checkbox Change detecting function
  // getCheckboxValues(ev:any, data:any) {
  //   if(ev.target.checked){
  //     // Pushing the object into array
  //     let obj = {
  //       id: data.id,
  //       unit:data.unit
  //     }
  //     this.newArray.push(obj);
  //   }  
  //   else{
  //     this.newArray.splice(this.newArray.indexOf(data),1);
  //   }
  //   this.sum();
  // }

  // sum(){
  //   if(this.newArray.length > 0){
  //     const total = this.newArray.reduce((a, b)=>  a + b.unit , 0 );
  //     this.totalunit.next(total)
  //   }else{
  //     this.totalunit.next(0)
  //   }
  // }
  

}
