import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { Location } from '@angular/common';
@Component({
  selector: 'app-qpsubjectspecific',
  templateUrl: './qpsubjectspecific.component.html',
  styleUrls: ['./qpsubjectspecific.component.css']
})
export class QpsubjectspecificComponent implements OnInit {
  user_id:any;
  private_key:any;
  qpid:any;
  examid:any;
  target_id : any;
	target_route:any;
	QpSubjects : any;
  Specific:any; 
  specific_len:any;
	sub_cat_id:any;
	specific_show:any;
	qpsubject_show:any;
  errorMessage: any;
  videos_show:any;
  SpecificVideos:any;
  specific_videos_len:any;
  videoName:any;
  videoStart:any;
  constructor(private route: ActivatedRoute, private _subscriptionService: SubscriptionService,
     private location: Location, private router: Router) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.target_route="specific";
    this.qpsubject_show=0;
    this.specific_show=0;
    this.sub_cat_id=0;
    this.target_id=0;
    this.videos_show=0;
    this.videoStart=0;
    this.qpid = this.route.snapshot.paramMap.get('qpid');
    this.examid = this.route.snapshot.paramMap.get('examid');
    this.target_id = this.route.snapshot.paramMap.get('target_id');
    this.showQpSubjects();
    console.log(this.qpid);
    console.log(this.examid);
  }

  goBack(): void {
    this.location.back();
  }

  showHide(): void {
    this.videos_show=0;
  }

  showVideo(videoname:any): void{
    this.videoStart=1;
    this.videoName=videoname;
  }

  getBack(): void {
    this.router.navigate(['/qpsubjectspecific/'+this.qpid+'/'+this.examid+'/2']); 
  }

  showSpecific(subject_id:any): void { 
    console.log(subject_id); 
    this.specific_show=1;
		this.qpsubject_show=0;
		this.sub_cat_id=subject_id;
		this.Specific = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.qpspecifics + '?qp_id='+this.qpid+'&sub_cat_id='+this.sub_cat_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
    data => { 
      this.Specific = data;
      this.specific_len = this.Specific.length;
      console.log(this.Specific);
    },
    error => this.errorMessage = <any>error);
  }

  getSpecificVideos(specific_id:any): void { 
    console.log(specific_id); 
    if(this.videos_show==1)
        this.videos_show=0;
    else
        this.videos_show=1;
    this.SpecificVideos = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.specificvideos + '?specific_id='+specific_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
    data => { 
      this.SpecificVideos = data;
      this.specific_videos_len = this.SpecificVideos.length;
      console.log(this.SpecificVideos);
    },
    error => this.errorMessage = <any>error);
  }

  showQpSubjects(): void {
    if(this.target_id==='1'){
      this.showSpecific(this.examid);
    }
    else{
      this.QpSubjects = []; 
      this._subscriptionService.getData(AppSettings.API_ENDPOINT +  AppSettings.qpsubjects + '?qp_id='+this.qpid +'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
      data => {
        this.QpSubjects = data;
       
        if(this.QpSubjects.Message=='Unauthorised Access.!!') {
          this.router.navigate(['/loginform']); return;
        }
        
        console.log(this.QpSubjects);
        if(this.QpSubjects[0].length===1 && this.target_id==='2') 
        {  
          this.router.navigate(['/dashboard']); 
        }
        else if(this.QpSubjects[0].length===1) 
        {
          this.showSpecific(this.QpSubjects[0][0].subcat_id);
        }
        else
        {
          this.qpsubject_show=1;
          this.specific_show=0;
        }
      },
      error => this.errorMessage = <any>error);
    } 
  }
  
  //#/study/{{qpid}}/{{sub_cat_id}}/{{exams.specific_id}}
  //href="#/specificpractice/{{qpid}}/{{exams.specific_id}}
  goStudy(specific_id:any): void{
    this.router.navigate(['/study/'+this.qpid+'/'+this.sub_cat_id+'/'+specific_id]); 
  }

  goSpecificPractice(specific_id:any): void{
    this.router.navigate(['/specificpractice/'+this.qpid+'/'+this.sub_cat_id+'/'+specific_id]); 
  }

  showPage(specificid: any, targettype:any): void {
    if(targettype==1)
      this.getSpecificVideos(specificid);
    else if(targettype==2)  
    this.getSpecificVideos(specificid);
  }
  
}
