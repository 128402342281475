import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { DialogService } from '../../../shard/service/dialog.service';

//import  Chart  from 'chart.js';


@Component({
  selector: 'app-viewresult',
  templateUrl: './viewresult.component.html',
  styleUrls: ['./viewresult.component.css']
})
export class ViewresultComponent implements OnInit {
  public chart: any;
  Result:any;
  errorMessage:any;
  user_id:any;
  private_key:any;
  qpid:any;
  attemptid:any;
  series:any;
  data:any;
  data2:any;
  labels:any;
  series_a:any;
  series_b:any;
  jsondata:any;
  httpResponse:any;
  CatTypes:any;
  myForm: FormGroup = new FormGroup({});
  //columnChartOptions:any;
  constructor(private dialogService: DialogService, private fb: FormBuilder, private route: ActivatedRoute,private router: Router, private location: Location, private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.CatTypes = [{id:"Web Site",name:"Web Site"},{id:"Site Error",name:"Site Error"},{id:"Site Slow",name:"Site Slow"}];
    this.createForm();
    this.qpid = this.route.snapshot.paramMap.get('qpid'); 
    this.attemptid = this.route.snapshot.paramMap.get('attemptid'); 
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.lastQpStatus();
    this.createChart();
    }
    
    createForm(){
      this.myForm = this.fb.group({
        qqcategory: [null, [Validators.required]],
        comment: [null, [Validators.required]], 
        });
    };

  createChart(): void {/*
    this.chart = new Chart("MyChart", {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: ['2022-05-10', '2022-05-11', '2022-05-12','2022-05-13',
                 '2022-05-14', '2022-05-15', '2022-05-16','2022-05-17', ], 
         datasets: [
          {
            label: "Sales",
            data: ['467','576', '572', '79', '92',
                 '574', '573', '576'],
            backgroundColor: 'blue'
          },
          {
            label: "Profit",
            data: ['542', '542', '536', '327', '17',
                   '0.00', '538', '541'],
            backgroundColor: 'limegreen'
          }  
        ]
      },
      options: {
        aspectRatio:2.5
      }
      
    }) */}
  
  goBack(): void {
    this.location.back();
  }

  successNotification(){
    const dataToComponent = {
      title: "Success.!!",
      message: "Your Comment submitted...!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  dispDashboard(): void{
    this.router.navigate(['/dashboard']);
  }
  
  lastQpStatus(): void{
    this.Result = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.lastqpstatus + '?qpid='+this.qpid+'&attemptid='+this.attemptid + '&user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
    data => {
      this.Result = data;

      if(this.Result.Message=='Unauthorised Access.!!') {
        this.router.navigate(['/loginform']); return;
      }

      console.log(this.Result);
      this.series = ['Total', 'Obtained'];
			this.data = [];
      this.data2=[];
			this.labels = [];
			this.series_a = [], this.series_b = [];
			for (var i in this.Result)
			{
				this.labels.push(this.Result[i].subject_name);
				this.series_a.push(this.Result[i].no_of_questions);
				this.series_b.push(this.Result[i].answered);
			};
      console.log(this.labels);
      console.log(this.series_a);
      console.log(this.series_b);
			this.data.push(this.series_a);
			this.data.push(this.series_b); 
    },
    error => this.errorMessage = <any>error);
  }

  getReport(): void{ /*
    this.chart = new Chart("MyChart", {
      type: 'bar', //this denotes tha type of chart
      data: {// values on X-Axis
        labels: this.labels, 
         datasets: [
          {
            label: "Total",
            data: this.series_a,
            backgroundColor: 'red'
          },
          {
            label: "Scored",
            data: this.series_b,
            backgroundColor: 'blue'
          }
        ]
      },
      options: {
        aspectRatio:2.5
      }
      
    })  */
  }

}
