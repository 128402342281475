    <div class="about" fxLayout="column">
		<div *ngIf="loading" style="display: flex;justify-content: center; align-items: center;">
			<mat-spinner></mat-spinner>
		</div>
		<div class="row text-center">
				<h3>Practice Questions</h3>
		</div>            
		<div class="row" style="display: flex;justify-content: space-between;">
			<div>{{Chapter[0].subject}}</div>
			<div>{{Chapter[0].name}}</div>
		</div>

		<div class="row" style="border-top: solid 1px; padding-top: 20px;">		
			<div id="dvQuestions" *ngIf="QuestionsLength>1 && isLoaded==true">
				<div class="qcard" *ngFor="let quest of Questions; let indexOfelement=index;">
					<div class="qst-line"  [innerHTML]="(indexOfelement + 1) + '. &nbsp;' + quest.qname" ></div>
					<div class="choice-line" id="IC1{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch1{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC1',$event)"
							value="1">
						<label class="opt-label" for="rdch1{{indexOfelement}}" [innerHTML]="quest.ch1"></label>
					</div>
					<div class="choice-line" id="IC2{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch2{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC2',$event)"
							value="2">
						<label class="opt-label" for="rdch2{{indexOfelement}}" [innerHTML]="quest.ch2"></label>
					</div>
					<div class="choice-line" id="IC3{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch3{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC3',$event)"
							value="3">
						<label class="opt-label" for="rdch3{{indexOfelement}}" [innerHTML]="quest.ch3"></label>
					</div>
					<div class="choice-line" id="IC4{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch4{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC4',$event)"
							value="4">
						<label class="opt-label" for="rdch4{{indexOfelement}}" [innerHTML]="quest.ch4"></label>
					</div>
				</div>
			</div>																																																																																				<div class="subfooter">
				<button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
				<button	mat-flat-button class="btnbluedark float-right" (click)="reloadQuestions()">Next</button>
			</div>
		</div>
    </div>