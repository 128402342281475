import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  Wallet:any;
  errorMessage:any;
  user_id: any;
  private_key:any;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location, private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id =  sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.wallet();
  }

  goBack(): void{
    this.location.back();
  }
  
  wallet(): void{
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.wallet + '?user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
      data => {
        this.Wallet = data;
        console.log(this.Wallet);
      },
      error => this.errorMessage = <any>error); 
  }

}
