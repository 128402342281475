import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { DialogService } from '../../../shard/service/dialog.service'
@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css']
})
export class StudyComponent implements OnInit {
  loading:boolean=true;
  user_id:any;
  private_key:any;
  study:any;
  isLoaded:any;
  qp_id:any;
  sub_cat_id:any;
  specific_id:any;
  Paper:any;
  errorMessage:any;
  QuestionsLength:any;
  Questions:any;
  message:any;

  constructor(private dialogService: DialogService,private location: Location,private route: ActivatedRoute, private router: Router,private _subscriptionService: SubscriptionService) { }
  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.isLoaded=false;
		this.qp_id = this.route.snapshot.paramMap.get('qpid');
		this.sub_cat_id = this.route.snapshot.paramMap.get('sub_cat_id');
		this.specific_id = this.route.snapshot.paramMap.get('specific_id');
    this.QuestionsLength=10;
    this.getQpDetails();
    this.loadQuestions();
  }

  reloadQuestions(): void{
    window.location.reload();
  }

    goBack(): void {
    this.location.back();
  }
  getQpDetails(): void {
		this.Paper = [{}]; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.qpdetails + '?qpid='+this.qp_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
    data => {
      this.Paper = data;
      if(this.Paper.Message=='Unauthorised Access.!!') {
        this.router.navigate(['/loginform']); return;
      }
    },
    error => this.errorMessage = <any>error);
  }
  
  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: this.message,
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  loadQuestions():void {
		this.Questions = [];
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.study + '?qp_id='+this.qp_id+'&sub_cat_id='+this.sub_cat_id+'&specific_id='+this.specific_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
      data => {
        this.Questions = data;
        this.isLoaded=true;
        this.loading=false;
        if(this.Questions[0].Question=='Maximum Study Count Reached'){
          this.message="Access Denied! Your free trial access exceeded. Please recharge your wallet and subscribe for this examination.!!";
          this.errorNotification();
          this.router.navigate(['/subscribenow']);
        }
        if (this.Questions[0].Question=='Your Subscription Expired' ){
          this.message="Access Denied! Your Subscription Expired. Please recharge your wallet and subscribe for this examination.!!";
          this.errorNotification();
          this.router.navigate(['/subscribenow']);
        }
      },
      error => this.errorMessage = <any>error);
	}; 

}
