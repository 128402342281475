import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { DialogService } from '../../../shard/service/dialog.service'
//import  Chart  from 'chart.js';

interface Post {
  startdate: Date;
  enddate: Date;
}


@Component({
  selector: 'app-progressreport',
  templateUrl: './progressreport.component.html',
  styleUrls: ['./progressreport.component.css']
})
export class ProgressreportComponent implements OnInit {
  
  post: Post = {
    startdate: new Date(Date.now()),
    enddate: new Date(Date.now())
  }
  public chart: any;
  myForm: FormGroup = new FormGroup({});
  packages:any=[];
  eligibleqpids:any=[];
  subscribedqpids:any=[];
  eligible_len:any;
  subscribed_len:any;
  errorMessage: any;
  Report:any=[];
  jsondata:any;
  user_id:any;
  private_key:any;
  qpid:any;
  Subjects:any;
  data:any;
  data2:any;
  Result:any;
  ResultLength:any;
  labels:any;
  series_a: any;
  series_b:any;
  series:any;
  ResultAll:any;
  ResultAllLength:any;
  total:any;
  score:any;
  attempt:any;
  grade:any;
  usertype:any;
  attended:any;
  constructor( private location: Location, private dialogService: DialogService, private route: ActivatedRoute,private router: Router,private fb: FormBuilder, private _subscriptionService: SubscriptionService) {  }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.usertype = sessionStorage.getItem('usertype');
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.ResultLength=0;
    this.ResultAllLength=0;
    this.myForm = this.fb.group({
      startdate: [formatDate((this.post.startdate), 'yyyy-MM-dd', 'en'), [Validators.required]],
      enddate: [formatDate(this.post.enddate, 'yyyy-MM-dd', 'en'), [Validators.required]],
      reporttype:["Exam Wise", [Validators.required]],
      qpid:0,
      subjectid:[0, [Validators.required]],
      examtype:[3, [Validators.required]],
      user_id:this.user_id,
      private_key:this.private_key,
    });
    this.getPackages();
    this.getResult();
  }

  getResult(): void{
    this.ResultAll = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.aptituderesult + '?user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
    data => {
    this.ResultAll = data; console.log(this.ResultAll);
    if(this.ResultAll) this.ResultAllLength=this.ResultAll.length;
    this.total = 0;
    this.score = 0;
    this.attempt = 0;
    this.attended = 0;
    this.grade = 0 ;
      for(let index=0; index<this.ResultAll.length; index++){
        this.total = this.total + this.ResultAll[index].totalmark;
        this.score = this.score + this.ResultAll[index].totalscore;
        this.attended = this.attended + this.ResultAll[index].questions_attended;
        this.attempt = this.attempt + 1;
      }; 
      console.log(this.total,this.score);
      this.grade = this.score / this.total * 100 ;
      this.grade = this.grade.toFixed(0);
    },
    error => this.errorMessage = <any>error);
  }

  goBack(): void{
    this.location.back();
  }

  examTypeChange(): void{

  }

  errorNotification(){
    const dataToComponent = {
      title: "No Data.!!",
      message: "No Record found.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  getReport(): void{ 
     if (this.myForm.invalid) {
       this.errorNotification();
       return;
     }
    console.log(this.myForm); 
    this.jsondata = JSON.stringify(this.myForm.value); console.log(this.jsondata);
    this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.progressreport, this.jsondata).subscribe(
      data => { console.log(data);
        this.Report = data;
        if(this.Report=='no') {
          this.errorNotification(); return;
        }
              
        this.Result = this.Report[0];
        if(this.Result) this.ResultLength=this.Report.length;
        console.log(this.Result);
        this.series = ['Total', 'Obtained'];
        this.data = [];
        this.data2=[];
        this.labels = [];
        this.series_a = [], this.series_b = [];
        for (var i in this.Result.table2)
        { 
          this.labels.push(this.Result.table2[i].subcatname);
          this.series_a.push(this.Result.table2[i].totalq);
          this.series_b.push(this.Result.table2[i].answered);
        };
     
        // this.chart = new Chart("MyChart", {
        //   type: 'bar', //this denotes tha type of chart
        //   data: {// values on X-Axis
        //     labels: this.labels, 
        //     datasets: [
        //       {
        //         label: "Total",
        //         data: this.series_a,
        //         backgroundColor: 'red'
        //       },
        //       {
        //         label: "Scored",
        //         data: this.series_b,
        //         backgroundColor: 'blue'
        //       }
        //     ]
        //   },
        //   options: {
        //     aspectRatio:2.5
        //   }
          
        // })
        
      },
      error => this.errorMessage = <any>error) 
    } 

  getSubjects(str:any): void{
    //this.Subjects = [];
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.filteredsubjects + '?str=' + str + '&user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
      data => {
        this.Subjects=data;  console.log(this.Subjects);
    },
      error => this.errorMessage = <any>error);
  }

  getPackages(): void {
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userpackages + '?user_id='+this.user_id + '&private_key='+this.private_key).subscribe(
      data => {
        this.packages = data;

        if(this.packages.Message=='Unauthorised Access.!!'){
          this.router.navigate(['/loginform']); return;
        }

        this.eligibleqpids = this.packages.filter(function (item: any) {
          if (parseInt(item.subscribed) === 1 && parseInt(item.eligible) === 1 && parseInt(item.display) === 1) { return true; } else { return false; }
        });
        this.eligible_len = this.eligibleqpids.length;
        if(this.eligible_len>0){ 
          this.myForm.patchValue({qpid : this.eligibleqpids[0].qpid}); //this.myForm.get("qpid")?.value; console.log(this.qpid);
          this.getReport();
          this.subscribedqpids = "";
          for (let i=0 ; i<this.eligible_len ; i++) {
            if (this.subscribedqpids!="") { this.subscribedqpids = this.subscribedqpids + "," }
              this.subscribedqpids = this.subscribedqpids + this.eligibleqpids[i].qpid;
        }
        this.getSubjects("SubCategoryID IN (SELECT SubCategoryID FROM TBL_QPSUBJECTS WHERE QpID IN(" + this.subscribedqpids + "))");
        }
      },
      error => this.errorMessage = <any>error);
  }

}
