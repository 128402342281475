
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SpinnerService } from 'src/app/shard/widgets/progressbar/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AppComponent {
  title = 'Mr.Maid';

  constructor(updates: SwUpdate, public spinner: SpinnerService) {
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  }

  @HostListener('document:keydown', ['$event']) onkeydown(event: KeyboardEvent) {
    let res=true;
    if (event.ctrlKey) {res= false; event.preventDefault();}
    if (event.keyCode==123) {res= false;}
    return res;
  }

   
}
