	<div class="about" fxLayout="column">
		<div *ngIf="loading" style="display: flex;justify-content: center; align-items: center;">
			<mat-spinner></mat-spinner>
		</div>
		<div class="row text-center">
			<h3>{{Paper[0].examname}} (Practice Exam)</h3>
		</div>
		<div class="row" style="display: flex;justify-content: space-between;">
			<div>{{Paper[0].name}}</div>
			<div>Mark : {{Paper[0].totalquestions}}</div>
			<div>Time : {{Paper[0].qpminutes}} Minutes</div>
		</div>																										
		<div class="row" style="border-top: solid 1px; padding-top: 20px;">			
			
			<div id="dvQuestions" *ngIf="QuestionsLength>1 && isLoaded==true">
				<div class="qcard" *ngFor="let quest of Questions; let indexOfelement=index;">
					<div class="qst-line"  [innerHTML]="(indexOfelement + 1) + '. &nbsp;' + quest.qname" ></div>
					<div class="choice-line" id="IC1{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch1{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC1',$event)"
							value="1">
						<label class="opt-label" for="rdch1{{indexOfelement}}" [innerHTML]="quest.ch1"></label>
					</div>
					<div class="choice-line" id="IC2{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch2{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC2',$event)"
							value="2">
						<label class="opt-label" for="rdch2{{indexOfelement}}" [innerHTML]="quest.ch2"></label>
					</div>
					<div class="choice-line" id="IC3{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch3{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC3',$event)"
							value="3">
						<label class="opt-label" for="rdch3{{indexOfelement}}" [innerHTML]="quest.ch3"></label>
					</div>
					<div class="choice-line" id="IC4{{indexOfelement}}">
						<input class="opt-radio" type="radio" id="rdch4{{indexOfelement}}" [disabled]="getStatus(quest)"
							(click)="getScore(quest,indexOfelement,'IC4',$event)"
							value="4">
						<label class="opt-label" for="rdch4{{indexOfelement}}" [innerHTML]="quest.ch4"></label>
					</div>
				</div>
			</div>	
			<form [formGroup]="myForm" fxLayout="column" fxLayout.xs="column" style="padding-bottom: 15px;">
				<mat-form-field appearance="outline" fxFlex="90">
					<mat-label>If any comments enter here and Submit</mat-label>
					<textarea matInput type="text" cols="50" rows="2" maxlength="250" minlength="3" placeholder="Enter your Comments" formControlName="mes"></textarea>
					<mat-error *ngIf="myForm.controls['mes'].invalid &&
									(myForm.controls['mes'].dirty || myForm.controls['mes'].touched)">
						Comments required.
					</mat-error>
				 </mat-form-field>
				 <div fxFlex="60" style="padding-top: 1px;">
					<button mat-flat-button color="primary" (click)="validateContact()">Submit</button>
				  </div>
			</form>
			<div>
				<button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
				<button	mat-flat-button class="btnbluedark float-right" (click)="reloadQuestions()">Next</button>
				<button mat-flat-button class="btnbluedark" (click)="viewResult()">View Result</button>
			</div>
		</div>
	</div>