import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '../../../shard/service/dialog.service'
import { WindowRefService } from '../../../core/services/window-ref.service';

@Component({
  selector: 'app-otherpackages',
  templateUrl: './otherpackages.component.html',
  styleUrls: ['./otherpackages.component.css']
})
export class OtherpackagesComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  packagesexams: any;
  packagesother: any;
  eligiblepackages: any;
  subscribedpackages: any;
  subscribed_len: any;
  eligible_len: any;
  UserWalletAmount: any;
  dataSource: any;
  errorMessage: any;
  user_id: any;
  private_key:any;
  edulevelid:any;
  standardid:any;
  
  SpotExams:any;
  SpotExamsLength:any;
  exam_message:any;
  ResultAll:any;
  ResultAllLength:any;
  total:any;
  score:any;
  attempt:any;
  grade:any;
  usertype:any;
  attended:any;
  UserData:any;
  DummyData:any;
  ExamName:any;
  userdata_len:any;
  viewSubscription:any;
  viewOtherPackages:any;
  TotalPrice:any;
  GTotalPrice:any;
  jsondata:any;
  httpResponseMessage:any;

  arr: Array<{qp_id: number,qp_name:string, qp_amount: number, row_total:number, chk_box: boolean}> = []; 
  constructor(private winRef: WindowRefService,private location: Location,private dialogService: DialogService, private route: ActivatedRoute,private router: Router,private fb: FormBuilder, private _subscriptionService: SubscriptionService) { 

   }
  
  
  ngOnInit(): void {
  
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id =  sessionStorage.getItem('user_id');
    this.usertype = sessionStorage.getItem('usertype');
    this.private_key = sessionStorage.getItem('private_key');
    this.edulevelid =  sessionStorage.getItem('edulevelid');
    this.standardid = sessionStorage.getItem('standardid');

    this.myForm = this.fb.group({
      qpCheckBox: this.fb.array([]),
      qpChkBox:false,
      months:1,
      user_id:this.user_id,
      private_key:this.private_key
    });
    this.TotalPrice=0;
    this.UserWalletAmount=[{wallet_amount:0}];
    this.ResultAllLength=0;
    //console.log(this.user_id);
    this.getUserWalletAmount();
    this.getPackagesExams();
    this.getPackagesOther();
    //console.log(this.user_id,this.edulevelid,this.standardid);
    this.exam_message="";
    this.ExamName="Exam Name";
    this.userdata_len=0;
    this.viewSubscription=false;
    this.viewOtherPackages=true;
  }
    
  
  options:any;
  payrazor() {
    if (this.myForm.invalid || this.arr.length == 0) {
      this.errorNotification();
      return;
    }
    this.GTotalPrice = this.TotalPrice + (this.TotalPrice * 18 / 100);
    this.options = {
      "key": "rzp_live_JId0QsZ0cQuW7t", // Replace with your Razorpay key ID
      "amount": this.GTotalPrice * 100, // Amount is in currency subunits. Default currency is INR. Hence, 4000 refers to 40.00 INR
      "currency": "INR",
      "name": "OPPAM",
      "description": "Test Transaction",
      "image": "https://example.com/your_logo",
      "prefill": {
        "name": "Anil Kumar CV",
        "email": "anilchembally1973klm@gmail.com",
        "contact": "9847500773"
      },
      "notes": {
        "address": "Akshaya E Office, Kannur, Kerala"
      },
      "theme": {
        "color": "#3399cc"
      },
      // "handler": function (response: any){
      //   console.log(response.razorpay_payment_id);
      //   console.log(response.razorpay_order_id);
      //   console.log(response.razorpay_signature);
      // },
      // "modal": {
      //   "ondismiss": function(){
      //     console.log('Checkout form closed');
      //   }
      // }
    };

    this.options.handler = ((response:any) => {
      console.log('response ', response);
      console.log(response.razorpay_payment_id);
      console.log(response.razorpay_order_id);
      console.log(response.razorpay_signature);
      
      if (response.razorpay_payment_id){ 
        this.SubscribePackages(response.razorpay_payment_id);
      } else {
        const dataToComponent = {
          title: "Error.!!",
          message: "Transaction failed. Please verify your input and try again.!!",
          alertType: "Warning",
        }
        this.dialogService.openAlertDialog(dataToComponent);
      }
    });

    this.options.ondismiss = (() => {
          console.log('Transaction cancelled.');
    });

    const rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
    rzp1.open();
  }

   ///use this code
 dispWalletRecharge(): void{
  this.router.navigate(['/walletrecharge']);
}
dispFeedBack(): void{
  this.router.navigate(['/feedback']);
}
dispDashboard(): void{
  this.router.navigate(['/dashboard']);
}
dispProgressReport(): void{
  this.router.navigate(['/progressreport']);
}
dispWallet(): void{
  this.router.navigate(['/wallet']);
}

onCheckboxChange(pkg:any, qpid: number, subscribed:number, amt: number, $event:any): void { 
  if ($event.target.checked){
    pkg.subscribed=0; 
    subscribed+=amt;
    pkg.subscribed=subscribed;
    this.TotalPrice+=subscribed;
    this.arr.push({qp_id:qpid,qp_name:pkg.qpname, qp_amount:amt,row_total:subscribed,chk_box:this.myForm.controls.qpChkBox.value});
    //if(this.myForm.controls.months.value) {subscribed+=amt*this.myForm.controls.months.value;} 
    //console.log(this.arr);
  } else {
    for(let index=0; index<this.arr.length; index++){
        if(this.arr[index].qp_id==qpid) {
          this.arr.splice(index,1);
        }
    }; 
    this.TotalPrice-=pkg.subscribed;
    pkg.subscribed=0;
  }
  //console.log(this.arr); 
};  

successNotification(){
  const dataToComponent = {
    title: "Done.!!",
    message: "Successfully Saved.!!",
    alertType: "Success",
  }
  this.dialogService.openAlertDialog(dataToComponent);
}
errorNotification(){
  const dataToComponent = {
    title: "Error.!!",
    message: "Fill all required fields.!!",
    alertType: "Warning",
  }
  this.dialogService.openAlertDialog(dataToComponent);
}
walletErrorNotification(){
  const dataToComponent = {
    title: "Wallet Error.!!",
    message: "Your Wallet amount is less than Total amount...Please recharge Wallet!!",
    alertType: "Warning",
  }
  this.dialogService.openAlertDialog(dataToComponent);
}

getUserWalletAmount(): void { 
  this.UserWalletAmount[0].wallet_amount=0;
  this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userwallet + '?service_user_id=' + this.user_id + '&user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
  data => { 
    if(data) this.UserWalletAmount = data;
    if(this.UserWalletAmount.Message=='Unauthorised Access.!!') {
      this.router.navigate(['/loginform']); return;
    }
    //console.log(this.UserWalletAmount);
  },
  error => this.errorMessage = <any>error);
}

dispSubScribeNow(): void{
  this.router.navigate(['/subscribenow']); 
}
dispProfile(): void{
  this.router.navigate(['/profile']); 
}

goBack(): void{
  this.myForm.reset();
  this.arr=[];
  this.TotalPrice=0;
  this.viewSubscription=false;
  this.viewOtherPackages=true; 

}

getPackagesExams(): void {
  this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userpackagesexams + '?user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
    data => {
      this.packagesexams = data; console.log(data);
      /*this.eligiblepackages = this.packages.filter(function (item: any) {
        if (parseInt(item.subscribed) === 0 && parseInt(item.eligible) === 0 && parseInt(item.display) === 1) { return true; } else { return false; }
      });

      this.subscribedpackages = this.packages.filter(function (item: any) {
        if (parseInt(item.subscribed) === 1) { return true; } else { return false; }
      });
      this.subscribed_len = this.subscribedpackages.length;
      this.eligible_len = this.eligiblepackages.length; */
    },
    error => this.errorMessage = <any>error);
}

getPackagesOther(): void {
  this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.userpackagesother + '?user_id=' + this.user_id + '&private_key=' + this.private_key).subscribe(
    data => {
      this.packagesother = data; console.log(data);
      this.eligiblepackages = this.packagesother.filter(function (item: any) {
        if (parseInt(item.subscribed) === 0 && parseInt(item.eligible) === 0 && parseInt(item.display) === 1) { return true; } else { return false; }
      });

      this.subscribedpackages = this.packagesother.filter(function (item: any) {
        if (parseInt(item.subscribed) === 1) { return true; } else { return false; }
      });
      this.subscribed_len = this.subscribedpackages.length;
      this.eligible_len = this.eligiblepackages.length;
    },
    error => this.errorMessage = <any>error);
}


  //#/qpsubjectspecific/{{pkg.qpid}}/{{pkg.examid}}   #/practice/{{pkg.qpid}}   #/exam/{{pkg.qpid}}
  showPage(examname:any, examid:any, targetid:any): void {
    this.viewOtherPackages=false;
    this.viewSubscription=true;
    this.myForm.reset();
    this.arr=[];
    this.TotalPrice=0;
    this.ExamName=examname;
    this.DummyData = this.eligiblepackages;
    this.UserData = this.DummyData.filter(function (item: any) {
      if (parseInt(item.examid) === examid) { return true; } else { return false; }
    });
    this.userdata_len = this.UserData.length;
    /* if(targetid==1)
      this.router.navigate(['/qpsubjectspecific/'+qpid+'/'+examid]); 
    else if(targetid==2)  
      this.router.navigate(['/practice/'+qpid]); 
    else if(targetid==3) 
      this.router.navigate(['/exam/'+qpid]);  */ 
  }
  
  SubscribePackages(paymentId: any): void { 
    this.jsondata = {qpids:this.arr, totalprice: this.GTotalPrice, centersubscription:0, serviceuser_id:this.user_id, commissionpercent: 0, user_id:this.user_id, private_key: this.private_key, payment_id:paymentId, duration:1}
    if (this.myForm.invalid || this.arr.length==0) {
      this.errorNotification();
      return;
    }
    //if(this.UserWalletAmount[0].wallet_amount<this.TotalPrice) {
     // this.walletErrorNotification(); 
     // this.router.navigate(['/walletrecharge']); return;
     // return;
   // }
    //else {
    console.log(this.myForm); 
    //this.jsondata = JSON.stringify(this.myForm.value);
    //console.log(this.jsondata); 
    this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.addsubscription, this.jsondata).subscribe(
      data => {
        this.httpResponseMessage = data
        //console.log(this.httpResponseMessage);
        this.myForm.reset();
        this.successNotification();
        this.router.navigate(['/dashboard']); return;
      },
      error => this.errorMessage = <any>error)
    //} 
    //console.log(this.TotalPrice);
  };

}
