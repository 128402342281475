import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { SubscriptionService } from '../../shard/service/subscription.service'; 
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../shard/service/dialog.service'
@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit, AfterViewInit {
  errorMessage:any;
  jsondata:any;
  httpResponse:any;
  myForm: FormGroup = new FormGroup({});
  constructor(private dialogService: DialogService,private route: ActivatedRoute,private router: Router,private fb: FormBuilder, private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.myForm = this.fb.group({
      nam:[null, [Validators.required]],
      mob:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      sub:[null, [Validators.required]],
      mes:[null, [Validators.required]],
      typ:['0'],
      });
  }; 

  successNotification(){
    const dataToComponent = {
      title: "Success.!!",
      message: "Details submitted successfully...!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }
  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  validateContact(): void {
   if (this.myForm.invalid) {
     this.errorNotification();
       return;
   }
   this.jsondata = JSON.stringify(this.myForm.value); 
   console.log(this.jsondata);
   this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.contact, this.jsondata).subscribe(
   data => {
     this.httpResponse = data; console.log(data);
     if(this.httpResponse.isvalid==="Yes") 
     {
      this.successNotification();
      this.router.navigate(['/home']);
      return;
     }
     else
     {
       this.errorNotification();
     } 
   },
   error => this.errorMessage = <any>error); 
 };

  ngAfterViewInit(){
    AOS.init();
  }
}
