import { Directive, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[scroller]'
})
export class ScrollerDirective implements OnInit{
  
  backgroundColor:String = 'transparent';

  @HostListener('window:scroll', []) 
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 10) {
      console.log('You are 100px from the top to bottom');
    } else if (number > 20) {
        console.log('You are 500px from the top to bottom');
    }

  }

  @HostListener('click') clicking(){
    console.log('clicking...');
  }

  constructor() { }

  ngOnInit(){
     
  }

}