import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { DialogService } from '../../../shard/service/dialog.service'
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-regform',
  templateUrl: './regform.component.html',
  styleUrls: ['./regform.component.css']
})
export class RegformComponent implements OnInit {
  name:any;
  loginname:any;
  password:any;
  user_id:any;
  private_key:any;
  
  public showPassword: boolean = false;
  errorMessage:any;
  QualificationSubjects:any;
  subjectList:any;
  expenseAccountsList:any;
  EduLevels:any;
  ProfQuals:any;
  tablename:any;
  Qualifications:any;
  ProfQualifications:any;
  jsondata:any;
  src:any;
  Schools:any;
  httpResponseMessage:any;
  profqualiflag:any;
  filteredSubjects!: Observable<any[]>
  myForm: FormGroup = new FormGroup({});
  
  constructor(private route: ActivatedRoute,private router: Router, private dialogService: DialogService, private fb: FormBuilder, private _subscriptionService: SubscriptionService
    ) { }

  ngOnInit(): void {
    this.profqualiflag=false;
    this.createForm();
    this.src=0;
    this.src = this.route.snapshot.paramMap.get('src'); 
    //this.getSubjectList();
    this.getMaster('School Names');
	  this.getMaster('Education Level');
	  this.getMaster('Prof Qualification');
    this.filteredSubjects = this.myForm.controls['qualsubid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
       if (this.subjectList){ return name ? this._filterDebit(name as string) : this.subjectList.slice();}
      }),
    )
    console.log(this.filteredSubjects);
  }
 
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  createForm(){
    this.myForm = this.fb.group({
      name:[null, [Validators.required]],
      unm:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      pwd:[null, [Validators.required]],
      dob:[null, [Validators.required]],
      levelid:[null, [Validators.required]],
      talentexamflag:[0, [Validators.required]],
      qualid:[null, [Validators.required]],
      qualsubid:[null, [Validators.required]],
      profid:0,
      caste:0,
      enteredby:0,
      franchiseeid:0,
      student:0,
      job:0,
      centertype:0,
      pwd2:[null, [Validators.required]],
      });
  };

  private _filterDebit(name: string): any[] {
    const filterValue = name.toLowerCase(); 
    return this.subjectList.filter((debitacc: any) => debitacc.name.toLowerCase().includes(filterValue));
  }

  getMaster(tbl:any): void { 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT +  AppSettings.mastercombo + '?tblname='+tbl).subscribe(
    data => {
      if (tbl=='School Names') {
        this.Schools = data; 
      }
			if (tbl=='Education Level') {
				this.EduLevels = data;
			}
			if (tbl=='Prof Qualification') {
				this.ProfQuals = data;
			}
      if (tbl=='Prof Qualification') {
        this.ProfQualifications = data; 
    }  
    },
    error => this.errorMessage = <any>error);
  }
 
  getFilterMaster(tbl:any, id:any): void { 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.filtercombo + '?tblname='+tbl+'&id='+id).subscribe(
    data => {
      if (tbl=='Qualification') {
				this.Qualifications = data; 
			}
			if (tbl=='Qualification Subject') {
				this.subjectList = data;  
        if(this.myForm.controls['qualid'].value>=11)
         { this.profqualiflag=true; } else { this.profqualiflag=false; }
        console.log(this.subjectList);
			}
   },
    error => this.errorMessage = <any>error);
  }

  displaySubjects(id: any): string {
    if (!id) {return '';}
    if (!this.subjectList) {return '';}
      let name='';
      this.subjectList?.forEach((e: any) => {
        if (e.id===id){name= e.name;}
      });
      return name;
  }

  successNotification(){
    const dataToComponent = {
      title: "Done.!!",
      message: "Successfully Saved.!!",
      alertType: "Success",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  userExistNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Login ID (Mobile Number) already exist try another.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  passwordNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Password Mismatch.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  saveData(): void {
     if(this.myForm.controls['pwd'].value != this.myForm.controls['pwd2'].value) { this.passwordNotification(); return;}
     if (this.myForm.invalid) {
        this.errorNotification();
        return;
      }
      else {
      console.log(this.myForm); 
      this.jsondata = JSON.stringify(this.myForm.value); console.log(this.jsondata);
      this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.register, this.jsondata).subscribe(
        data => {
          this.httpResponseMessage = data
          if(this.httpResponseMessage[0].isvalid==="Yes")
            {
              sessionStorage.setItem('name',this.httpResponseMessage[0].fullname);
              sessionStorage.setItem('loginname',this.httpResponseMessage[0].loginname);
              sessionStorage.setItem('password',this.httpResponseMessage[0].password);
              sessionStorage.setItem('edulevelid',this.httpResponseMessage[0].edulevelid);
              sessionStorage.setItem('standardid',this.httpResponseMessage[0].standardid);
              sessionStorage.setItem('user_id',this.httpResponseMessage[0].userid);
              sessionStorage.setItem('private_key',this.httpResponseMessage[0].pkey);
              this.router.navigate(['/dashboard']); 
            }
          else
            this.userExistNotification();    
          //this.myForm.reset();
          //this.successNotification();
        },
        error => this.errorMessage = <any>error)
      } 
    } 

  /*
 saveData(val: number){
    if (this.myForm.valid) {
      this.isSubmitted=true;
      this.myForm.patchValue({trdate: formatDate(this.myForm.controls['trdate'].value ,"yyyy-MM-dd","en")});
      if (this.myForm.controls['istaxable'].value==false){
        this.myForm.patchValue({grossamount: 0,taxamount: 0 });
      }
      if (this.record_id===0){
        this.httpService.post(environment.accountsUrl + "expense", this.myForm.getRawValue()).subscribe((data) => {
          this.viewNotification();
          this.resetPage(val, data);
        })
      } else {
        this.httpService.put(environment.accountsUrl + "expense", this.myForm.getRawValue()).subscribe((data) => {
          this.viewNotification();
          this.resetPage(val,data);
        })
      }
      this.isSubmitted=false;
    }
  }

  viewNotification(){
    const dataToComponent = {
      title: "Done.!!",
      message: "Successfully Updated.!!",
      alertType: "Success"
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }
  */
  
}
