import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { BlocksModule } from './blocks/blocks.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './blocks/root/app.component';
import { TitleService } from './shard/widgets/title.service';
import { ShardModule } from './shard/shard.module';
import { UserModule } from './modules/user/user.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ExamsModule } from './modules/exams/exams.module';
import { ContactPageComponent } from './modules/contact-page/contact-page.component';
import { AboutPageComponent } from './modules/about-page/about-page.component';
import { FaqPageComponent } from './modules/faq-page/faq-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginformComponent } from './modules/loginform/loginform.component';
import { OtherlinksComponent } from './modules/otherlinks/otherlinks.component';
import { PricingComponent } from './modules/pricing/pricing.component';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { TermsComponent } from './modules/terms/terms.component';
import { WindowRefService } from './core/services/window-ref.service';




@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ShardModule,
    AppRoutingModule,
    DashboardModule,
    UserModule,
    ExamsModule,
    CoreModule,
    BlocksModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],

  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers:[TitleService,WindowRefService],
  bootstrap: [AppComponent],
  declarations: [ContactPageComponent, AboutPageComponent, FaqPageComponent, LoginformComponent, OtherlinksComponent, PricingComponent, PrivacypolicyComponent, TermsComponent]
})
export class AppModule { }
