import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-to-bottom',
  template: `
    <button *ngIf="buttonFixed" mat-fab color="primary" class="top-to-bottom animate__animated" (click)="scrollToTop()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  `,
  styles: [
`  .top-to-bottom{
     position:fixed;
     bottom: 17px;
     right: 13px;
     z-index: 100;
     background-color: var(--theme-color);
     display:block
  }
  `
  ]
})
export class ScrollToBottomComponent implements OnInit {

  buttonFixed:boolean = false;

  constructor() { 
  }

  ngOnInit(): void {
  }

  // animate the button after scrolling 
  @HostListener('window:scroll', []) 
  onWindowScroll() {
      var topToBottom = document.querySelectorAll('.top-to-bottom')[0];
      const pageOffeset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      console.log(pageOffeset)
      if (pageOffeset > 100) {
        topToBottom.classList.add('animate__fadeInRight');
        this.buttonFixed = true
        
      } else{
        this.buttonFixed = false
        topToBottom.classList.remove('animate__fadeInRight');
      }
    
  }

  // scroll to top of the window
  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
  }

}
