<div class="footer">
    <div class="pt-marg-30" fxLayout="row wrap" fxLayout.xs="row wrap" fxLayoutAlign="space-evenly start">
       <div fxFlex="20" fxFlex.xs="50">
        <ul class="footer-li">
          <li><a routerLink="/home">Home</a></li>
          <li><a routerLink="/dashboard">Dashboard</a></li>
          <li><a routerLink="/about">About</a></li>
          <li><a routerLink="/pricing">Our Pricing</a></li>
          <li><a routerLink="/privacypolicy">Privacy and Refund Policy</a></li>
        </ul>
      </div>

      <div fxFlex="20" fxFlex.xs="50">
        <ul class="footer-li">
          <li><a routerLink="/features">Features</a></li>
          <li><a routerLink="/otherlinks">Other Links</a></li>
          <li><a routerLink="/contact">Contact US</a></li>
          <li><a routerLink="/terms">Terms & Conditions</a></li>
        </ul>
      </div>
    </div>
    <br>
    <div class="copyright">Copyright oppam.in All rights reserved.</div> 
</div>