import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionService } from '../../../shard/service/subscription.service';
import { AppSettings } from 'src/app/app.constant';
import { DialogService } from '../../../shard/service/dialog.service'
import { empty } from 'rxjs';
import { isEmptyExpression } from '@angular/compiler';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {
  loading:boolean=true;
  user_id:any;
  private_key:any;
  Paper:any;
  qp_id:any;
  attemptid:any;
  Questions:any;
  QuestionsLength:any;
  isLoaded:any;
  errorMessage:any;
  true_answer:any;
  false_answer:any;
  AssessmentData:any;
  jsondata:any;
  httpResponseMessage:any;
  message:any;
  promise:any;
  mymessage:any;
  public timerInterval: any;
  countTimer:any;
  constructor(private dialogService: DialogService,private route: ActivatedRoute,private router: Router, private location: Location, private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.mymessage='';
    this.user_id = sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.qp_id = this.route.snapshot.paramMap.get('qpid'); 
    this.countTimer=0;
    this.getQpDetails();
    this.loadQuestions(); 
    this.true_answer=0;
    this.false_answer=0;
    this.Paper = [{examname:"",countTimer:10000}];
    this.AssessmentData = [{user_id:"",private_key:"",qpid:"",qid:"",subcatid:"",specificid:"",qans:"",attempttype:"",attemptid:""}];
  }

  //timer starts **********************************
  start() {
    this.timer(this.Paper[0].countTimer);
  }
  stop() {
    clearInterval(this.timerInterval);
  }

  timer(minute:number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.countTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        //console.log('finished');
        this.stop();
        this.router.navigate(['/viewresult/'+this.attemptid[0].id +'/'+this.qp_id]); 
      }
    }, 1000);
  }
  //timer ends   ********************************************

  goBack(): void {
    this.stop();
    this.location.back();
  }

  reloadQuestions(): void{
    window.location.reload();
  }

  viewResult(): void{
    this.stop();
    this.router.navigate(['/viewresult/'+this.attemptid[0].id +'/'+this.qp_id]); 
  }

  getQpDetails(): void {
		this.Paper = []; 
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.qpdetails + '?qpid='+this.qp_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
    data => {
      this.Paper = data;// console.log(this.Paper);

      if(this.Paper.Message=='Unauthorised Access.!!') {
        this.router.navigate(['/loginform']); 
        this.Paper[0].countTimer = 0;
        return;
      }
      else {
        //console.log(this.Paper);
        this.Paper[0].countTimer = this.Paper[0].qpminutes;
        this.start();
      }    
    },
    error => this.errorMessage = <any>error);
  }
 
  errorNotification(){
    const dataToComponent = {
      title: "Access Denied!",
      message: this.message,
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  loadQuestions():void {
		this.Questions = [];
    this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.exam + '?qp_id='+this.qp_id+'&user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
      data => {
        let HttpResponse=""; 
        this.Questions = data; 
        if(this.Questions[0].Question){ 
          HttpResponse=this.Questions[0].Question;
          if(HttpResponse.substring(0,26)=='Maximum Exam Count Reached'){
            this.message=this.Questions[0].Question;
            this.errorNotification();
            this.router.navigate(['/subscribenow']);
          }
        }  
        if(this.Questions[0].Question){ 
          if (this.Questions[0].Question=='Your Subscription Expired' ){
            this.message="Your Subscription Expired. Please recharge your wallet and subscribe for this examination.!!";
            this.errorNotification();
            this.router.navigate(['/subscribenow']);
          }
       }  
         this.QuestionsLength=this.Questions.length;

        this.Questions.forEach((e: any) => {
          if (e.name){
            if (e.qname?.substring(e.qname.length - 4, e.qname.length) == '</p>') {
              e.qname = e.qname.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch1){
            if (e.ch1.substring(e.ch1.length - 4, e.ch1.length) == '</p>') {
              e.ch1 = e.ch1.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch2){
            if (e.ch2.substring(e.ch2.length - 4, e.ch2.length) == '</p>') {
              e.ch2 = e.ch2.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch3){
            if (e.ch3.substring(e.ch3.length - 4, e.ch3.length) == '</p>') {
              e.ch3 = e.ch3.replace("<p>", "").slice(0,-4); 
            }
          }
          if (e.ch4){
            if (e.ch4.substring(e.ch4.length - 4, e.ch4.length) == '</p>') {
              e.ch4 = e.ch4.replace("<p>", "").slice(0,-4); 
            }
          }
        }); 

        this.loading=false;
        this.isLoaded=true;
        //start  get AttemptID
          this._subscriptionService.getData(AppSettings.API_ENDPOINT + AppSettings.attemptid + '?user_id='+this.user_id+'&private_key='+this.private_key).subscribe(
          data => {
            this.attemptid = data;
          },
          error => this.errorMessage = <any>error);
        //end  get AttemptID
      },
      error => this.errorMessage = <any>error);
	}

  getStatus(obj:any): boolean { 
		if(obj.stat==0) 
      return false
    else
      return true;
    };    

  getScore(question:any,index:any,$event:any): void { 
    question.stat = 1; //console.log(question);
    let answer=0;
    question.stat = 1;
    if ($event.target.value==question.qans){ 
        answer=1;
        this.true_answer++; 
    }
    else
    {
      this.false_answer++;
    }

    this.AssessmentData = {
        'user_id':this.user_id,
        'private_key':this.private_key,
        'qpid':this.qp_id,
        'qid':question.qid,
        'subcatid':question.sub_cat_id,
        'specificid':question.specific_id,
        'qans':answer,
        'attempttype':3,
        'attemptid':this.attemptid[0].id
    };

    this.jsondata = this.AssessmentData ; 
    //this.jsondata = JSON.stringify(this.AssessmentData.value); console.log(this.jsondata);
      this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.assessment, this.AssessmentData).subscribe(
        data => {
          this.httpResponseMessage = data
        },
        error => this.errorMessage = <any>error)
  } 
}

function $interval(checkTime: () => void, arg1: number): any {
  throw new Error('Function not implemented.');
}

