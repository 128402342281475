<br><br><br><br><br>
<div>
    <div class="about animate_animated animatefadeIn animate_slower" fxLayout="row wrap" fxLayout.xs="column"
        fxLayoutAlign="center">
        <div fxFlex="80">
            <h2>Our Features</h2>
           
            <UL>
                <li>NMMS, NTSE, Navodaya</li>
                <li>PSC</li>
                <li>UPSC</li>
                <li>Bank Recruitment</li>
                <li>Railway Recruitment</li>
                <li>Staff Selection</li>
                <li>Entrance UG</li>
                <li>Entrance PG</li>
                <li>Common Entrance Exam</li>
                <li>KTET</li>
                <li>UGC</li>
                <li>SET</li>
                <li>Army, Navy, Airforce</li>
                <li>Army Public School</li>
                <li>Navodaya</li>
                <li>Kendriya Vidayalaya</li>
                <li>KEAM, NEET, BSc Nursing</li>
                <li>High Court Assistant</li>
                <li>Department Test</li>
                <li>Driving Learners Test</li>
             </UL>  
            
          

           <br><br>
        </div>
      
</div>
</div>