<br><br><br><br><br>
<div style="padding:20px;">
    <h1>About Our Company</h1>
    <p>We are a passionate team dedicated to creating innovative solutions for our clients.
    We provide quality educational tools through our websit for students and job seekers in a low budjet
    </p>
    <p>Our mission is to...</p>

    <h2>Our Values</h2>
    <ul>
        <li>Quality</li>
        <li>Integrity</li>
        <li>Customer Satisfaction</li>
    </ul>

    <h2>Contact Us</h2>
    <p>Have questions? Reach out to us at <a href="mailto:info@oppam.in">info@oppam.in</a>.</p>
    <div >
        <div fxFlex="80">
            <h2>About OPPAM</h2>
            
            <p>
            Oppam
            എല്ലാ പരീക്ഷകളും, പരീക്ഷ ചോദ്യങ്ങൾ, സർക്കാർ അപേക്ഷകൾ, തൊഴിൽ അവസരങ്ങൾ വിദേശത്തും സോദേശത്തും, സർക്കാർ പദ്ധതികൾ, സൗജന്യമായി
            നിങ്ങളുടെ വിരൽ തുമ്പിൽ ലഭിക്കുവാൻ ഇവിടെ രജിസ്റ്റർ ചെയ്യുക</p>
            
          

           <br><br>
        </div>
      
</div>

</div>

 
