import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../../shard/service/subscription.service'; 
import { AppSettings } from 'src/app/app.constant';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../../shard/service/dialog.service'


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  user_id:any;
  private_key:any;
  jsondata:any;
  httpResponseMessage:any;
  errorMessage:any;
  optionList: { id: number, name: string }[] = [{"id": 1, "name": "Web site" },{ "id": 2, "name": "Loading Speed" }, { "id": 3, "name": "Questions" }, { "id": 4, "name": "Navigation" }, { "id": 5, "name": "Accuracy"}];
  myForm: FormGroup = new FormGroup({});

  constructor(private dialogService: DialogService,private route: ActivatedRoute,private router: Router,private fb: FormBuilder, private _subscriptionService: SubscriptionService) { }
  ngOnInit(): void {
    if(sessionStorage.getItem('user_id')==null) {
      this.router.navigate(['/home']); return;
    }
    this.user_id =  sessionStorage.getItem('user_id');
    this.private_key = sessionStorage.getItem('private_key');
    this.createForm();
  }
  createForm(){
    this.myForm = this.fb.group({
      user_id:[this.user_id],
      private_key:[this.private_key],
      a1:[null, [Validators.required]],
      a2:[null, [Validators.required]],
      a3:[null, [Validators.required]],
      a4:[null, [Validators.required]],
      a5:[null, [Validators.required]],
      commentoptions:[null],
      usercomment:[null]
      });
  };

  successNotification(){
    const dataToComponent = {
      title: "Done.!!",
      message: "Successfully Saved.!!",
      alertType: "Success",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  errorNotification(){
    const dataToComponent = {
      title: "Error.!!",
      message: "Fill all required fields.!!",
      alertType: "Warning",
    }
    this.dialogService.openAlertDialog(dataToComponent);
  }

  validateFeedBack(): void{
      if (this.myForm.invalid) {
     this.errorNotification();
     return;
   }
   if(this.myForm.controls['commentoptions'].value && !this.myForm.controls['usercomment'].value){
        this.errorNotification();
        return;
    }
   else {
   console.log(this.myForm); 
   //this.myForm.controls['commentoptions']=this.myForm.controls['commentoptions'].value.join(',');
   this.jsondata = JSON.stringify(this.myForm.value); console.log(this.jsondata);
   //console.log((this.myForm.controls['commentoptions'].value).join(',')); 
   this._subscriptionService.postData(AppSettings.API_ENDPOINT + AppSettings.feedback, this.jsondata).subscribe(
     data => {
       this.httpResponseMessage = data
       console.log(this.httpResponseMessage);
       //this.myForm.reset();
       this.successNotification();
       this.router.navigateByUrl("dashboard");
     },
     error => this.errorMessage = <any>error)
   } 
  };
}
