<div style="margin:20px; margin-top:80px;">
    <h2>Privacy policy</h2><br>
    <div>
        <article>
            <div>
                <h5>Table of contents :</h5>
                <br>
                <ol type="A">
                    <li><a href="#general">
                            <p>General Privacy Terms </p>
                        </a></li>
                    <li><a href="#applicability">
                            <p>Applicability</p>
                        </a></li>
                    <li><a href="#access">
                            <p>Access</p>
                        </a></li>
                    <li><a href="#use_of_platform">
                            <p>Use of the Platform/Services by Children </p>
                        </a></li>
                    <li><a href="#controllers">
                            <p>Controllers</p>
                        </a></li>
                    <li><a href="#personal_information">
                            <p>Personal Information</p>
                        </a></li>
                    <li><a href="#info_collect">
                            <p>Information We Collect</p>
                        </a></li>
                    <li><a href="#basis_of_collection">
                            <p>Basis of Collection and Processing of Your Personal Information</p>
                        </a></li>
                    <li><a href="#use_share_info">
                            <p>How we Use and Share the Information Collected</p>
                        </a></li>
                    <li><a href="#cross_border_data">
                            <p>Cross-Border Data Transfer</p>
                        </a></li>
                    <li><a href="#duration_info">
                            <p>Duration for which your Information is Stored</p>
                        </a></li>
                    <li><a href="#your_choice">
                            <p>Your Choices</p>
                        </a></li>
                    <li><a href="#your_rights">
                            <p>Your Rights</p>
                        </a></li>
                    <li><a href="#info_sec">
                            <p>Information Security</p>
                        </a></li>
                    <li><a href="#personal_comm">
                            <p>Promotional Communications</p>
                        </a></li>
                    <li><a href="#interest_based_ads">
                            <p>Interest-Based Ads</p>
                        </a></li>
                    <li><a href="#mod-privacy-policy">
                            <p>Modification to Privacy Policy</p>
                        </a></li>
                    <li><a href="#grievances">
                            <p>Privacy Grievances</p>
                        </a></li>
                    <li><a href="#country_specific_privacy_terms">
                            <p>Country Specific Additional Privacy Terms</p>
                        </a></li>
                </ol>
            </div><a name="general">
                <h3>A. General</h3>
            </a>
            <p>In addition to our <a role="" href="/terms">Terms and conditions</a> Service, Oppam respects your privacy and is committed to protecting it. This Privacy Policy (the “Policy”) explains the types of information collected by Oppam when you use the Platform (as defined in <a role="" href="/terms">Terms and conditions</a>) that references this Policy, how we collect, use, share and store such information collected and also explains the rationale for collection of such information, the privacy rights and choices you have regarding your information submitted to us when you use the Services.</p><br>
            <p>For ease of reference, use of the terms “Oppam”, “we”, “us, and/or “our” refer to Oppam Private Limited – a company incorporated in India and all of its affiliates which have license to host the Platform and offer Services. Similarly, use of the terms “you”, “yours” and/or “User(s)” refer to all users of the Platform and includes all Learners and Content Providers <i>(as more particularly defined under our <a role="" href="/terms">Terms and conditions</a>)</i>.</p><br>
            <p>The Services are governed by this Policy, <a role="" href="/terms">Terms and conditions</a>, and any other rules, policies or guidelines published on the Platform as applicable to you. Please read this Policy carefully prior to accessing our Platform and using the Services. By accessing and using the Platform, providing your Personal Information (defined below), or by otherwise signalling your agreement when the option is presented to you, you consent to the collection, use, disclosure, sharing and storing of information described in this Policy, Terms of Service and any other rules, policies or guidelines published on the Platform as applicable to you (collectively referred to as the <b>“Platform Terms”</b>), and Oppam disclaims all the liabilities arising therefrom. If you have inadvertently submitted any Personal Information to Oppam prior to reading this Policy statements set out herein, or you do not agree with the way your Personal Information is collected, stored, or used, then you may access, modify and/or delete your Personal Information in accordance with this Policy (refer to the sections about <b>Your Choices</b> and <b>Your Rights</b>).</p><br>
            <p>If any information you have provided or uploaded on the Platform violates the Platform Terms, Oppam may be required to delete such information upon informing you of the same and revoke your access to the Platform if required.</p><br>
            <p>Capitalized terms used but not defined in this Policy can be found in our <a role="" href="/terms">Terms and conditions</a>.</p><br>
            <p>If you have any questions about this Policy, please contact us at<a href="mailto:info@oppam.in">info@oppam.in</a>.</p><br><br><a name="applicability">
                <h3>B. Applicability</h3>
            </a>
            <p>This Policy applies to all Users of the Platform.</p><br>
            <p>Oppam owns and/or manages several platforms (other than the Platform) that offer a range of services including services related to technology solutions in the space of education, either by itself or through its affiliates and subsidiaries (<b>“Oppam Group Platforms”</b>). Each of the Oppam Group Platforms have published their own privacy policies. Accordingly, this Policy does not apply to information collected through the Oppam Group Platforms and only applies to the collection of your information through the Platform. Please visit the relevant Oppam Group Platform to know the privacy practices undertaken by them.</p><br>
            <p>Oppam has taken reasonable precautions as per applicable laws and implemented industry standards to treat Personal Information as confidential and to protect it from unauthorized access, improper use or disclosure, modification and unlawful destruction or accidental loss of the Personal Information.</p><br><br><a name="access">
                <h3>C. Access</h3>
            </a>
            <p>You may be allowed to access and view the Platform as a guest and without creating an account on the Platform or providing any Personal Information; Oppam does not validate or takes no responsibility towards information, if any, provided by you as a guest, except as otherwise required under any law, regulation, or an order of competent authority. However, to have access to all the features and benefits on our Platform, you are required to first create an account on our Platform. To create an account, you are required to provide certain Personal Information as may be required during the time of registration and all other information requested on the registration page, including the ability to receive promotional offers from Oppam, is optional. Oppam may, in future, include other optional requests for information from you to help Oppam to customize the Platform to deliver personalized information to you. Oppam may keep records of telephone calls or emails received from or made by you for making enquiries, feedback, or other purposes for the purpose of rendering Services effectively and efficiently.</p><br><br><a name="use_of_platform">
                <h3>D. Use of the Platform/Services by Children</h3>
            </a>
            <p>As stated in our <a role="" href="/terms">Terms and conditions</a>, to register on the Platform you must meet the ‘Age Requirements’ (as defined in our <a role="" href="/terms">Terms and conditions</a>). If you are a <b>“Minor”</b> or <b>“Child”</b> i.e., an individual who does not meet the Age Requirements, then you may not register on the Platform, and only your Parent (<i>defined below</i>) can register on the Platform on your behalf, agree to all Platform Terms and enable access to you under their guidance and supervision.</p><br>
            <p>While some of our Services may require collection of a Child’s Personal Information, we do not knowingly collect such Personal Information from a Child and assume that information has been provided with consent of the Parent. If you are a Parent and you are aware that your Child has provided us with any Personal Information without your consent, please contact us at<a href="mailto:info@oppam.in">info@oppam.in</a>. If we become aware that we have collected Personal Information from a Child in the absence of consent of the Parent, we will take steps to remove such information from our servers.</p><br>
            <p>The information in the relevant parts of this notice applies to Children as well as adults. If your Child faces any form of abuse or harassment while availing our Services, you must write to us at<a href="mailto:info@oppam.in">info@oppam.in</a>, so that necessary actions could be considered.</p><br><br><a name="controllers">
                <h3>E. Controllers</h3>
            </a>
            <p>Oppam is the controller of Personal Information that it collects and processes in connection with the use of the Platform and the provision of the Services on the Platform. The kind of Personal Information we collect in connection with such use is detailed below.</p><br><br><a name="personal_information">
                <h3>F. Personal Information</h3>
            </a>
            <p><b>“Personal Information”</b> shall mean the information which identifies a User i.e., first and last name, identification number, email address, age, gender, location, photograph and/or phone number provided at the time of registration or any time thereafter on the Platform.</p><br>
            <p><b>“Sensitive Personal Information”</b> shall include (i) passwords and financial data (except the truncated last four digits of credit/debit card), (ii) health data, (iii) official identifier (such as biometric data, aadhar number, social security number, driver’s license, passport, etc.,), (iv) information about sexual life, sexual identifier, race, ethnicity, political or religious belief or affiliation, (v) account details and passwords, or (vi) other data/information categorized as ‘sensitive personal data’ or ‘special categories of data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, General Data Protection Regulation (GDPR) and / or the California Consumer Privacy Act (CCPA) (<b>“Data Protection Laws”</b>) and in context of this Policy or other equivalent / similar legislations.</p><br>
            <p>Usage of the term <b>‘Personal Information’</b> shall include <b>‘Sensitive Personal Information’</b> as may be applicable to the context of its usage.</p><br>
            <p>We request you to not provide Oppam with any Personal Information unless specifically requested by us. In the event you share with Oppam any Personal Information without us having specifically requested for the same, then we bear no liability in respect of such Personal Information provided by you.</p><br><br><a name="info_collect">
                <h3>G. Information We Collect</h3>
            </a>
            <p>We only collect information about you if we have a reason to do so — for example, to provide our Services on the Platform, to communicate with you, or to make our Services better.</p><br>
            <p>We collect this information from the following sources:</p><br>
            <p>
                </p><li><i>Information we collect from You:</i>
                    <ol type="i">
                        <li><b>Basic account information:</b> In order to access certain features of the Platform, you will need to create an account and register with us. We ask for basic information which may include your name, an email address, state of residence country, and password, along with a username and phone/mobile number.</li>
                        <li><b>Know Your Customer ('KYC') information:</b> If you are an Content Provider registered with us, then, we also collect the KYC information, which may include information pertaining to your PAN number, aadhaar number, driver’s license, passport, your entity details such as name, MOU/AOA, certificate of incorporation, list of directors/partners, social security number etc., along with the relevant documents. We collect this information and documents from you to complete our verification process including pursuant to any other arrangement / agreement executed with you.</li>
                        <li><b>Public profile information:</b> If you are an Content Provider, we may also collect certain additional information from you to enable creation of your public profile, if any, on the Platform to help Learners and other Users know you better. We do not share your KYC information, documents or any such Personal Information on these public profiles. Your public profile will only contain Personal Information that are required for Learners or Users to know you (as applicable) viz., your username, a brief description of any content you have made available on the Platform, your educational qualifications, your past associations, your photo, and such similar information that may enable a Learner or other User to know you better. The information in your public profile will be based on the information you provide to us or as updated by you on such applicable sections.</li>
                        <li><b>Information when you communicate with us:</b> When you write to us with a question or to ask for help, we will keep that correspondence, and the email address, for future reference; this may also include any phone/ mobile numbers if you have provided us the same as part of your communication either in writing (emails included), over a phone call or otherwise. When you browse pages on our Platform, we will track that for statistical purposes which may be to improve the Platform and the Services. You may also provide us your Personal Information or other information when you respond to surveys, enter any form of contests, tests, events, competitions, webinars, etc., hosted by Oppam, either on the Platform or otherwise, or when you otherwise communicate with us via form, e-form, email, phone, or otherwise, we store a copy of our communications (including any call recordings or emails, if any, as permitted by applicable law).</li>
                        <li><b>Information related to location:</b> You may also choose to provide location related information, including but not limited to access to GPS, which will enable us, with your consent, to offer customized offerings for specific services where location data is relevant and/or applicable such as informing you whether services on the Platform that you have expressed interest in may be availed of at or near your location. </li>
                    </ol>
                </li><br>
                <li><i>Information we collect automatically:</i>
                    <ol type="i">
                        <li><b>Device and Log information:</b> When you access our Platform, we collect information that web browsers, mobile devices, and servers typically make available, including the browser type, IP address, unique device identifiers, language preference, referring site, the date and time of access, operating system, and mobile network information. We collect log information when you use our Platform — for example, when you create or make changes to your account information on the Platform.</li>
                        <li><b>Usage information:</b> We collect information about your usage of our Platform. We also collect information about what happens when you use our Platform (e.g., page views, support document searches, features enabled for your account, interactions with other parts of our Services) along with information about your Supported Device (e.g., screen size, name of cellular network, and mobile device manufacturer). We use this information to provide our Platform to you, get insights on how people use our Platform so that we can make our Platform better, and understand and make predictions about User retention.</li>
                        <li><b>Location information:</b> We may determine the approximate location of your Supported Device from your Internet Protocol (IP) address. We may collect and use this information to calculate how many people visit from certain geographic regions or to improve our Platform Services.</li>
                        <li><b>Information from cookies &amp; other technologies:</b> We may collect information about you through the use of cookies and other similar technologies. A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. For more information on our use of cookies and similar technologies, please refer to our <a role="" href="/cookie-policy">Cookie Policy</a>.</li>
                    </ol>
                </li><br>
                <li><i>Information we collect from other sources:</i>
                    <ul>We might receive and collect information about you from other sources in the course of their services to us or from a publicly available sources, as permitted by law, which we may combine with other information we receive from or about you. For example, we may receive information about you from a social media site or a Google service if you connect to the Services through that site or if you use the Google sign-in.</ul>
                </li>
            <p></p><br><br><a name="basis_of_collection">
                <h3>H. Basis of Collection and Processing of Your Personal Information</h3>
            </a>
            <p>
                </p><li><i>Basis for collection:</i>
                    <ul>We collect and process your Personal Information based on the following legal parameters depending upon the nature of Personal Information and the purposes for which it is processed:</ul><br>
                    <ol type="i">
                        <li><b>Consent:</b> We rely on your consent to process your Personal Information in certain situations. If we require your consent to collect and process certain Personal Information, as per the requirements under the applicable Data Protection Laws, your consent is sought at the time of collection of your Personal Information and such processing will be performed where consent is secured.</li>
                        <li><b>Compliance with a legal obligation:</b> Your Personal Information may be processed by us, to the extent that such processing is necessary to comply with a legal obligation.</li>
                    </ol>
                </li>
            <p></p><br>
            <p>
                </p><li><i>Processing of your Personal Information: </i>
                    <ul>We may process your Personal Information in connection with any of the purposes and uses set out in this Policy on one or more of the following legal grounds:</ul><br>
                    <ol type="i">
                        <li>Because it is necessary to perform the Services you have requested or to comply with your instructions or other contractual obligations between you and us;</li>
                        <li>To comply with our legal obligations as well as to keep records of our compliance processes;</li>
                        <li>Because our legitimate interests, or those of a third-party recipient of your Personal Information, make the processing necessary, provided those interests are not overridden by your interests or fundamental rights and freedoms;</li>
                        <li>Because you have chosen to publish or display your Personal Information on a public area of the Platform, such as a comment area;</li>
                        <li>Because it is necessary to protect your vital interests;</li>
                        <li>Because it is necessary in the public interest; or</li>
                        <li>Because you have expressly given us your consent to process your Personal Information in a particular manner.</li>
                    </ol>
                </li>
            <p></p><br>
            <p>We do not use Personal Information for making any automated decisions affecting or creating profiles other than what is described in this Policy.</p><br>
            <p>Where the processing of your Personal Information is based on your consent, you have the right to withdraw your consent at any point in time in accordance with this Policy. Please note that should the withdrawal of consent result in us not being able to continue offering our Services to you, we reserve the right to withdraw or cease from offering our Services to you upon your consent withdrawal. You may withdraw consent by contacting us with a written request to the contact details provided in the <a role="" href="/privacy#grievances">‘Grievances’</a> section below. Upon receipt of your request to withdraw your consent, the consequences of withdrawal may be communicated to you. Upon your agreement to the same, your request for withdrawal will be processed.</p><br><br><a name="use_share_info">
                <h3>I. How we Use and Share the Information Collected</h3>
            </a>
            <p>
                </p><li>We use/process your information in the following manner:<ol type="i">
                        <li><b>To provide Services on our Platform:</b> We use your information as collected by us to allow you to access the Platform and the Services offered therein, including without limitation to set-up and maintain your account, provide customer service, fulfil purchases through the Platform, verify User information and to resolve any glitches with our Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.</li>
                        <li><b>To improve our Platform and maintain safety:</b> We use your information to improve and customize the Platform and Services offered by us, including providing automatic updates to newer versions of our Platform and creating new features based on the Platform usage analysis. Further, we also use your information to prevent, detect, investigate, and take measures against criminal activity, fraud, misuse of or damage to our Platform or network, and other threats and violations to Oppam’s or a third party's rights and property, or the safety of Oppam, its users, or others. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.</li>
                        <li><b>To market our Platform and communicate with You:</b> We will use your information to develop a more targeted marketing of our Platform, to communicate with you about our offers, new products, services or even receive your feedback on the Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between you and us.</li>
                        <li><b>To establish, exercise, or defend legal claims:</b> We may process any Personal Information identified in this Policy when necessary for establishing, exercising, or defending legal claims, whether in court, administrative, or other proceedings. The legal basis for this processing is our legitimate interest in the protection and assertion of our legal rights, your legal rights, and the legal rights of others.</li>
                        <li><b>To manage risk and obtain professional advice:</b>We may process any of the Personal Information identified in this Policy to manage risk or obtain professional advice. The legal basis for this processing is our legitimate interest in the proper protection of our business and Platform</li>
                        <li><b>Consent:</b>We may otherwise use your information with your consent or at your direction.</li>
                        <li><b>To Better Understand Our Users:</b>We may use information we gather to determine which areas of the Services are most frequently visited to understand how to enhance the Services.</li>
                    </ol>
                </li><br>
                <li>We share the information collected as per terms of this Policy only in the manner specified hereinbelow. We do not sell or otherwise disclose Personal Information we collect about you for monetary or other valuable consideration. Further, only authorized representatives of Oppam and on a need-to-know basis use any information received from you and as consented by you. In the event of any identified unauthorized use or disclosure of information or upon your complaint as stated under the ‘Grievances’ section below, we will investigate any such complaint and take the appropriate action as per the applicable Data Protection Laws.<ol type="i">
                        <li><b>Affiliates and Subsidiaries:</b> We may disclose information about you to our affiliates, subsidiaries and other businesses under the same control and ownership, and their respective officers, directors, employees, accountants, attorneys, or agents, who need the information to help us provide the Services or process the information on our behalf. or to market similar services/offerings to you. We require our affiliate, subsidiaries and other businesses under the same control and ownership to follow this Privacy Policy for any Personal Information that we share with them. Further, if you have availed any Service that is offered by Oppam in collaboration with one or more Oppam Group Platforms, or if you have availed any service similar to a Service offered by a Group Platform, then we may share your Personal Information with the relevant Oppam Group Platform, who may also communicate with you regarding certain other products/services offered by them separately via email, phone, SMS and/or such other mode of communication; you will always have an option to opt out of receiving some or all of such promotional communications from an Oppam Group Platform through the unsubscribe link in the email or such other option that may be communicated to you.  .</li>
                        <li><b>Third-party vendors/service providers including integrated services on the Platform: </b> We may share information about you with third-party vendors or service providers (including consultants, payment processors, and other service providers and integrated services) who need the information to provide their support services to us or you, or to provide their services to you on our behalf either directly or through the Platform. These services may include providing customer support, performing business and sales analysis, supporting our website functionality, facilitating payment processing, and supporting contests, surveys, and other features offered on our Platform. Such third-party vendors are not allowed to use the information for any purpose other than what it was provided for, and they are required to process and use the information in accordance with this Privacy Policy.</li>
                        <li><b>Third-party platforms to facilitate the additional offerings:</b> We may share information about you with third-party platforms that are used to create interactive forums between Content Providers and Learners to enable the Learners a more conducive environment to extend their learning. The information shared with these platforms are limited to the information required to facilitate your participation in the forums and is based on your consent. However, the privacy practices of these platforms are independent of this Policy, and we suggest that you read through the privacy policies and terms and condition of the platforms relevant to you prior to consenting to being a part of any forums made available on these third-party platforms.</li>
                        <li><b>Legal Disclosures:</b> We may disclose information about you in response to a court order, or other governmental request. Without limitation to the foregoing, we reserve the right to disclose such information where we believe in good faith that such disclosure is necessary to:<ul>
                                <li>comply with applicable laws, regulations, court orders, government and law enforcement agencies’ requests;</li>
                                <li>protect and defend Oppam’s or a third party's rights and property, or the safety of Oppam, our users, our employees, or others; or</li>
                                <li>prevent, detect, investigate and take measures against criminal activity, fraud and misuse or unauthorized use of our Platform and/or to enforce our Terms and Conditions or other agreements or policies.</li>
                            </ul>To the extent permitted by law, we will attempt to give you prior notice before disclosing your information in response to such a request.</li>
                        <li><b>Business transfers:</b>In the event Oppam undergoes any merger, acquisition, or sale of company assets, in part or in full, with another company, or in the unlikely event that Oppam goes out of business or enters bankruptcy, user information would likely be one of the assets that is transferred or acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to apply to your information and the party receiving your information may continue to use your information, but only consistent with this Privacy Policy.</li>
                        <li><b>Advertising and Analytics Partners:</b>We may share usage data with third-party advertisers, advertisement networks, and analytics providers through cookies and other similar technologies.</li>
                        <li><b>With Your Consent:</b>We may share and disclose information with your consent or at your direction.</li>
                    </ol><br><b>Your information may be shared for reasons not described in this Policy, however, we will seek your consent before we do the same or share information upon your direction.</b></li><br>
            <p></p><br><br><a name="cross_border_data">
                <h3>J. Cross-Border Data Transfer</h3>
            </a>
            <p>Your information including any Personal Information is stored, processed, and transferred in and to the Amazon Web Service (AWS) servers and databases located in Singapore, India and the USA. Oppam may also store, process, and transfer information in and to servers in other countries depending on the location of its affiliates and service providers.</p><br>
            <p>Please note that these countries may have differing (and potentially less stringent) privacy laws and that Personal Information can become subject to the laws and disclosure requirements of such countries, including disclosure to governmental bodies, regulatory agencies, and private persons, as a result of applicable governmental or regulatory inquiry, court order or other similar process.</p><br>
            <p>If you use our Platform from outside India, including in the USA, EU, EEA, and UK, your information may be transferred to, stored, and processed in India. By accessing our Platform or otherwise giving us information, you consent to the transfer of information to India and other countries outside your country of residence. If you are located in the EU and applicable law specifies relevant legal grounds for processing personal data, the legal grounds for our processing activities are to perform our contract(s) with you; to meet our legal obligations; and for our legitimate business purposes, including to improve our operation and Services and to detect and prevent fraud.</p><br>
            <p>We rely on legal bases to transfer information outside the EU, EEA and UK, and any Personal Information that we transfer will be protected in accordance with this Policy as well as with adequate protections in place in compliance with applicable Data Protection Laws and regulations.</p><br><br><a name="duration_info">
                <h3>K. Duration for which your Information is Stored</h3>
            </a>
            <p>Mostly, when you delete any of the information provided by you or when you delete your account, on the Platform, the same will be deleted from our servers too. However, in certain cases, we will retain your information for as long as it is required for us to retain for the purposes stated hereinabove, including for the purpose of complying with legal obligation or business compliances.</p><br>
            <p>Further, please note that we may not be able to delete all communications or photos, files, or other documents publicly made available by you on the Platform (for example: comments, feedback, etc.), however, we shall anonymize your Personal Information in such a way that you can no longer be identified as an individual in association with such information made available by you on the Platform. We will never disclose aggregated or de-identified information in a manner that could identify you as an individual.</p><br>
            <p>Note: If you wish to exercise any of your rights (as specified in <b>‘Your Rights’</b> section below) to access, modify and delete any or all information stored about you, then you may do so by using the options provided within the Platform. You can always write to us at<a href="mailto:info@oppam.in">info@oppam.in</a> for any clarifications needed.</p><br><br><a name="your_choice">
                <h3>L. Your Choices</h3>
            </a>
            <p>
                </p><li><b>Limit the information You provide:</b> You always have an option to choose the information you provide to us, including the option to update or delete your information. However, please note that lack of certain information may not allow you the access to the Platform or any of its features, in part or in full.</li>
                <li><b>Limit the communications You receive from us: </b> Further, you will also have the option to choose what kind of communication you would like to receive from us and whether you would like to receive such communication at all or not. However, there may be certain communications that are required for legal or security purposes, including changes to various legal agreements, that you may not be able to limit.</li>
                <li><b>Reject Cookies and other similar technologies:</b> You may reject or remove cookies from your web browser; you will always have the option to change the default settings on your web browser if the same is set to ‘accept cookies’. However, please note that some Services offered on the Platform may not function or be available to you, when the cookies are rejected, removed, or disabled.</li>
            <p></p><br><br><a name="your_rights">
                <h3>M. Your Rights</h3>
            </a>
            <p>In general, all Users have the rights specified herein this section. However, depending on where you are situated, you may have certain specific rights in respect of your Personal Information accorded by the laws of the country you are situated in. To understand <a role="" href="/privacy#your_rights"><b>Your rights</b></a>, please refer to the <a role="" href="/privacy#country_specific_privacy_terms">Country Specific Additional Terms</a> below.<br><br>If you are a User, you may exercise any of these rights by using the options provided to you within the Platform upon your login. If however, you are facing any issues or require any clarifications, you can always write to us at the address noted in the <a role="" href="/privacy#grievances"><b>‘Grievances’</b></a> section below, and we will address your concerns to the extent required by the applicable law.</p><br><br>
            <p>
            </p><ol type="a">
                <li><b>Right to Confirmation and Access: </b>You have the right to get confirmation and access to your Personal Information that is with us along with other supporting information.</li>
                <li><b>Right to Correction: </b>You have the right to ask us to rectify your Personal Information that is with us that you think is inaccurate. You also have the right to ask us to update your Personal Information that you think is incomplete or out-of-date.</li>
                <li><b>Right to be Forgotten: </b>You have the right to restrict or prevent the continuing disclosure of your Personal Information under certain circumstances.</li>
                <li><b>Right to Erasure: </b>If you wish to withdraw/remove your Personal Information from our Platform, you have the right to request erasure of your Personal Information from our Platform. However, please note that such erasure will remove all your Personal Information from our Platform (except as specifically stated in this Policy) and may result in deletion of your account on the Platform permanently, and the same will not be retrievable.</li>
            </ol><br>Remember, you are entitled to exercise your rights as stated above only with respect to your information, including Personal Information, and not that of other Users. Further, when we receive any requests or queries over email or physically to the address specified in the <a role="" href="/privacy#grievances"><b>‘Grievances’</b></a> section below, then, as per the applicable Data Protection Laws, we may need to ask you a few additional information to verify your identity in association with the Platform and the request received.<p></p><br><br><a name="info_sec">
                <h3>N. Information Security</h3>
            </a>
            <p>
                </p><li>We work to protect the security of your information during transmission by using Transport Layer Security (TLS) or Secure Sockets Layer (SSL) software (depending on your browser/Supported Device), which encrypts information you input in addition to maintaining security of your information as required under applicable laws.</li>
                <li>We maintain electronic, and procedural safeguards in connection with the collection, storage, and disclosure of Personal Information (including Sensitive Personal Information). Our security procedures mean that we may occasionally request proof of identity before we disclose Personal Information to you that belongs to you.</li>
                <li>However, no form or method of data storage or transmission system is fully secure, and we cannot guarantee that security provided by such system(s) is absolute and that your information will not be accessed, disclosed, or destroyed in the event of a breach of any of our security measures.</li>
                <li>It is important for you to protect your account against unauthorized access to or use of your password and to your computer and if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner, you must immediately change your password or inform us, so that we are able to help you stop or prevent such unauthorized access. Be sure to sign off when you finish using a shared computer.</li>
                <li>All KYC information collected in accordance with this Policy are fully encrypted and cannot be accessed by any person other than the designated authority in Oppam.</li>
                <li>We try and ensure that the third parties who provide services to us under appropriate contracts take appropriate security measures to protect Personal Information in line with our policies.</li>
            <p></p><br><br><a name="personal_comm">
                <h3>O. Promotional Communications</h3>
            </a>
            <p>You will always have the option to opt out of receiving some or all of our promotional communications through the setting provided within the Platform upon your login, by using the unsubscribe link in any email communications sent to you or by emailing <a href="mailto:info@oppam.in">info@oppam.in</a>.</p><br>
            <p>If you opt out of promotional communications, we may still send you transactional communications, such as service announcements, administrative and legal notices, and information about your account, without offering you the opportunity to opt out of these communications. If you no longer wish to use our Platform or receive any communications from us (except for those that are legally required), then you may delete your account by using the option enabled within the Platform or by writing to us at <a href="mailto:info@oppam.in">info@oppam.in</a>.</p><br>
            <p>Advertisers or ad companies working on their behalf sometimes use technology to serve the ads that appear on our sites directly to Your browser. They automatically receive Your IP address when this happens. They may also use cookies to measure the effectiveness of their ads and to personalize ad content. We do not have access to or control over cookies or other features that advertisers and third-party sites may use, and the information practices of these advertisers and third-party websites are not covered by our Policy. Please contact them directly for more information about their privacy practices.</p><br>
            <p>Please note that opting out of promotional email communications only affects future communications from us. If we have already provided your information to a third party (as stated in this Policy) before you changed your preferences or updated your information, you may have to change your preferences directly with that third party.</p><br>
            <p>We do not sell your Personal Information to third parties, and we do not use your name or name of your company in marketing statements without your consent.</p><br><br><a name="interest_based_ads">
                <h3>P. Interest-Based Ads</h3>
            </a>
            <p>On unaffiliated sites, Oppam may display interest-based advertising using information you make available to us when you interact with our Platform and Services. Interest-based ads, also sometimes referred to as personalised or targeted ads, are displayed to you based on information from activities such as registering with our Platform, visiting sites that contain Oppam content or ads. In providing interest-based ads, we follow applicable laws, as well as the Code for Self-Regulation in Advertising by the Advertising Standards Council of India and the Self-Regulatory Principles for Online Behavioural Advertising developed by the Digital Advertising Alliance (a coalition of marketing, online advertising, and consumer advocacy organizations) and other rules and guidelines issued by the Federal Trade Commission in respect of digital advertising.</p><br>
            <p>We do not provide any Personal Information to advertisers or to third party sites that display our interest-based ads. However, advertisers and other third-parties (including the ad networks, ad-serving companies, and other service providers they may use) may assume that users who interact with or click on a personalised ad or content are part of the group that the ad or content is directed towards. Also, some third-parties may provide us information about you (such as the sites where you have been shown ads or demographic information) from offline and online sources that we may use to provide you more relevant and useful advertising.</p><br>
            <p>Advertisers or ad companies working on their behalf sometimes use technology to serve the ads that appear on our sites directly to your browser. They automatically receive your IP address when this happens. They may also use cookies to measure the effectiveness of their ads and to personalise ad content. We do not have access to or control over cookies or other features that advertisers and third-party sites may use, and the information practices of these advertisers and third-party websites are not covered by our Policy. Please contact them directly for more information about their privacy practices.</p><br><br><a name="mod-privacy-policy">
                <h3>Q. Modification to Privacy Policy</h3>
            </a>
            <p>Our business changes constantly and our Policy may change from time to time. We may, at our discretion (unless required by applicable laws to mandatorily do so), email periodic reminders of our notices and conditions, unless you have instructed us not to, but we encourage you to check our Platform frequently to see the recent changes. Unless stated otherwise, our current Policy applies to all information that we have about you and your account. We stand behind the promises we make, however, and will not materially change our policies and practices making them less protective of customer information collected in the past without your consent.</p><br><br><a name="grievances">
                <h3>R. Privacy Grievances</h3>
            </a>
            <p>If you have any questions about this Policy, wish to exercise your rights, have concerns about privacy of your data or any privacy related grievances in respect of the Platform, then please register your complaint with a thorough description via email to <a href="mailto:info@oppam.in">info@oppam.in</a> addressed to our grievance officer Mr. Siddharth Manchanda (General Counsel) or via a registered post to the below address-<br><br><b>Oppam Private Limited</b><br>Akshaya E Centre, 3rd Floor,<br> A-Block, Andamkovval, Kunhimangalam<br> Kannur- 670309, Kerala, India.</p><br><br><a name="country_specific_privacy_terms">
                <h3>S. Country Specific Additional Privacy Terms</h3>
            </a>
            <p>
            </p><ol type="A">
                <li><b>TERMS APPLICABLE IF YOU ARE AN INDIAN RESIDENT</b><br><br><a role="" href="/privacy#your_rights"><b>Your rights: </b></a>If you are located in India, you may have the following rights under the Personal Data Protection Bill (PDPB) when it becomes a legislation. All requests can be made by using the option provided to you within the Platform upon your login. You may also write to us as stated in the <a role="" href="/privacy#grievances">‘Grievances’</a> section above, and we will address you concerns to the extent required by law.</li><br>
                <ol type="1">
                    <li><b>Right to Confirmation and Access:</b> You have the right to get confirmation and access to your Personal Information that is with us along with other supporting information.</li>
                    <li><b>Right to Correction:</b> You have the right to ask us to rectify your Personal Information that is with us that you think is inaccurate. You also have the right to ask us to update your Personal Information that you think is incomplete or out-of-date.</li>
                    <li><b>Right to Data Portability:</b> You have the right to ask that we transfer the Personal Information you gave us to another organisation, or to you, under certain circumstances.</li>
                    <li><b>Right to be Forgotten:</b> You have the right to restrict or prevent the continuing disclosure of your Personal Information under certain circumstances.</li>
                    <li><b>Right to Erasure: </b> f you wish to withdraw/remove your Personal Information from our Platform, you have the right to request erasure of your Personal Information from our Platform. However, please note that such erasure will remove all your Personal Information from our Platform (except as specifically stated in this Policy) and may result in deletion of your account on the Platform permanently, and the same will not be retrievable.</li>
                </ol><br>
            </ol>
        </article>
    </div><br><br>


    <div>
        <div><h2>Refund and Cancellation Policy For Online Subscriptions</h2></div>
        <br>
        <div>
          <article>
            <p>
              Please read the subscription terms and conditions carefully before
              subscribing to any of the subscription plans, as once you have
              subscribed, you cannot change or cancel your subscription plan. Once you
              subscribe and make the required payment for any online subscriptions, it
              shall be final and there cannot be any changes or modifications to the
              same and neither will there be any refund.
            </p>
          </article>
        </div>
        <br><br>
      </div>
</div>