<br><br><br><br><br>
<div class="col-md-12 pt-marg-30">
    <h3 >Wallet Details</h3>
      <div class="col-md-12">
        <table class="table table-hover table-bordered p-5">
            <thead>
                <tr class="info">
                    <th style="width: 50px;">#</th>
                    <th  style="width:130px"> Date</th>
                    <th>Service Name</th>
                    <th  style="width:130px">In Value</th>
                    <th  style="width:130px">Out Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let obj of Wallet; let indexOfelement=index;">
                    <td>{{indexOfelement + 1}}</td>
                    <td style="text-align:center;">{{obj.entered_on | date: 'dd-MMM-yyyy'}}</td>
                    <td>{{obj.service_name}}</td>
                    <td style="text-align:right;"><span ng-if="obj.in_value > 0">{{obj.in_value}}</span></td>
                    <td style="text-align:right;"><span ng-if="obj.out_value > 0">{{obj.out_value}}</span></td>
                </tr>
            </tbody>
        </table>
        <br>
        <br>
    </div>
    <div class="col-md-12 pt-marg-30">
        <button mat-flat-button class="btnbluedark" (click)="goBack()">Back</button>
    </div>
    <br>
</div>
