import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShardRoutingModule } from './shard-routing.module';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollerDirective } from './diractives/menu.diractive';
import { OwlSliderComponent } from './components/owl-slider/owl-slider.component';
import { ScrollToBottomComponent } from './widgets/scroll-to-bottom/scroll-to-bottom.component';
import { ProductsComponent } from './components/products/products.component';
import { FullSliderComponent } from './components/full-slider/full-slider.component';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ApproveDialogComponent } from './dialogs/approve-dialog/approve-dialog.component';



@NgModule({
  declarations: [ScrollerDirective, OwlSliderComponent, ScrollToBottomComponent, ProductsComponent, FullSliderComponent, AlertDialogComponent, ConfirmDialogComponent, ApproveDialogComponent],
  imports: [
    CommonModule,
    ShardRoutingModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports:[
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    OwlSliderComponent,
    ScrollerDirective,
    ScrollToBottomComponent,
    ProductsComponent,
    FullSliderComponent
  ]
})
export class ShardModule { }

// share module helps to access the diractives components all over the app
